import * as constants from '../config/constants';
let CryptoJS = require("crypto-js");

let UserProfile = (function() {
	let details = {
		id: null,
		email: null,
		mobile: null,
		password: null,
		name: null,
		gender: null,
		age: null,
		country: null,
		state: null,
		language: null,
		preferredExams: null,
		profilePic: null,
		isLoggedIn: false,
		skippedExams: false,
	};

	let setUserProfile = function(object) {
		if(object === null) {
			details = {
				id: null,
				email: null,
				mobile: null,
				password: null,
				name: null,
				gender: null,
				age: null,
				country: null,
				state: null,
				language: null,
				preferredExams: null,
				isLoggedIn: false,
				skippedExams: false
			};
		} else {
			details.isLoggedIn = true;
			details = object;
		}
		localStorage.setItem('UserProfile', CryptoJS.AES.encrypt(JSON.stringify(details), constants.ENC_KEY));
	}

	let getUserProfile = function() {
		let details = localStorage.getItem('UserProfile');
		if(details !== null) {
			details = CryptoJS.AES.decrypt(details, constants.ENC_KEY).toString(CryptoJS.enc.Utf8);
			details = JSON.parse(details);
			return details;
		}
		else
			return null;
	};

	return {
		setUserProfile: setUserProfile,
		getUserProfile: getUserProfile
	}

})();

export default UserProfile;