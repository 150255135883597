import React, { Component } from 'react';
import axios from 'axios';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import CloseIcon from '@material-ui/icons/Close';
import NoteIcon from '@material-ui/icons/Note';
import ShareIcon from '@material-ui/icons/Share';
import IconButton from '@material-ui/core/IconButton';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import UserProfile from '../Models/UserProfile';
import * as constants from '../config/constants';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Tooltip from '@material-ui/core/Tooltip';
import Note from '../note/note';
import ShareDialog from '../social/shareDialog';
import SwipeableViews from 'react-swipeable-views';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import AddIcon from '@material-ui/icons/Add';
import ReactHtmlParser from 'react-html-parser';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import pink from '@material-ui/core/colors/pink';
import brown from '@material-ui/core/colors/brown';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ReactDOM from 'react-dom';

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 24 }}>
    {props.children}
    </Typography>
    );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

class Lesson extends Component {

  constructor(props) {
    super(props);
    this.state = {
      lessonId:  this.props.lessonId,
      noSticky:  this.props.noSticky,
      notes:  this.props.notes,
      title:  this.props.title,
      description: this.props.description,
      category: this.props.category,
      topic: this.props.topic,
      subTopic: this.props.subTopic,
      isBookmarked: (this.props.isBookmarked) ? this.props.isBookmarked : false,
      readingTime: (this.props.readingTime) ? this.props.readingTime : false,
      isLiked: (this.props.isLiked) ? this.props.isLiked : false,
      isFavorite: (this.props.isFavorite) ? this.props.isFavorite : false,
      openNotes: (this.props.noteOpened) ? this.props.noteOpened : false,
      menuOpened: typeof this.props.menuOpened !== "undefined" ? this.props.menuOpened : false,
      dialogOpen: false,
      showShareDialog: false,
      shareMessage: '',
      shareUrl: '',
      searchTerm: '',
      mouseX: 0,
      mouseY: 0,
      noteOpened: (this.props.noteOpened) ? this.props.noteOpened : false,
      range: [],
      openMenu: false,
      notesCallBack: (this.props.notesCallBack) ? this.props.notesCallBack : null,
      handleLessonPersonalisationCB: (this.props.handleLessonPersonalisationCB) ? this.props.handleLessonPersonalisationCB : null,
      notesEdited: (this.props.notesEdited) ? this.props.notesEdited : null,
      highlightedText: '',
      currentPage: 0,
      isMobile: window.innerWidth <= 500,
      newNote: {
        user: UserProfile.getUserProfile(),
        noteId: null,
        category: this.props.category,
        topic: this.props.topic,
        subTopic: this.props.subTopic,
        title: ("Notes for " + this.props.subTopic),
        description: null,
        updatedAt : this.props.updatedAt
      }
    };
    this.handleShare = this.handleShare.bind(this);
    this.handleNotes = this.handleNotes.bind(this);
    this.addCard = this.addCard.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    this.handleMenuClose = this.handleMenuClose.bind(this);
    this.displayCard = this.displayCard.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.deleteCallBack = this.deleteCallBack.bind(this);
    this.toggleShareDialog = this.toggleShareDialog.bind(this);
    this.optionsOnSelect = this.optionsOnSelect.bind(this);
    this.handleAddToNotes = this.handleAddToNotes.bind(this);
    this.handleSearchGoogle = this.handleSearchGoogle.bind(this);
    this.handleSearchChange =this.handleSearchChange.bind(this);
    this.handlePageChange =this.handlePageChange.bind(this);
    this.handleLessonPersonalisation =this.handleLessonPersonalisation.bind(this);
  }
  handleAddToNotes() {
    let copiedStates = Object.assign({}, this.state);
    copiedStates.newNote.description = copiedStates.highlightedText;
    copiedStates.openMenu = false;
    copiedStates.dialogOpen = true;
    this.setState(copiedStates);
  }
  handleSearchGoogle() {
    window.open('https://www.google.co.in/search?q=' + this.state.highlightedText, '_blank');
    this.handleMenuClose();
  }
  handleMenuClose() {
    this.setState({
      openMenu: false
    });
  }
  optionsOnSelect(event) {
    if (window.getSelection) {
      let text = window.getSelection().toString();
      if(text) {
        let copiedStates = Object.assign({}, this.state);
        copiedStates.mouseY = event.clientY;
        copiedStates.mouseX = event.clientX;
        copiedStates.highlightedText = text;
        copiedStates.openMenu = true;
        this.setState(copiedStates);
      }
    }
  }
  toggleShareDialog() {
    this.setState({
      showShareDialog: !this.state.showShareDialog
    });
  }
  deleteCallBack(noteId) {
    let notedeleteArray = this.state.notes;
    let allNotes = [];
    notedeleteArray.map(note => {
      if(noteId !== note.noteId) {
        allNotes.push(note);
      }
      return true;
    });
    this.setState({notes : allNotes});
  }
  addCard() {
    this.setState({dialogOpen: true});
  }
  closeDialog() {
    this.setState({dialogOpen : false});
  }
  handleNotes() {
    if(typeof(this.state.notesCallBack) !== 'undefined')
      this.state.notesCallBack();
  }
  handleSearchChange(event) {
    let copiedStates = Object.assign({}, this.state);
    if(event.target.value !== '') {
      for(let key in copiedStates.notes) {
        let regex = new RegExp((event.target.value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')) , 'ig');
        if((copiedStates.notes[key].title.search(regex) > -1)) {
          copiedStates.notes[key].visible = true; 
        }
        else if((copiedStates.notes[key].description.search(regex) > -1)) {
          copiedStates.notes[key].visible = true; 
        }
        else {
          copiedStates.notes[key].visible = false;
        }
      }
    }
    else {
      for(let keys in copiedStates.notes) {
        copiedStates.notes[keys].visible = true;
      }
    }
    copiedStates.searchTerm = event.target.value;
    this.setState(copiedStates);
  }
  handleLessonPersonalisation(what) {
    let copiedStates = Object.assign({}, this.state);
    let request = {
      userId: UserProfile.getUserProfile().id,
      lessonId: copiedStates.lessonId
    };
    (what === "bookmark") && (copiedStates.isBookmarked = request.isBookmarked = !copiedStates.isBookmarked);
    (what === "favorite") && (copiedStates.isFavorite = request.isFavorite = !copiedStates.isFavorite);
    (what === "like") && (copiedStates.isLiked = request.isLiked = !copiedStates.isLiked);
    axios.post(constants.API_BASE_URL + '/personalize/lessons', request);
    this.setState(copiedStates);
    this.state.handleLessonPersonalisationCB(copiedStates);
  }
  handleShare() {
    let copiedStates = Object.assign({}, this.state);
    let shareUrl = constants.HOST_URL + '/lessons/'
    + copiedStates.category + '/'
    + copiedStates.topic + '/'
    + copiedStates.subTopic;
    copiedStates.showShareDialog = true;
    copiedStates.shareMessage = UserProfile.getUserProfile().name + " has shared you the lesson " + copiedStates.title;
    copiedStates.shareUrl = shareUrl;
    this.setState(copiedStates);
  }
  componentWillReceiveProps(nextProps) {
    let copiedStates = Object.assign({}, this.state);
    var newNote = {
      user: UserProfile.getUserProfile(),
      noteId: null,
      category: nextProps.category,
      topic: nextProps.topic,
      subTopic: nextProps.subTopic,
      title: (nextProps.category + ' > ' + nextProps.topic + ' > ' + nextProps.subTopic),
      description: null,
      updatedAt : nextProps.updatedAt
    };
    if(copiedStates.menuOpened !== nextProps.menuOpened || copiedStates.noteOpened !== nextProps.noteOpened ) {
      let lessonCardHeader = document.getElementById("LessonCardHeader");
      let sticky = lessonCardHeader.offsetTop;
      if (document.getElementById("MainLessonView").scrollTop >= sticky) {
        let styleToSet;
        let width;
        if(nextProps.menuOpened === true) {
          width = (nextProps.noteOpened === true) ? 'left: 326px; width: calc(75% - 380px)' : 'left: 326px; width: calc(100% - 380px)'; 
        } else {
          width = (nextProps.noteOpened === true) ? 'left: 0px; width: calc(75% - 54px)' : 'left: 0px; width: calc(100% - 54px)'; 
        }
        styleToSet = 'position: fixed; top: 70px; z-index: 1; background: white; margin: 0 24px; ' + width;
        lessonCardHeader.setAttribute('style', styleToSet);
      } else {
        lessonCardHeader.style.position = 'relative';
        lessonCardHeader.style.top = null;
        lessonCardHeader.style.left = null;
        lessonCardHeader.style.margin = null;
        lessonCardHeader.style.width = null;
      }
    }
    copiedStates.noSticky = nextProps.noSticky;
    copiedStates.title = nextProps.title;
    copiedStates.noteOpened = nextProps.noteOpened;
    copiedStates.menuOpened = nextProps.menuOpened;
    copiedStates.openNotes = nextProps.noteOpened;
    copiedStates.notesCallBack = nextProps.notesCallBack;
    copiedStates.isBookmarked = nextProps.isBookmarked;
    copiedStates.isFavorite = nextProps.isFavorite;
    copiedStates.isLiked = nextProps.isLiked;
    copiedStates.description = nextProps.description;
    copiedStates.category = nextProps.category;
    copiedStates.topic = nextProps.topic;
    copiedStates.subTopic = nextProps.subTopic;
    copiedStates.lessonId = nextProps.lessonId;
    copiedStates.notes = nextProps.notes;
    copiedStates.readingTime = nextProps.readingTime;
    copiedStates.newNote = newNote;
    this.setState(copiedStates);
  }
  handleInputChange(e) {
    let copiedStates = Object.assign({}, this.state);
    copiedStates.newNote[e.target.name] = e.target.value;
    this.setState(copiedStates);
  }
  displayCard(event) {
    let response = {
      userId: this.state.newNote.user.id,
      title : this.state.newNote.title,
      description : this.state.newNote.description,
      category: this.state.newNote.category,
      topic: this.state.newNote.topic,
      subTopic: this.state.newNote.subTopic,
      tags: (this.state.newNote.tags) ? this.state.newNote.tags.split(',') : this.state.newNote.tags,
    };
    let that = this;
    axios.post(constants.API_BASE_URL + '/note',response)
    .then(function (responseFromServer) {
      let copiedStates = Object.assign({}, that.state);
      (copiedStates.notes) ? copiedStates.notes.unshift(responseFromServer.data) : (copiedStates.notes = [responseFromServer.data]);
      copiedStates.newNote = {
        user: UserProfile.getUserProfile(),
        noteId: null,
        category: that.state.category,
        topic: that.state.topic,
        subTopic: that.state.subTopic,
        title: (that.state.category + ' > ' + that.state.topic + ' > ' + that.state.subTopic),
        description: null,
        tags: '',
        updatedAt : null
      };
      copiedStates.dialogOpen = false;
      if(that.state.notesEdited)
        that.state.notesEdited(copiedStates);
      that.setState(copiedStates);
      let noteId = responseFromServer.data._id;
      axios.post(constants.API_BASE_URL + '/personalize/lessons', {
        userId: UserProfile.getUserProfile().id,
        lessonId: that.state.lessonId,
        notes: [noteId]
      })
      .then(function(response) {
      });
    })
    .catch(function (error) {
    });
  }
  componentDidMount(){
    document.getElementById("MainLessonView").onscroll = () => {
      let lessonCardHeader = document.getElementById("LessonCardHeader");
      if(lessonCardHeader) {
        let sticky = lessonCardHeader.offsetTop;
        if (document.getElementById("MainLessonView").scrollTop >= sticky) {
          let styleToSet;
          if(this.state.noSticky === false) {
            styleToSet =  (this.state.openNotes) ? 'position: fixed; top: 70px; left: 0px; z-index: 1; background: white; margin: 0 24px; width: calc(75% - 54px)' : 'position: fixed; top: 70px; left: 0px; z-index: 1; background: white; margin: 0 24px; width: calc(100% - 54px)';
          }
          else {
            styleToSet =  (this.state.openNotes) ? 'position: fixed; top: 70px; left: 326px; z-index: 1; background: white; margin: 0 24px; width: calc(75% - 380px)' : 'position: fixed; top: 70px; left: 326px; z-index: 1; background: white; margin: 0 24px; width: calc(100% - 380px)';
          }
          lessonCardHeader.setAttribute('style', styleToSet);
        } else {
          lessonCardHeader.classList.remove("stickyCardHeader");
          lessonCardHeader.style.position = 'relative';
          lessonCardHeader.style.top = null;
          lessonCardHeader.style.left = null;
          lessonCardHeader.style.margin = null;
          lessonCardHeader.style.width = null;
        }
      }
    }
  }
  componentDidUpdate = () => {
    // ReactDOM.findDOMNode(this).scrollIntoView();
  }
  handlePageChange(event, value) {
    this.setState({currentPage: value});
    ReactDOM.findDOMNode(this).scrollIntoView({
      behavior: "smooth",
      block: "start"
    });
  }
  render() {
    let notesArray = this.state.notes;
    let notesComponents = [];
    let tabComponents = [];
    let tabContainerComponents = [];

    for(let i =0; i < this.state.description.length; i++) {
      tabComponents.push(
        <Tab key={i} label={"Page "+ (i + 1)} />
        );
      tabContainerComponents.push(
        <TabContainer key={"TCC" + i} >
        {ReactHtmlParser(this.state.description[i])}
        </TabContainer>
        );
    }

    for (var i = 0; notesArray && i < notesArray.length; i++) {
      notesComponents.push(
        <ListItem key={notesArray[i].noteId}>
        <Note
        displaySubheader={false}
        displayTags={false}
        descriptionLimit={50}
        noteId={notesArray[i].noteId}
        title={notesArray[i].title}
        description={notesArray[i].description}
        topic={notesArray[i].topic}
        category={notesArray[i].category}
        subTopic={notesArray[i].subTopic} 
        updatedAt={notesArray[i].updatedAt}
        tags={notesArray[i].tags}
        deleteCallBack={this.deleteCallBack}
        visible={typeof(notesArray[i].visible) === 'undefined' ? true : notesArray[i].visible}
        />
        </ListItem>
        );
    }

    let cardWidth = (this.state.openNotes) ? ('100% - 350px') : '100%';

    return (
      <div style={{textAlign: 'left'}}>
      <Drawer
      classes={{
        paper: 'marginedNotePanel'
      }}
      variant={this.state.isMobile ? "temporary" : "persistent"}
      anchor="right"
      open={this.state.openNotes}
      onClose={this.handleNotes}>
      <AppBar color="default" position="static">
      <Toolbar style={{
        paddingLeft: 0,
        paddingRight: 0,
      }} >
      <IconButton onClick={this.handleNotes}>
      <CloseIcon />
      </IconButton>
      <TextField
      label="Search"
      id="bootstrap-input"
      value={this.state.searchTerm}
      onChange={this.handleSearchChange}
      fullWidth
      />
      
      <Tooltip title="Add a new note">
      <IconButton onClick = {this.addCard} size="small" color="primary" variant="fab">
      <AddIcon />
      </IconButton>
      </Tooltip>

      </Toolbar>
      </AppBar>
      <List style={{
        overflow: "auto",
        textAlign: "center"
      }} component="div">
      {notesComponents}
      </List>
      </Drawer>
      <Menu
      style={{
        position: 'absolute',
        left: this.state.mouseX + 'px',
        top: -this.state.mouseY + 'px'
      }}
      id="simple-menu"
      open={this.state.openMenu}
      onClose={this.handleMenuClose}
      >
      <MenuItem onClick={this.handleAddToNotes}>Add to Notes</MenuItem>
      <MenuItem onClick={this.handleSearchGoogle}>Search Google</MenuItem>
      </Menu>
      <ShareDialog
      shareUrl={this.state.shareUrl}
      showShareDialog={this.state.showShareDialog}
      shareMessage={this.state.shareMessage}
      toggleShareDialogCB={this.toggleShareDialog}
      />
      <Card style={{
        width: cardWidth,
        boxShadow: 'none',
        border: '1px solid #888',
        borderRadius: 0
      }}>
      <CardHeader
      style={{
        borderTop: '1px solid #888',
      }}
      id={"LessonCardHeader"}
      title={this.state.title}
      subheader={((this.state.readingTime) ? ("(" + this.state.readingTime.text) + ") " : '') + ((this.state.isMobile === false) ? (this.state.category + ' > ' + this.state.topic + ' > ' + this.state.subTopic) : "")}
      action = {
        <span>
        <Tooltip title={'Share this lesson'}>
        <IconButton onClick={this.handleShare}>
        <ShareIcon />
        </IconButton>
        </Tooltip>
        <Tooltip title={(this.state.isFavorite) ? 'Remove from favorite' : 'Add to favorite'}>
        <IconButton onClick={() => this.handleLessonPersonalisation("favorite")}>
        {(this.state.isFavorite) ? (<FavoriteIcon style={{
          color: pink[500]
        }}/>) : (<FavoriteBorderIcon />)}
        </IconButton>
        </Tooltip>
        <Tooltip title={(this.state.isBookmarked) ? 'Remove Bookmark' : 'Add Bookmark'}>
        <IconButton onClick={() => this.handleLessonPersonalisation("bookmark")}>
        {(this.state.isBookmarked) ? (<BookmarkIcon style={{
          color: brown[500]
        }}/>) : (<BookmarkBorderIcon />)}
        </IconButton>
        </Tooltip>
        <Tooltip title={this.state.openNotes ? 'Hide Notes' : 'Show Notes'}>
        <IconButton onClick={this.handleNotes}>
        <NoteIcon />
        </IconButton>
        </Tooltip>
        </span>
      }
      />
      <CardContent style={{
        padding: this.state.description.length === 1 ? 24 : 0
      }} >
      <div style={{textAlign: 'left'}} onMouseUp={this.optionsOnSelect}>
      {this.state.description.length === 1 ? ReactHtmlParser(this.state.description[0]) : (
        <div>
        <AppBar position="static" color="default">
        <Tabs
        value={this.state.currentPage}
        onChange={this.handlePageChange}
        indicatorColor="primary"
        textColor="primary"
        scrollable
        scrollButtons="auto"
        >
        {tabComponents}
        </Tabs>
        </AppBar>

        <SwipeableViews
        index={this.state.currentPage}
        onChangeIndex={this.handlePageChange}
        >
        {tabContainerComponents}
        </SwipeableViews>

        <AppBar position="static" color="default">
        <Tabs
        value={this.state.currentPage}
        onChange={this.handlePageChange}
        indicatorColor="primary"
        textColor="primary"
        scrollable
        scrollButtons="auto"
        >
        {tabComponents}
        </Tabs>
        </AppBar>

        </div>
        )}
      </div>
      </CardContent>
      </Card>
      <Dialog 
      open={this.state.dialogOpen}
      onClose={this.closeDialog}
      aria-labelledby="responsive-dialog-title"
      fullWidth
      >
      <DialogTitle id="responsive-dialog-title">Add Note</DialogTitle>
      <DialogContent>
      <TextField
      id="multiline-static"
      label="Title"
      name="title"
      multiline
      placeholder="Title here"
      fullWidth
      style={{fontWeight: '600'}}
      margin="normal"
      value={this.state.newNote.title}
      onChange={this.handleInputChange}
      />
      <TextField
      id="multiline-static"
      label="Description"
      name="description"
      placeholder="Description here"
      multiline
      fullWidth
      margin="normal"
      value={this.state.newNote.description}
      onChange={this.handleInputChange}
      />
      <TextField
      id="multiline-static"
      label="Tags"
      name="tags"
      placeholder="Tags here"
      multiline
      fullWidth
      margin="normal"
      value={this.state.newNote.tags}
      onChange={this.handleInputChange}
      />
      </DialogContent>
      <DialogActions>
      <Button color="primary" onClick = {this.displayCard}>
      Add note
      </Button> 
      </DialogActions>
      </Dialog>
      </div>
      );
}
}

export default Lesson;