import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Collapse from '@material-ui/core/Collapse';
import Folder from '@material-ui/icons/Folder';
import FolderOpen from '@material-ui/icons/FolderOpen';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore'
import InsertDriveFile from '@material-ui/icons/InsertDriveFile';
import TextField from '@material-ui/core/TextField';
import Slide from '@material-ui/core/Slide';
import CircularProgress from '@material-ui/core/CircularProgress';

const drawerWidth = 325;

function getHighlightedText(text, higlight) {
  var parts = text.split(new RegExp(`(${higlight})`, 'gi'));
  return <span>{parts.map(part => part.toLowerCase() === higlight.toLowerCase() ? <b key={Math.random()}>{part}</b> : part)}</span>;
}

const styles = theme => ({
  nested: {
    paddingLeft: theme.spacing.unit * 4,
  },
  drawerPaper: {
    position: 'relative',
    width: drawerWidth,
    paddingLeft: theme.spacing.unit * 4,
    paddingTop: theme.spacing.unit * 4,
    paddingRight: 0,
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    paddingLeft: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 3,
    minWidth: 0,
    width: 100% - drawerWidth
  },
  toolbar: theme.mixins.toolbar,
});

class LessonMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      props: props,
      open: false,
      chapters: null,
      searchTerm: '',
      category: this.props.category,
      topic: null,
      subTopic: null,
      prevMenuItem: null,
      prevInnerKey: null,
      openMenus: this.props.openMenus,
      subTopicClickedCB: this.props.subTopicClickedCB,
      menuClicked: this.props.subTopicClickedCB,
      visible: this.props.visible,
    }
    this.menuClicked = this.menuClicked.bind(this);
    this.subTopicClicked = this.subTopicClicked.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
  }
  handleSearchChange(event) {
    let copiedStates = Object.assign({}, this.state);
    if(event.target.value === '') {
      for(let key in copiedStates.openMenus) {
        for(let innerKey in copiedStates.openMenus[key]) {
          copiedStates.openMenus[key][innerKey] = false;
        }
      }
    }
    else {
      for(let key in copiedStates.openMenus) {
        for(let innerKey in copiedStates.openMenus[key]) {
          copiedStates.openMenus[key][innerKey] = true;
        }
      }
    }
    copiedStates.searchTerm = event.target.value;
    copiedStates.openMenus = copiedStates.openMenus;
    copiedStates.searchTerm = copiedStates.searchTerm;
    this.setState(copiedStates);
  }
  openTOCToggle() {
    let copiedStates = Object.assign({}, this.state);
    copiedStates.openTOC = !this.state.openTOC;
    this.setState(copiedStates);
  }
  subTopicClicked(category, topic, subTopic) {
    let copiedStates = Object.assign({}, this.state);
    copiedStates.category = category;
    copiedStates.topic = topic;
    copiedStates.subTopic = subTopic;
    copiedStates.openTOC = !this.state.openTOC;
    this.setState(copiedStates);
    this.state.subTopicClickedCB(category, topic, subTopic);
  }
  menuClicked(menuItem, innerKey, forceOpen) {
    let copiedStates = Object.assign({}, this.state);
    copiedStates.open = !copiedStates.open;
    if(innerKey) {
      copiedStates.openMenus[menuItem][innerKey] = (forceOpen === true) ? true : !copiedStates.openMenus[menuItem][innerKey];
      if(forceOpen === true) {
        copiedStates.openMenus[menuItem].currentCategoryOpened = true;
      }
    }
    else
      copiedStates.openMenus[menuItem].currentCategoryOpened = (forceOpen === true) ? true : !copiedStates.openMenus[menuItem].currentCategoryOpened;

    if(this.state.prevMenuItem !== null && this.state.prevMenuItem !== menuItem) {
      copiedStates.openMenus[this.state.prevMenuItem].currentCategoryOpened = false;
    }
    if(this.state.prevInnerKey !== null && this.state.prevInnerKey !== innerKey) {
      copiedStates.openMenus[this.state.prevMenuItem][this.state.prevInnerKey] = false;
    }

    if(copiedStates.openMenus[menuItem].currentCategoryOpened === false) {
      for(var key in copiedStates.openMenus[menuItem]) {
        copiedStates.openMenus[menuItem][key] = false;
      }
    }
    copiedStates.open = copiedStates.open;
    copiedStates.openMenus = copiedStates.openMenus;
    copiedStates.prevMenuItem = menuItem;
    if(innerKey) {
      copiedStates.prevInnerKey = innerKey;
    }
    this.setState(copiedStates);
  }
  componentWillReceiveProps(nextProps) {
    let copiedStates = Object.assign({}, this.state);
    var openMenus = {};
    if(nextProps.chapters) {
      for(let key in nextProps.chapters) {
        openMenus[key] = {};
        openMenus[key]['currentCategoryOpened'] = false;
        for(let innerKey in nextProps.chapters[key]) {
          openMenus[key][innerKey] = false;
        }
      }
      copiedStates.openMenus = openMenus;
      copiedStates.chapters = nextProps.chapters;
    }
    if(this.state.chapters && nextProps.category && nextProps.topic && nextProps.subTopic) {
      let innerKey = nextProps.topic;
      let menuItem = nextProps.category;
      if(innerKey) {
        copiedStates.openMenus[menuItem][innerKey] = true;
        copiedStates.openMenus[menuItem].currentCategoryOpened = true;  
      }
      else
        copiedStates.openMenus[menuItem].currentCategoryOpened = true;

      if(this.state.prevMenuItem !== null && this.state.prevMenuItem !== menuItem) {
        copiedStates.openMenus[this.state.prevMenuItem].currentCategoryOpened = false;
      }
      if(this.state.prevInnerKey !== null && this.state.prevInnerKey !== innerKey) {
        copiedStates.openMenus[this.state.prevMenuItem][this.state.prevInnerKey] = false;
      }

      if(copiedStates.openMenus[menuItem].currentCategoryOpened === false) {
        for(let key in copiedStates.openMenus[menuItem]) {
          copiedStates.openMenus[menuItem][key] = false;
        }
      }
      copiedStates.category = nextProps.category;
      copiedStates.topic = nextProps.topic;
      copiedStates.subTopic = nextProps.subTopic;
    }
    copiedStates.visible = nextProps.visible;
    this.setState(copiedStates);
  }

  render(){
    const { classes } = this.props;
    let drawerMenu = [];
    const chapters = this.state.chapters;
    const searchTerm = this.state.searchTerm;
    let secondaryText = '';
    for(let key in chapters) {
      let innerMenu = [];
      let innerKey;
      secondaryText = (Object.keys(this.state.chapters[key]).length + " ") + ((Object.keys(this.state.chapters[key]).length === 1) ? "topic" : "topics");
      drawerMenu.push(
        <div key={key+Math.random()}>
        <ListItem dense button onClick={this.menuClicked.bind(this, key, null)} >
        <ListItemIcon style={{
          color: '#222'
        }}>
        {this.state.openMenus[key].currentCategoryOpened ? <FolderOpen /> : <Folder />}
        </ListItemIcon>
        <ListItemText primary={getHighlightedText(key, searchTerm)} secondary={secondaryText} />
        {this.state.openMenus[key].currentCategoryOpened ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        </div>
        );
      drawerMenu.push(
        <li style={{listStyle: 'none'}} key={key+Math.random()}>
        <Divider />
        </li>
        );
      for(innerKey in chapters[key]) {
        let innerMost = [];
        secondaryText = (Object.keys(this.state.chapters[key][innerKey]).length + " ") + ((Object.keys(this.state.chapters[key][innerKey]).length === 1) ? "lesson" : "lessons");
        innerMenu.push(
          <ListItem dense button className={classes.nested} key={key+Math.random()} onClick={this.menuClicked.bind(this, key, innerKey)} >
          <ListItemIcon style={{
            color: '#222'
          }}>
          {this.state.openMenus[key][innerKey] ? <FolderOpen /> : <Folder />}
          </ListItemIcon>
          <ListItemText primary={getHighlightedText(innerKey, searchTerm)} secondary={secondaryText} />
          {this.state.openMenus[key][innerKey] ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          );
        innerMenu.push(
          <li style={{listStyle: 'none'}} key={key+Math.random()}>
          <Divider />
          </li>
          );
        for(let insideKey in chapters[key][innerKey]) {
          let active = false;
          if(key === this.state.category && innerKey === this.state.topic && chapters[key][innerKey][insideKey] === this.state.subTopic) {
            active = true;
          }
          innerMost.push(
            <ListItem dense button style={{
              backgroundColor: (active) ? 'rgb(63, 81, 181,  0.2)' : 'none'
            }} className={classes.nested} key={insideKey+Math.random()} onClick={this.subTopicClicked.bind(this, key, innerKey, chapters[key][innerKey][insideKey])}>
            <ListItemIcon style={{
              color: '#222'
            }}>
            <InsertDriveFile />
            </ListItemIcon>
            <ListItemText primary={getHighlightedText(chapters[key][innerKey][insideKey], searchTerm)} />
            </ListItem>
            );
          innerMost.push(
            <li style={{listStyle: 'none'}} key={key+Math.random()}>
            <Divider />
            </li>
            );
        }
        innerMenu.push(
          <Collapse in={this.state.openMenus[key][innerKey]} key={key+Math.random()}>
          <List dense component="div" disablePadding>
          <div>
          {innerMost}
          </div>
          </List>
          </Collapse>
          );
      }
      drawerMenu.push(
        <Collapse in={this.state.openMenus[key].currentCategoryOpened} key={key+Math.random()}>
        <List dense component="div" disablePadding style={{
          fontSize: "0.8125rem"
        }}>
        <div>
        {innerMenu}
        </div>
        </List>
        </Collapse>
        );
    }
    return (
      <Slide direction="right" in={this.state.visible} mountOnEnter unmountOnExit>
      <Drawer
      variant="permanent"
      anchor="left"
      open={this.state.openTOC}
      classes={{
        paper: classes.drawerPaper,
      }}
      >
      <List>{chapters && Object.keys(chapters).length > 0 ? drawerMenu : (<Typography component="div">
        <CircularProgress thickness={4} size={30} color="primary" /></Typography>)}</List>
      </Drawer>
      </Slide>
      );
  }
  
}

LessonMenu.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LessonMenu);