import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import ShareDialog from '../social/shareDialog';
import TimeAnalysis from './timeAnalysis';
import UserProfile from '../Models/UserProfile';

	// function onLinkedInLoad() {
 //    IN.Event.on(IN, "auth", shareContent);
 //  }

 //  function onSuccess(data) {
 //    console.log(data);
 //  }

 //  function onError(error) {
 //    console.log(error);
 //  }

 //  function shareContent() {
 //    var payload = { 
 //      "comment": "Hi Ramya here!", 
 //      "visibility": { 
 //        "code": "anyone"
 //      } 

 //    };

 //    IN.API.Raw("/people/~/shares?format=json")
 //    .method("POST")
 //    .body(JSON.stringify(payload))
 //    .result(onSuccess)
 //    .error(onError);

 //  }
 function processSubTopicWiseData(data) {
 	let percentageArray = [];
 	let correct;
 	let percentageObject;
 	let subTopicWiseMessage;
 	if(Object.keys(data).length > 0) {	
 		data = data.data;
 		Object.keys(data).map((topic) => {
 			percentageObject = {
 				label: topic,
 				correct: 0,
 				wrong: 0,
 				attempts: 0,
 				percentage: 0
 			};
 			data[topic].map(attempt => {
 				correct = attempt.isCorrect ? 1 : 0;
 				if(correct)
 					percentageObject.correct += 1;
 				else 
 					percentageObject.wrong += 1;
 				percentageObject.attempts += 1;
 				return 0;
 			});
 			percentageObject.percentage = percentageObject.correct / percentageObject.attempts;
 			percentageArray.push(percentageObject);
 			return 0;
 		});
 		percentageArray.sort((a, b) => {
 			return b.percentage - a.percentage
 		});
 	}
 	subTopicWiseMessage = percentageArray.length > 0 ? "Attempt questions from " + percentageArray[0].label + " topic" : "";
 	return subTopicWiseMessage;
 }



 class Suggestions extends Component {
 	constructor(props) {
 		super(props);
 		this.state = {
 			showShareDialog: false,
 			data: (this.props.data) ? this.props.data : {},
 			subTopicWiseMessage: (this.props.subTopicWise) ? processSubTopicWiseData(this.props.subTopicWise) : {}
 		}
 	}
 	componentWillReceiveProps(nextProps) {
 		this.setState({
 			data: nextProps.data
 		});
 		this.setState({
 			subTopicWiseMessage: processSubTopicWiseData(nextProps.subTopicWise)
 		});
 	}
 	render = () => {
 		let shareMessage = UserProfile.getUserProfile().name + " has attempted questions. What about you?";

 		return (
 			<div>
 			{(this.state.showShareDialog) ? (<ShareDialog
 				showShareDialog={true}
 				shareMessage={shareMessage}
 				toggleShareDialogCB={this.toggleShareDialogCB}
 				/>) : (<span></span>)}
 			<Card
 			style={{
 				textAlign: 'left',
 				display: 'inline-block',
 				maxWidth: '100%',
 				width: '100%'
 			}}>
 			<CardHeader
 			title={'Suggestions'}
 			
 			/>
 			<CardContent>

 			<TimeAnalysis data={this.state.data} subTopicWiseMessage={this.state.subTopicWiseMessage} />
 			</CardContent>
 			</Card>
 			</div>
 			);
 	}
 }

 export default Suggestions;