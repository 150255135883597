import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { unregister } from './registerServiceWorker';
import axios from 'axios';
import UserProfile from './Models/UserProfile';
import * as constants from './config/constants';

const applicationServerPublicKey = 'BOWt0P9lcLW9s-tcKeuySW3zMKroLc7H93yfyeV7FEicPXrpwArR9_cBThdRyw3CvW9RKZUzEImz4FN4y5QN1tY';
let isSubscribed = false;
let swRegistration = null;

function urlB64ToUint8Array(base64String) {
	const padding = '='.repeat((4 - base64String.length % 4) % 4);
	const base64 = (base64String + padding)
		.replace(/-/g, '+')
		.replace(/_/g, '/');

	const rawData = window.atob(base64);
	const outputArray = new Uint8Array(rawData.length);

	for (let i = 0; i < rawData.length; ++i) {
		outputArray[i] = rawData.charCodeAt(i);
	}
	return outputArray;
}

function subscribeUser() {
	const applicationServerKey = urlB64ToUint8Array(applicationServerPublicKey);
	swRegistration.pushManager.subscribe({
		userVisibleOnly: true,
		applicationServerKey: applicationServerKey
	})
		.then(function (subscription) {
			updateSubscriptionOnServer(subscription);
			isSubscribed = true;
		})
		.catch(function (err) {
		});
}

function unsubscribeUser() {
	swRegistration.pushManager.getSubscription()
		.then(function (subscription) {
			if (subscription) {
				return subscription.unsubscribe();
			}
		})
		.catch(function (error) {
		})
		.then(function () {
			updateSubscriptionOnServer(null);
			isSubscribed = false;
		});
}

function updateSubscriptionOnServer(subscription) {
	if (UserProfile.getUserProfile() && UserProfile.getUserProfile().isLoggedIn === true) {
		const userId = UserProfile.getUserProfile().id;
		if (subscription) {
			subscription = JSON.parse(JSON.stringify(subscription));
			let request = {
				endpoint: subscription.endpoint,
				auth: subscription.keys.auth,
				p256dh: subscription.keys.p256dh,
				userId: userId
			}
			axios.put(constants.API_BASE_URL + '/users/' + userId + '/registerPushKeys', request)
				.then(function (response) {
				})
				.catch(function (error) {
				});
		}
	}
}

function callBackAfterRegistration(registration) {
	swRegistration = registration;
	if (isSubscribed) {
		unsubscribeUser();
	} else {
		subscribeUser();
	}
}

ReactDOM.render(<App />, document.getElementById('root'));
//registerServiceWorker(callBackAfterRegistration);
unregister();
