import React, { Component } from 'react';
import Chip from '@material-ui/core/Chip';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
	label: {
		fontSize: 10,
		fontWeight: 700,
		textTransform: 'uppercase'
	}
});

class Tags extends Component {
	constructor(props) {
		super(props);
		this.state = {
			tags: this.props.tags.filter(n => { return n !== '' })
		};
	}
	componentWillReceiveProps(nextProps) {
		this.setState({
			tags: nextProps.tags.filter(n => { return n !== '' })
		});
	}
	render() {

		let { classes } = this.props;

		let listOfTags = this.state.tags.map((tag, i) => {
			return (<Chip
				key={i}
				variant="outlined"
				label={this.state.tags[i]}
				classes={{
					label: classes.label
				}}
				style={{
					margin: 2,
					fontSize: 10,
					borderStyle: 'dashed'
				}} />
				);
		});
		return (this.state.tags.length > 0) ? (<div>{listOfTags}</div>) : (<div></div>);
	}
}

export default withStyles(styles)(Tags);