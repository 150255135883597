let CryptoJS = require("crypto-js");

export let ENVIRONMENT = "LIVE";
if (window.location.hostname === "localhost") {
  ENVIRONMENT = "DEV";
} else if (window.location.hostname === "aptimithra-staging.web.app") {
  ENVIRONMENT = "STAGING";
}
export let API_BASE_URL;
export let PROD_NAME = "Aptimithra";
export let SPHERE_ACCESS = "c1fd9741d7f6b8797a76801f0890bd85";
export let HOST_URL;
export let ENC_KEY;
export const MILESTONES = ["Newbie", "Rookie", "Skilled", "Expert"];
ENC_KEY = "aravindIsAlive";

if (ENVIRONMENT === "LIVE") {
  API_BASE_URL = "https://apps.aptimithra.com:3443";
  HOST_URL = "https://aptimithra.com";
} else if (ENVIRONMENT === "STAGING") {
  console.log("Warning: ENV is STAGING");
  API_BASE_URL = "https://apps.aptimithra.com:3443";
  HOST_URL = "https://aptimithra-staging.web.app";
} else {
  console.log("Warning: ENV is DEV");
  // API_BASE_URL = "https://ethnustech.com:3443";
  API_BASE_URL = "http://localhost:5000";
  HOST_URL = "http://localhost:3000";
}

export let getRequestToken = (id) => {
  let encrypted = CryptoJS.AES.encrypt(id, ENC_KEY);
  return encodeURI(encrypted);
};

export let processResponse = (response, key) => {
  /*try {
		let decoded = decodeURI(response.data);
		let decrypted = CryptoJS.AES.decrypt(decoded, key);
		let decryptedString = decrypted.toString(CryptoJS.enc.Utf8)
		if(decryptedString) {
			response.data = JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
			return response;
		}
		response.data = {};
		return response;
	}
	catch(error){
		response.data = {};
		return response;
	}*/
  return response;
};

export let processRequest = (request, key) => {
  /*request.data = {
		c: encodeURI(CryptoJS.AES.encrypt(JSON.stringify(request.data), key))
	};*/
  request.data = {
    c: JSON.stringify(request.data),
  };
  return request;
};
