import * as constants from "../config/constants";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
// import Tabs from "@material-ui/core/Tabs";
// import Tab from "@material-ui/core/Tab";
import Checkbox from "@material-ui/core/Checkbox";
import React, { Component } from "react";
import UserProfile from "../Models/UserProfile";
import Quiz from "./quiz";
import axios from "axios";
import Grid from "@material-ui/core/Grid";

// import TimerIcon from "@material-ui/icons/Timer";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
// import LinearScaleIcon from "@material-ui/icons/LinearScale";
// import DoneAllIcon from "@material-ui/icons/DoneAll";
// import FilterListIcon from "@material-ui/icons/FilterList";
// import CloseIcon from "@material-ui/icons/Close";
import { Row, Col } from "reactstrap";
import BounceLoader from "react-spinners/BounceLoader";
import PulseLoader from "react-spinners/PulseLoader";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import QuizQuestions from "./quizQuestions";
import AttemptWiseReport from "../report/attemptWiseReport";
// import BottomNavigation from "@material-ui/core/BottomNavigation";
// import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
// import Drawer from "@material-ui/core/Drawer";
// import Slide from "@material-ui/core/Slide";
import ReactGA from "react-ga";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";

/*
  NOTE: The drawerWidth and styles for filters/drawers are still defined, 
  but much of the related code is now commented out.
*/
const drawerWidth = "360px";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    height: window.innerHeight - 70,
    zIndex: 1,
    overflow: "hidden",
    position: "relative",
    display: "flex",
    width: "100%",
  },
  content: {
    overflow: "auto",
    padding: 20,
    paddingLeft: 40,
    paddingRight: 40,
    marginBottom: 40,
  },
  nested: {
    paddingLeft: theme.spacing.unit * 4,
  },
  titleFlex: {
    flex: 1,
    fontSize: "1.3125rem",
    fontWeight: 500,
    lineHeight: "1.16667em",
    fontFamily: "'Aclonica', sans-serif !important",
  },
  drawerPaper: {
    position: "relative",
    overflow: "hidden",
    width: drawerWidth,
    paddingLeft: theme.spacing.unit * 2,
    paddingTop: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
  },
  mobileDrawerPaper: {
    position: "relative",
    overflow: "hidden",
    width: "100%",
    minWidth: drawerWidth,
    paddingLeft: theme.spacing.unit * 2,
    paddingTop: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
  },
  toolbar: theme.mixins.toolbar,
  label: {
    fontSize: "0.875rem",
  },
  alertSnackbar: {
    background: "#FF9800",
    color: "#FFFBF5",
  },
});

function TabContainer({ children, dir }) {
  return (
    <Typography component="div" dir={dir} style={{ padding: 24, textAlign: "left" }}>
      {children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired,
};

// Extract quiz id from URL
let url_string = window.location.href;
let url = new URL(url_string);
let selectedQuizId = url.searchParams.get("qu");

class ListQuiz extends Component {
  constructor() {
    super();
    this.violationTimerID = 0;
    this.alertCount = 0;

    // State simplified: Removed companies tab & "view more" button code.
    this.state = {
      isLoading: true,
      selectedQuizId: null,
      webcamEnabled: false,
      newQuiz: {
        title: "",
        description: "",
        tags: [],
        subTopics: [],
        linear: "linear",
        mode: "untimed",
        numberOfQuestionsNeeded: 50,
        availableTopics: {},
      },
      listOfQuiz: [],
      quizIds: [],
      attemptCode: "",
      violationCount: 0,
      numberOfQuizAttempts: 0,
      showViolationDialog: false,
      listOfQuestions: [],
      displayQuestions: false,
      activeStep: 0,
      completed: {},
      bookmarked: {},
      steps: [],
      mode: null,
      bookmarkedCount: 0,
      showAnalysis: false,
      showResults: false,
      error: {},
      snackBar: false,
      snackBarMessage: "",
      isMobile: window.innerWidth <= 500,
      // Removed companies & view more related states, but leaving them commented:
      // listOfTags: [],
      // listOfCompanies: [],
      // listOfTagsOriginal: [],
      // listOfCompaniesOriginal: [],
      // filter: { timed: false, untimed: false, linear: false, nonlinear: false, tags: [], company: [] },
      // bottomFilter: 0,
      // currentFilters: [],
      noTagsFound: true,
      noQuizFound: false,
      mobileFilters: false,
      // noCompaniesFound: true,
    };

    if (selectedQuizId) {
      console.log("selectedQuizId", selectedQuizId);
      this.state.selectedQuizId = selectedQuizId;
    }

    this.populateQuizCB = this.populateQuizCB.bind(this);
    this.deleteQuizCB = this.deleteQuizCB.bind(this);
    this.subTopicSelectedCB = this.subTopicSelectedCB.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.openCreateQuizDialog = this.openCreateQuizDialog.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.finishCallback = this.finishCallback.bind(this);
    this.handleSignIn = this.handleSignIn.bind(this);
    // Removed filtering functions, but leave commented:
    // this.handleFilterQuiz = this.handleFilterQuiz.bind(this);
    // this.handleSearchChange = this.handleSearchChange.bind(this);
    // this.handleOpenMobileFilters = this.handleOpenMobileFilters.bind(this);
    // this.handleFilterTabClick = this.handleFilterTabClick.bind(this);
    this.windowFocused = this.windowFocused.bind(this);
    this.windowBlured = this.windowBlured.bind(this);
    // this.increaseLimit = this.increaseLimit.bind(this);

    ReactGA.initialize("UA-125256246-1", {
      gaOptions: {
        userId: UserProfile.getUserProfile().email,
      },
    });
    ReactGA.pageview("/quiz");
  }

  // Commented out filter handling related functions:
  /*
  handleFilterTabClick = (event, value) => {
    this.setState({ filterTabIndex: value });
  };

  handleOpenMobileFilters() {
    let copiedStates = Object.assign({}, this.state);
    copiedStates.mobileFilters = !copiedStates.mobileFilters;
    copiedStates.bottomFilter = copiedStates.currentFilters.length > 0 ? 0 : 3;
    this.setState(copiedStates);
  }
  */

  async finishCallback() {
    let copiedStates = Object.assign({}, this.state);
    let that = this;

    window.removeEventListener("focus", this.windowFocused);
    window.removeEventListener("blur", this.windowBlured);

    // Submit attempt
    axios
      .put(constants.API_BASE_URL + "/attempts/submit", {
        userId: UserProfile.getUserProfile().id,
        attemptCode: this.state.attemptCode,
        submissionStatus: 1,
      })
      .then(function () {
        ReactGA.event({ category: "Quiz", action: "Submitted" });
        copiedStates.displayQuestions = false;
        copiedStates.showInstructions = false;
        copiedStates.showAnalysis = true;
        selectedQuizId = null;
        that.setState(copiedStates);
      });

    // Update plagiarism data
    try {
      await axios.put(constants.API_BASE_URL + "/attempts/plagiarism", {
        attemptCode: this.state.attemptCode,
        quizId: this.state.quizId,
        finished: true,
        intervalsOffScreen: this.state.violationCount,
      });
    } catch (err) {
      alert("Some error occurred while submitting");
    }
  }

  deleteQuizCB(quizId) {
    let copiedStates = Object.assign({}, this.state);
    let listOfQuiz = [];
    for (let i = 0; i < this.state.listOfQuiz.length; i++) {
      if (this.state.listOfQuiz[i].id !== quizId) {
        listOfQuiz.push(this.state.listOfQuiz[i]);
      }
    }
    copiedStates.listOfQuiz = listOfQuiz;
    this.setState(copiedStates);
  }

  handleClose() {
    this.setState({ createQuizDialog: false });
  }

  // Removed search handling, commented out:
  /*
  handleSearchChange(event) {
    // Filtering code commented out as we no longer show tags or companies
  }
  */

  windowFocused() {
    let plagiarismData = { attemptCode: this.state.attemptCode, focus: true };
    axios
      .put(constants.API_BASE_URL + "/attempts/plagiarism", plagiarismData)
      .catch(() => { });
    let that = this;
    setTimeout(() => that.setState({ ...that.state, showViolationDialog: false }), 2000);
    clearInterval(this.violationTimerID);
  }

  windowBlured() {
    let that = this;
    let plagiarismData = { attemptCode: this.state.attemptCode, blur: true };
    axios
      .put(constants.API_BASE_URL + "/attempts/plagiarism", plagiarismData)
      .catch(() => { });

    setTimeout(function () {
      that.setState((prevState) => ({ ...prevState, showViolationDialog: true }));
    }, 2000);

    this.violationTimerID = setInterval(function () {
      that.setState((prevState) => ({ ...prevState, violationCount: prevState.violationCount + 1 }));
    }, 5000);
  }

  async populateQuizCB(response, quizId, attemptCode) {
    let that = this;
    let initQuizRes;

    try {
      initQuizRes = await axios.post(constants.API_BASE_URL + "/attempts/plagiarism", {
        attemptCode: attemptCode,
        quizId: quizId,
        userId: UserProfile.getUserProfile().id,
      });
    } catch (err) {
      alert("Error tracking the quiz. Report this error. Code: ERR_START_TRACK");
    }

    let copiedStates = Object.assign({}, this.state);
    copiedStates.numberOfQuizAttempts = response.data.numberOfAttempts;
    for (let i = this.state.listOfQuiz.length - 1; i >= 0; i--) {
      if (this.state.listOfQuiz[i].id === quizId) {
        let steps = Array.from({ length: response.data.details.listOfQuestions.length }, (x, j) => j);
        copiedStates.selectedQuizId = quizId;
        copiedStates.attemptCode = attemptCode;
        copiedStates.listOfQuestions = response.data.details.listOfQuestions;
        copiedStates.linear = this.state.listOfQuiz[i].linear;
        copiedStates.showAnalysis = this.state.listOfQuiz[i].showAnalysis;
        copiedStates.redirectNextQuiz = this.state.listOfQuiz[i].redirectNextQuiz;
        copiedStates.webcamEnabled = this.state.listOfQuiz[i].webcamEnabled;
        copiedStates.showResults = this.state.listOfQuiz[i].showResults;
        copiedStates.mode = this.state.listOfQuiz[i].mode || "untimed";
        copiedStates.types = this.state.listOfQuiz[i].types;
        copiedStates.shuffleOptions =
          typeof this.state.listOfQuiz[i].shuffleOptions === "undefined"
            ? true
            : this.state.listOfQuiz[i].shuffleOptions;
        if (this.state.listOfQuiz[i].mode === "timed") {
          copiedStates.timeout =
            initQuizRes.data.resumeTime >= 0 ? initQuizRes.data.resumeTime : this.state.listOfQuiz[i].timeout;
        }

        copiedStates.steps = steps;
        copiedStates.displayQuestions = true;

        window.addEventListener("focus", this.windowFocused);
        window.addEventListener("blur", this.windowBlured);
        this.setState(copiedStates);
        break;
      }
    }
  }

  componentDidMount() {
    let that = this;
    let copiedStates = Object.assign({}, this.state);

    axios
      .get(constants.API_BASE_URL + "/quizs/users/" + UserProfile.getUserProfile().id)
      .then(function (response) {
        let listOfQuiz = [];
        response.data.details.forEach((q) => {
          q.visible = true;
          copiedStates.quizIds.push(q.id);
          listOfQuiz.push(q);
        });
        copiedStates.isLoading = false;
        copiedStates.listOfQuiz = listOfQuiz;
        if (listOfQuiz.length === 0) copiedStates.noQuizFound = true;
        copiedStates.mobileFilters = true;
        that.setState(copiedStates);
      })
      .catch(function () {
        copiedStates.noTagsFound = true;
        copiedStates.noQuizFound = true;
        that.setState(copiedStates);
      });
  }

  openCreateQuizDialog() {
    let that = this;
    let copiedStates = Object.assign({}, that.state);
    axios
      .get(constants.API_BASE_URL + "/questions/categories/all")
      .then(function (response) {
        copiedStates.newQuiz.availableTopics = response.data.details;
        that.setState(copiedStates);
      })
      .catch(() => { });
    copiedStates.newQuiz.createQuizDialog = true;
    that.setState(copiedStates);
  }

  subTopicSelectedCB(checkedSubTopics) {
    let copiedStates = Object.assign({}, this.state);
    copiedStates.newQuiz.subTopics = checkedSubTopics;
    this.setState(copiedStates);
  }

  handleInputChange(e) {
    let copiedStates = Object.assign({}, this.state);
    let topic = e.target.value;
    if (e.target.name === "subTopics") {
      if (copiedStates.newQuiz.subTopics.indexOf(topic) > -1) {
        let index = copiedStates.newQuiz.subTopics.indexOf(topic);
        copiedStates.newQuiz.subTopics.splice(index, 1);
      } else {
        copiedStates.newQuiz[e.target.name].push(topic);
      }
    } else {
      copiedStates.newQuiz[e.target.name] = e.target.value;
    }
    this.setState(copiedStates);
  }

  handleSignIn(ref, isValid) {
    let copiedStates = Object.assign({}, this.state);
    copiedStates.error[ref] = isValid ? 0 : 1;
    this.setState(copiedStates);
  }

  render() {
    let { classes } = this.props;
    let { quizIds, numberOfQuizAttempts } = this.state;
    let attemptWiseReport = "";

    if (this.state.showAnalysis === true) {
      attemptWiseReport = (
        <AttemptWiseReport
          attemptCode={this.state.attemptCode}
          showResults={this.state.showResults}
          numberOfQuizAttempts={numberOfQuizAttempts}
          redirectNextQuiz={this.state.redirectNextQuiz}
        />
      );
    }

    // If selected quiz is not available, show a "Not Available" dialog
    if (
      this.state.selectedQuizId !== null &&
      this.state.isLoading === false &&
      quizIds.indexOf(this.state.selectedQuizId) === -1
    ) {
      return (
        <Dialog
          open={true}
          fullScreen
          onClose={this.handleEducationalDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <AppBar id={"fixed-header"} position="fixed" className={classes.appBar}>
            <Toolbar>
              <Typography variant="h2" color="inherit" className={classes.titleFlex}>
                <span className="titleFirstName">{constants.PROD_NAME.charAt(0)}</span>
                {constants.PROD_NAME.substring(1)}
              </Typography>
            </Toolbar>
          </AppBar>
          <DialogContent style={{ textAlign: "center", fontSize: 24 }}>
            <DialogTitle style={{ textAlign: "center", fontSize: 24 }}>NOT AVAILABLE</DialogTitle>
            <br />
            <br />
            <img src="General/stop-sign.png" alt="Not Available" style={{ width: "128px" }} />
            <br />
            <br />
            This test is not available at the moment!
          </DialogContent>
        </Dialog>
      );
    }

    // If no questions are displayed, show a friendly message
    if (this.state.displayQuestions === false) {
      return (
        <div className={classes.root}>
          {attemptWiseReport}
          <Grid
            container
            direction="row"
            spacing={4}
            alignItems="center"
            justify="center"
            className={classes.content}
          >
            {/* Friendly message shown instead of quizzes/companies/view more */}
            <Typography style={{ marginTop: 50, textAlign: "center" }}>
              {/* Friendly message after removing companies tab & view more button */}
              Welcome to Aptimithra, your skill assessment platform.<br></br>When a test is scheduled, simply use the provided direct link to access it.
            </Typography>
          </Grid>
        </div>
      );
    }

    // If questions are displayed, render the quiz questions component
    return (
      <div className="containerWithMargin">
        <Snackbar
          open={this.state.showViolationDialog}
          onClose={() => this.setState({ ...this.state, showViolationDialog: false })}
        >
          <SnackbarContent
            message={
              <div>
                Warning! Leaving the test window will result in plagiarism.
                <br />
                Kindly do not switch tabs/windows while taking the test
              </div>
            }
            className={classes.alertSnackbar}
          />
        </Snackbar>
        <QuizQuestions
          attemptCode={this.state.attemptCode}
          linear={this.state.linear}
          types={this.state.types}
          finishCallback={this.finishCallback}
          mode={this.state.mode}
          shuffleOptions={this.state.shuffleOptions}
          showAnalysis={this.state.showAnalysis}
          webcamEnabled={this.state.webcamEnabled}
          timeout={this.state.mode === "timed" ? this.state.timeout : 0}
          selectedQuizId={this.state.selectedQuizId}
          listOfQuestions={this.state.listOfQuestions}
          steps={this.state.steps}
        />
      </div>
    );
  }
}

ListQuiz.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ListQuiz);
