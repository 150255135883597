import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import * as constants from "../config/constants";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import axios from "axios";
import UserProfile from "../Models/UserProfile";
import AnalyticsTable from "../analytics/analyticsTable";
import DetailedReport from "./detailedReport";
import AppBar from "@material-ui/core/AppBar";
import CircularProgress from "@material-ui/core/CircularProgress";
import Toolbar from "@material-ui/core/Toolbar";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import ShareDialog from "../social/shareDialog";
import ReactHtmlParser from "react-html-parser";
import { Chart } from "react-google-charts";

let redirectInterval = null;
let redirectTimeout = null;

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  flex: {
    flex: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
    color: "white",
  },
  menuButtonMobile: {
    marginLeft: -12,
    color: "white",
  },
  headerIcons: {
    color: "white",
  },
  studentInfo: {
    width: "50vw",
    maxWidth: "50vw",
    margin: "auto",
    textAlign: "center",
    marginTop: "10vh",
  },
});

class AttemptWiseReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      defaultMessage:
        "Congrats! You have submitted your test. We will be releasing your results shortly. Thanks for taking this test.",
      displayDailog: true,
      attemptCode: this.props.attemptCode,
      showResults: this.props.showResults,
      redirectNextQuiz: this.props.redirectNextQuiz,
      redirectCancel: false,
      closeCB: this.props.closeCB,
      secondsToRedirect: 20,
      userId: UserProfile.getUserProfile().id,
      percent: 0,
      totalNumberOfAttempts: 0,
      showShareDialog: false,
      data: {
        questionWise: {},
        subTopicWise: {},
        dayWise: {},
      },
      wholeData: [],
      chartDetails: [],
      loader: true,
    };
    this.handleStopRedirect = this.handleStopRedirect.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleShowShareDialog = this.handleShowShareDialog.bind(this);
    this.loadChartDetails = this.loadChartDetails.bind(this);
    this.toggleShareDialogCB = this.toggleShareDialogCB.bind(this);
    this.convertObjectTo2DArray = this.convertObjectTo2DArray.bind(this);

    this.studentDetails = {
      name: UserProfile.getUserProfile().name,
      email: UserProfile.getUserProfile().email,
      "number Of Attempts": this.props.numberOfQuizAttempts
        ? this.props.numberOfQuizAttempts + 1
        : 1,
      "quiz finished on": String(new Date()).split("GMT")[0].trim(),
    };
  }

  convertObjectTo2DArray(obj) {
    return Object.keys(obj).map((key) => [key, obj[key]]);
  }

  handleClose() {
    this.setState({
      displayDailog: false,
    });
    if (this.state.closeCB) {
      this.state.closeCB();
    }
  }
  handleShowShareDialog() {
    this.setState({
      showShareDialog: true,
    });
  }
  toggleShareDialogCB() {
    this.setState({
      showShareDialog: false,
    });
  }
  loadChartDetails = (quizId) => {
    let copiedStates = Object.assign({}, this.state);
    var that = this;
    axios
      .get(
        constants.API_BASE_URL +
          "/ranking/Quiz/" +
          quizId +
          "/" +
          UserProfile.getUserProfile().id +
          "/topicsUser"
      )
      .then(function (response) {
        copiedStates.chartDetails = response.data.data;
        copiedStates.displayDailog = true;
        that.setState(copiedStates);
      })
      .catch(function (error) {});
  };
  handleStopRedirect = () => {
    clearInterval(redirectInterval);
    clearTimeout(redirectTimeout);
    let copiedStates = Object.assign({}, this.state);
    copiedStates.redirectCancel = false;
    copiedStates.redirectMessage =
      "Click <a href='" +
      copiedStates.redirectNextQuiz +
      "'>here</a> to redirect to the next quiz";
    this.setState(copiedStates);
  };
  componentDidMount() {
    var that = this;
    let copiedStates = Object.assign({}, this.state);
    if (
      this.state.showResults !== false &&
      (typeof this.state.redirectNextQuiz === "undefined" ||
        this.state.redirectNextQuiz.length === 0)
    ) {
      axios
        .get(
          constants.API_BASE_URL +
            "/attempts/attemptCode/" +
            this.state.attemptCode +
            "/new"
        )
        .then(function (response) {
          var questionWise = {};
          var subTopicWise = {};
          let isCorrect = 0;
          let quizId = response.data[0].quizId._id;
          copiedStates.numberOfQuestions = 0;
          let totalMarks = 0;
          let markEarned = 0;
          for (var i = response.data.length - 1; i >= 0; i--) {
            if (response.data[i].attemptCode !== "NONE") {
              copiedStates.numberOfQuestions++;
            }
            if (response.data[i].isCorrect === true) {
              isCorrect = isCorrect + 1;
            }
            totalMarks += response.data[i].questionId.mark;
            markEarned += response.data[i].markEarned;
            for (
              var j = response.data[i].questionId.subTopics.length - 1;
              j >= 0;
              j--
            ) {
              var subTopic = response.data[i].questionId.subTopics[j];
              response.data[i].displayKey = subTopic;
              if (subTopic in subTopicWise) {
                response.data[i].displayKey = subTopic;
                subTopicWise[subTopic].push(response.data[i]);
              } else {
                response.data[i].displayKey = subTopic;
                subTopicWise[subTopic] = [response.data[i]];
              }
            }
            if (response.data[i].questionId.id in questionWise) {
              response.data[i].displayKey = response.data[i].questionId.title;
              questionWise[response.data[i].questionId.id].push(
                response.data[i]
              );
            } else {
              response.data[i].displayKey = response.data[i].questionId.title;
              questionWise[response.data[i].questionId.id] = [response.data[i]];
            }
          }
          copiedStates.percent = markEarned / totalMarks;
          copiedStates.totalNumberOfAttempts = response.data[0]
            ? response.data[0].quizId.listOfQuestions.length
            : 0;
          copiedStates.data = {
            subTopicWise: {
              title: "Topic Wise",
              data: subTopicWise,
              chartType: "ColumnChart",
              displayKey: "topic",
            },
          };
          copiedStates.wholeData = response.data;
          copiedStates.loader = false;
          that.setState(copiedStates, function () {
            that.loadChartDetails(quizId);
          });
        });
    }
    if (this.state.redirectNextQuiz) {
      copiedStates.redirectCancel = true;
      copiedStates.redirectMessage =
        "You will be redirected to the next quiz in next <b>" +
        that.state.secondsToRedirect +
        "</b> seconds";
      redirectTimeout = setTimeout(function () {
        window.location = copiedStates.redirectNextQuiz;
      }, copiedStates.secondsToRedirect * 1000);
      redirectInterval = setInterval(function () {
        copiedStates.secondsToRedirect--;
        copiedStates.redirectMessage =
          "You will be redirected to the next quiz in next <b>" +
          that.state.secondsToRedirect +
          "</b> seconds";
        that.setState(copiedStates);
      }, 1000);
      that.setState(copiedStates);
    }
  }
  render() {
    let { classes } = this.props;
    let maxWidth = "100%";
    let tablesToDisplay = [];
    let data = this.state.chartDetails;

    let quizName = this.props.quizName ? this.props.quizName : "";
    let uniqueCat = new Set();
    let topicGraph = [["Topic", "Percentage"]];
    let timeGraph = [["Topic", "Time Spent"]];

    let topicGraphObject = {};
    let timeGraphObject = {};

    Object.keys(data).map((users) => {
      let overallScore = 0;
      let overallAttempts = 0;
      let overallMaxScores = 0;
      let overallNumberOfQuestions = 0;
      let overallTimeSpent = 0;
      let overallPercentage = 0;

      Object.keys(data[users]).map((cat) => {
        let catToDisplay = cat.indexOf(":") > 0 ? cat.split(":")[0] : cat;
        //TODO: Check if this "if" is required or not (Check previous commits)
        if (true) {
          overallTimeSpent += data[users][cat].timeSpent;
          overallPercentage += data[users][cat].percentage;
          console.log(catToDisplay);
          if (catToDisplay in topicGraphObject) {
            topicGraphObject[catToDisplay]["score"] += data[users][cat].score;
            topicGraphObject[catToDisplay]["totalScore"] +=
              data[users][cat].totalScore;
          } else {
            console.log("Current", topicGraphObject);
            topicGraphObject[catToDisplay] = {
              score: data[users][cat].score,
              totalScore: data[users][cat].totalScore,
            };
          }

          if (catToDisplay in timeGraphObject) {
            timeGraphObject[catToDisplay] =
              timeGraphObject[catToDisplay] +
              Math.round(
                data[users][cat].timeSpent / data[users][cat].attempts / 1000
              );
          } else {
            timeGraphObject[catToDisplay] = Math.round(
              data[users][cat].timeSpent / data[users][cat].attempts / 1000
            );
          }

          // topicGraph.push([
          //   catToDisplay,
          //   Math.round(
          //     (data[users][cat].score * 100) / data[users][cat].totalScore
          //   ),
          // ]);
          // timeGraph.push([
          //   catToDisplay,
          //   Math.round(
          //     data[users][cat].timeSpent / data[users][cat].attempts / 1000
          //   ),
          // ]);
        }
      });
      console.log(topicGraphObject);

      //topicGraph = this.convertObjectTo2DArray(topicGraphObject);
      topicGraph = Object.keys(topicGraphObject).map((cat) => [
        cat,
        (topicGraphObject[cat]["score"] * 100) /
          topicGraphObject[cat]["totalScore"],
      ]);
      timeGraph = this.convertObjectTo2DArray(timeGraphObject);
      topicGraph.unshift(["Topic", "Percentage"]);
      timeGraph.unshift(["Topic", "Time Spent"]);
    });

    if (
      this.state.showResults !== true ||
      (this.state.redirectNextQuiz && this.state.redirectNextQuiz.length !== 0)
    ) {
      return (
        <div>
          <Dialog
            open={this.state.displayDailog}
            onClose={this.handleClose}
            fullScreen
            transition={Transition}
          >
            <AppBar>
              <Toolbar>
                <IconButton
                  color="inherit"
                  onClick={this.handleClose}
                  aria-label="Close"
                >
                  <CloseIcon />
                </IconButton>
                <Typography
                  variant="title"
                  color="inherit"
                  className={classes.flex}
                >
                  {"Your results for the quiz"}
                </Typography>
                <Button color="inherit" onClick={this.handleShowShareDialog}>
                  share
                </Button>
              </Toolbar>
            </AppBar>
            <DialogContent
              style={{
                backgroundColor: "#e8e8e8 !important",
              }}
            >
              <div className="mainArea">
                <br />
                <br />
                <div className="quizSummary">
                  <div
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <img
                      src="General/check-mark.png"
                      alt="Successfully Submitted"
                      style={{
                        width: "128px",
                      }}
                    />
                    <br />
                    <Typography variant="h3" gutterBottom>
                      {this.state.defaultMessage}
                    </Typography>
                    <div class="studentDetails" class={classes.studentInfo}>
                      {Object.keys(this.studentDetails).map((key) => (
                        <Grid container>
                          <Grid item xs={6}>
                            <Typography variant="overline" gutterBottom>
                              <b>{key}</b>
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography variant="overline" gutterBottom>
                              {this.studentDetails[key]}
                            </Typography>
                          </Grid>
                        </Grid>
                      ))}
                    </div>

                    <br />
                    {ReactHtmlParser(this.state.redirectMessage)}
                    {this.state.redirectCancel && (
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        className="redirectCancelBtn"
                        onClick={this.handleStopRedirect}
                      >
                        Cancel
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </DialogContent>
          </Dialog>
        </div>
      );
    }

    let markSuggestions = {
      "0-25": {
        points: 25,
        messages: [
          "Attempting the same questions might increase your score. Try the same test again.",
          "We are there for you, just work hard!",
          "A lot of scope for improvment. Try another test.",
          "Spend more time on each question. Keep trying...",
        ],
      },
      "26-50": {
        points: 50,
        messages: [
          "Congrats on that! But we are sure that you can improve.",
          "Push yourself. Aim for more score.",
          "If you concentrate and answer, your accuracy will improve.",
          "Don't worry about the time. Just get the question right.",
        ],
      },
      "51-75": {
        points: 75,
        messages: [
          "Awesome work. Get ready for the next test.",
          "Good grade. Let's try another test.",
          "Few steps away from champ.",
        ],
      },
      "75-100": {
        points: 100,
        messages: [
          "You did excellent.",
          "Marvellous.",
          "You are great.",
          "You are a champ.",
        ],
      },
    };
    let attemptSuggestions = {
      "0-50": {
        points: 50,
        messages: [
          "You are attempting very less number of questions.",
          "Try attempting more number of questions.",
        ],
      },
      "51-75": {
        points: 75,
        messages: [
          "Good work on attempting almost all questions. But try attempting more.",
          "Congrats on attempting almost all questions. Next time try attempting all questions.",
        ],
      },
      "75-100": {
        points: 100,
        messages: [
          "It is good that the number of questions that you have attempted is impressive",
          "We appreciate your effort.",
        ],
      },
    };
    let min, max;
    let yourSuggestion = "";
    let lengthOfSuggestion = 0;
    let that = this;
    let totalPoints = 0;
    Object.keys(markSuggestions).map((s) => {
      [min, max] = s.split("-");
      if (
        that.state.percent * 100 >= parseInt(min, 10) &&
        that.state.percent * 100 <= parseInt(max, 10)
      ) {
        lengthOfSuggestion = markSuggestions[s].messages.length;
        yourSuggestion =
          markSuggestions[s].messages[
            Math.round(Math.random() * (lengthOfSuggestion - 1))
          ];
        totalPoints += that.state.percent * 100;
      }
      return 0;
    });
    Object.keys(attemptSuggestions).map((s) => {
      [min, max] = s.split("-");
      let per =
        (that.state.numberOfQuestions / that.state.totalNumberOfAttempts) * 100;
      if (per >= parseInt(min, 10) && per <= parseInt(max, 10)) {
        lengthOfSuggestion = attemptSuggestions[s].messages.length;
        yourSuggestion +=
          " " +
          attemptSuggestions[s].messages[
            Math.round(Math.random() * (lengthOfSuggestion - 1))
          ];
        totalPoints += per;
      }
      return 0;
    });
    totalPoints = Math.round(totalPoints);
    let shareMessage =
      "" +
      UserProfile.getUserProfile().name +
      " has scored " +
      Math.round(this.state.percent * 100) +
      "% in a test. How much can you?";
    return (
      <div>
        <Dialog
          open={this.state.displayDailog}
          onClose={this.handleClose}
          fullScreen
          transition={Transition}
        >
          <AppBar>
            <Toolbar>
              <IconButton
                color="inherit"
                onClick={this.handleClose}
                aria-label="Close"
              >
                <CloseIcon />
              </IconButton>
              <Typography
                variant="title"
                color="inherit"
                className={classes.flex}
              >
                {"Your results for the quiz - " + quizName.toUpperCase()}
              </Typography>
              <Button color="inherit" onClick={this.handleShowShareDialog}>
                share
              </Button>
            </Toolbar>
          </AppBar>
          <DialogContent
            style={{
              backgroundColor: "#e8e8e8 !important",
            }}
          >
            <div className="mainArea">
              <br />
              <br />

              {this.state.loader && (
                <Typography className="circularProgress" component="div">
                  <CircularProgress thickness={4} size={50} color="inherit" />
                </Typography>
              )}

              {this.state.loader === false && (
                <div className="quizSummary">
                  <Typography
                    style={{
                      textAlign: "center",
                    }}
                  >
                    You have attempted <b>{this.state.numberOfQuestions}</b>{" "}
                    {this.state.numberOfQuestions === 1
                      ? "question "
                      : "questions "}
                    out of <b>{this.state.totalNumberOfAttempts}</b> and scored{" "}
                    <b>{Math.round(this.state.percent * 100)}%</b> in this test.
                    <br />
                    <br />
                    {ReactHtmlParser(this.state.redirectMessage)}
                    {this.state.redirectCancel && (
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        className="redirectCancelBtn"
                        onClick={this.handleStopRedirect}
                      >
                        Cancel
                      </Button>
                    )}
                  </Typography>
                  <Typography
                    className="yourSuggestion"
                    style={{
                      textAlign: "center",
                    }}
                  >
                    {ReactHtmlParser(yourSuggestion)}
                  </Typography>
                </div>
              )}

              {this.state.loader === false && (
                <Grid container>
                  <Grid
                    item
                    md={8}
                    sm={12}
                    xs={12}
                    style={{
                      border: "1px solid #ccc",
                      borderRadius: 3,
                    }}
                  >
                    {topicGraph.length > 1 && (
                      <Chart
                        chartType="ColumnChart"
                        data={topicGraph}
                        width="100%"
                        height="550px"
                        legendToggle
                        options={{
                          title: "Topic-wise result analysis",
                          vAxis: { minValue: 0, maxValue: 100 },
                          legend: "none",
                        }}
                      />
                    )}
                  </Grid>
                  <Grid
                    item
                    md={4}
                    sm={12}
                    xs={12}
                    style={{
                      border: "1px solid #ccc",
                      borderRadius: 3,
                    }}
                  >
                    {timeGraph.length > 1 && (
                      <Chart
                        chartType="PieChart"
                        data={timeGraph}
                        width="100%"
                        height="550px"
                        legendToggle
                        options={{
                          title: "Topic-wise time analysis",
                          vAxis: { minValue: 0 },
                        }}
                      />
                    )}
                  </Grid>
                </Grid>
              )}

              {this.state.wholeData.length > 0 && (
                <DetailedReport data={this.state.wholeData} />
              )}
            </div>
          </DialogContent>
        </Dialog>
        {this.state.showShareDialog ? (
          <ShareDialog
            showShareDialog={true}
            shareMessage={shareMessage}
            toggleShareDialogCB={this.toggleShareDialogCB}
          />
        ) : (
          <span></span>
        )}
      </div>
    );
  }
}

AttemptWiseReport.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AttemptWiseReport);
