import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import * as constants from '../config/constants';
import axios from 'axios';
import UserProfile from '../Models/UserProfile';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
});

class AchievementInfo extends PureComponent {
	constructor() {
		super();
		this.state = {
			task : [],
			badgeCount : 0,
			taskGroup : {},
			userId: UserProfile.getUserProfile().id
		}
		this.componentDidMount = this.componentDidMount.bind(this);
	}
	componentDidMount() {
		let copiedStates = Object.assign({}, this.state);
		let that = this;
		axios.get(constants.API_BASE_URL + '/tasks/users/'+ this.state.userId)
		.then(function(response){
			let taskGroup = {};
			let badgeCount = 0;
			response.data.details.map(task => {
				if(task.status === 'CL') {
					badgeCount++;
					(task.entity in taskGroup) ? taskGroup[task.entity].push(task) : taskGroup[task.entity] = [task];
				}
				return true;
			});
			let orderedTaskGroup = {};
			for(let group in taskGroup) {
				orderedTaskGroup[group] = taskGroup[group].sort((a, b) => Number(b.steps) - Number(a.steps));
				orderedTaskGroup[group][0].ranked = orderedTaskGroup[group].length;
				orderedTaskGroup[group] = orderedTaskGroup[group][0];
			}
			copiedStates.badgeCount = badgeCount;
			copiedStates.task = response.data.details;
			copiedStates.taskGroup = orderedTaskGroup;
			that.setState(copiedStates);
		})
		.catch(function(error){
			console.log(error);
		});
	}
	render() {
		let achievements = [];
		let taskGroups = this.state.taskGroup;
		let mileStoneTitles = constants.MILESTONES;
		for(let entity in taskGroups) {
			achievements.push(
				<ListItem 
				dense
				disableGutters
				>
				<Avatar style={{
					width: 60,
					height: 60
				}} src={taskGroups[entity].badge} />
				<ListItemText primary={entity.toUpperCase()} secondary={mileStoneTitles[taskGroups[entity].ranked - 1]}/>
				</ListItem>
				);
			achievements.push(
				<ListItem key={Math.random()}>
				<Divider />
				</ListItem>
				);
		}
		achievements.length !== 0 ? achievements.pop() : achievements.push(
			<div key={Math.random()}>
			<Typography variant="headline">
			No achievements found!
			</Typography>
			<img alt="Not Found" src="/ExpressionIcons/notFound.png" />
			</div>
			);
		
		return (
			<Card className="cardWithMargins profileCard">
			<CardHeader
			title="My Achievements"
			subheader={"Total " + this.state.badgeCount + " achievements"}
			/>
			<CardMedia className="cardMediaImages"
			image={"https://img.haikudeck.com/mi/0E3CC3F5-1CF7-489A-B66A-E88121F500B4.jpg"}
			/>
			<CardContent>
			<Typography component="div">
			<List dense>
			{achievements}
			</List>
			</Typography>
			</CardContent>
			</Card>
			);
	}
}

AchievementInfo.propTypes = {
	classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(AchievementInfo);