import React, { Component } from "react";
import "./App.css";
import SignIn from "./signin";
import Profile from "./profile/profile";
import Performance from "./profile/performance";
import Header from "./header";
import Lessons from "./lesson/lessons";
import ListQuiz from "./quiz/listQuiz";
import CodingPlayGround from "./quiz/codingPlayGround";
import Notes from "./note/notes";
import AttemptList from "./report/attemptList";
import RankingList from "./ranking/rankingList";
import CodingQuestion from "./quiz/codingQuestion";
import Home from "./home/home";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import UserProfile from "./Models/UserProfile";
import NotificationList from "./notification/notificationList";
import axios from "axios";
import * as constants from "./config/constants";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import grey from "@material-ui/core/colors/grey";
import yellow from "@material-ui/core/colors/yellow";
import { NotFound } from "./components";

// const muiTheme = createMuiTheme({
//   palette: {
//     primary: {
//       light: grey[500],
//       dark: "#000",
//       main: grey[900],
//       contrastText: "#fff",
//     },
//     secondary: {
//       light: grey[300],
//       dark: "#000",
//       main: grey[900],
//       contrastText: "#fff",
//     },
//   },
// });

const muiTheme = createTheme({
  palette: {
    primary: {
      light: grey[500],
      dark: "#000",
      main: grey[900],
      contrastText: "#fff",
    },
    secondary: {
      light: grey[300],
      dark: "#000",
      main: grey[900],
      contrastText: "#fff",
    },
  },
});

let requestToken = UserProfile.getUserProfile()
  ? constants.getRequestToken(UserProfile.getUserProfile()._id)
  : constants.getRequestToken("ABCD");
axios.defaults.headers.common = {
  "X-Request-Token": requestToken,
};

axios.interceptors.request.use(
  function (request) {
    if (["put", "post", "delete"].indexOf(request.method) > -1) {
      request = constants.processRequest(request, requestToken);
    }
    return request;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    response = constants.processResponse(response, requestToken);
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

class App extends Component {
  constructor() {
    super();
    this.state = {
      isLogin: true,
      user: UserProfile.getUserProfile(),
    };
    if (typeof this.state.user === "undefined" || this.state.user == null) {
      this.state.isLogin = false;
    } else if (this.state.user && this.state.user["isLoggedIn"] === false) {
      this.state.isLogin = false;
    } else {
      this.state.isLogin = true;
    }
  }

  render() {
    console.log(
      "%cStop ! This is a browser feature intended for developers only.",
      "color: #000000; font-family: sans-serif; font-size: 4.5em; font-weight: bolder; text-shadow: #000 1px 1px;"
    );
    if (this.state.isLogin === false) {
      window.history.pushState(
        { html: "Hello", pageTitle: "pageTitle" },
        "",
        constants.HOST_URL + "?redirect=" + window.location.href
      );
      return (
        <div>
          <ThemeProvider theme={muiTheme}>
            <SignIn />
          </ThemeProvider>
        </div>
      );
    } else {
      return (
        <div>
          <ThemeProvider theme={muiTheme}>
            <BrowserRouter>
              <div>
                <Header />
                <div className="App mainArea">
                  <Switch>
                    <Route exact={true} path="/" component={ListQuiz} />
                    <Route
                      exact={true}
                      path="/dashboard"
                      component={Performance}
                    />
                    <Route
                      exact={true}
                      path="/play"
                      component={CodingPlayGround}
                    />
                    <Route exact={true} path="/profile" component={Profile} />
                    <Route
                      exact={true}
                      path="/rankings"
                      component={RankingList}
                    />
                    <Route
                      exact={true}
                      path="/lessons/:category/:topic/:subTopic"
                      component={Lessons}
                    />
                    <Route exact={true} path="/note" component={Notes} />
                    <Route exact={true} path="/signin" component={ListQuiz} />
                    <Route exact={true} path="/quiz" component={ListQuiz} />
                    <Route
                      exact={true}
                      path="/report"
                      component={AttemptList}
                    />
                    <Route
                      exact={true}
                      path="/notification"
                      component={NotificationList}
                    />
                    <Route
                      exact={true}
                      path="/code"
                      component={CodingQuestion}
                    />
                    <Route component={NotFound} />
                  </Switch>
                </div>
              </div>
            </BrowserRouter>
          </ThemeProvider>
        </div>
      );
    }
  }
}

export default App;
