import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import * as constants from '../config/constants';
import UserProfile from '../Models/UserProfile';
import axios from 'axios';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Visibility from '@material-ui/icons/Visibility';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import ReactGA from 'react-ga';


class ChangePassword extends Component {
	constructor(props){
		super(props);
		this.state ={
			passwordDailog: typeof this.props.passwordDailog !== "undefined" ? this.props.passwordDailog : false,
			oldPassword: '',
			newPassword: '',
			confirmPassword: '',
			userId: UserProfile.getUserProfile().id,
			closeDialogCB: (this.props.closeDialogCB) ? this.props.closeDialogCB : null,
			showOldPassword: false,
			showNewPassword: false,
			showConfirmPassword: false,
			snackBar: false,
			snackBarMessage: '',
      changePasswordError: '',
      anchorEl: this.props.anchorEl
    }
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleChangePassword = this.handleChangePassword.bind(this);
    this.handleClickshowOldPassword = this.handleClickshowOldPassword.bind(this);
    this.handleClickshowNewPassword = this.handleClickshowNewPassword.bind(this);
    this.handleClickshowOldPassword = this.handleClickshowOldPassword.bind(this);
    this.handleClickshowConfirmPassword =this.handleClickshowConfirmPassword.bind(this);
    this.closeSnackBar = this.closeSnackBar.bind(this);
    this.handlePasswordDailogClose = this.handlePasswordDailogClose.bind(this);
    ReactGA.initialize('UA-125256246-1', {
      gaOptions: {
        userId: UserProfile.getUserProfile().email
      }
    });
  }
  componentWillReceiveProps(nextProps){
    let copiedStates = Object.assign({},this.state);
    copiedStates.passwordDailog = typeof nextProps.passwordDailog !== "undefined" ? nextProps.passwordDailog : false;
    if(nextProps.passwordDailog)
      ReactGA.modalview("ChangePassword");
    copiedStates.oldPassword = '';
    copiedStates.newPassword = '';
    copiedStates.confirmPassword = '';
    copiedStates.changePasswordError = '';
    copiedStates.anchorEl = nextProps.anchorEl;
    this.setState(copiedStates);
  }
  handleInputChange(e) {
    let copiedStates = Object.assign({}, this.state);
    copiedStates.changePasswordError = '';
    copiedStates[e.target.name] = e.target.value;
    this.setState(copiedStates);
  }
  handleClickshowOldPassword = () => {
    this.setState({ showOldPassword: !this.state.showOldPassword });
  };
  handleClickshowNewPassword = () => {
    this.setState({ showNewPassword: !this.state.showNewPassword });
  };
  handleClickshowConfirmPassword = () => {
    this.setState({ showConfirmPassword: !this.state.showConfirmPassword });
  };
  handleChangePassword(){
    var that = this;
    let copiedStates = Object.assign({},this.state);
    let oldPassword = that.state.oldPassword;
    let newPassword = that.state.newPassword;
    let confirmPassword = that.state.confirmPassword;
    if(newPassword !== confirmPassword) {
      copiedStates.changePasswordError = "New Password and Confirm Password should be same";
      that.setState(copiedStates);
      return;
    } else if(newPassword === oldPassword) {
      copiedStates.changePasswordError = "New password cannot be same as Old password";
      that.setState(copiedStates);
      return;
    } else {
      let request = {
        oldPassword: that.state.oldPassword,
        newPassword: that.state.newPassword,
        confirmPassword: that.state.confirmPassword
      }
      axios.put(constants.API_BASE_URL + '/users/changePassword/' + that.state.userId, request)
      .then(function (response) {
        if(response.data.code === 200){
          copiedStates.passwordDailog = false;
          copiedStates.anchorEl = null;
          copiedStates.oldPassword = '';
          copiedStates.newPassword = '';
          copiedStates.confirmPassword = '';
          copiedStates.snackBar = true;
          copiedStates.snackBarMessage = response.data.message;
          ReactGA.event({
            category: 'ChangePassword',
            action: 'Success'
          });
          (that.state.closeDialogCB) && (that.state.closeDialogCB('User cancelled by pressing cancel button'));
        }
        else if(response.data.code === 401){
          copiedStates.changePasswordError = response.data.message;
          copiedStates.passwordDailog = true;
          copiedStates.anchorEl = null;
          ReactGA.event({
            category: 'ChangePassword',
            action: 'Error: ' + response.data.message
          });
        }
        else if(response.data.code === 504){
          copiedStates.changePasswordError = response.data.message;
          copiedStates.passwordDailog = true;
          ReactGA.event({
            category: 'ChangePassword',
            action: 'Error:' + response.data.message
          });
        }
        that.setState(copiedStates);
      }).catch(function (error) {
        ReactGA.event({
          category: 'ChangePassword',
          action: 'Error: Network'
        });
        (that.state.closeDialogCB) && (that.state.closeDialogCB('User cancelled by pressing cancel button'));
      });
    }
  }
  closeSnackBar(){
    this.setState({
      snackBar: false
    })
  }
  handlePasswordDailogClose(){
    let copiedStates = Object.assign({},this.state);
    copiedStates.passwordDailog = false;
    copiedStates.oldPassword = '';
    copiedStates.newPassword = '';
    copiedStates.confirmPassword = '';
    this.setState(copiedStates);
    (this.state.closeDialogCB) && this.state.closeDialogCB('User cancelled by pressing cancel button');
  }
  render(){
    return(
     <div>
     <Dialog
     open={this.state.passwordDailog}
     onClose={this.handlePasswordDailogClose}
     aria-labelledby="alert-dialog-title"
     aria-describedby="alert-dialog-description"
     >
     <DialogTitle>Change Password</DialogTitle>
     <DialogContent>
     <InputLabel htmlFor="adornment-password">Old Password</InputLabel>
     <Input
     id="password"
     fullWidth
     type={this.state.showOldPassword ? 'text': 'password'}
     onChange={this.handleInputChange}
     onKeyUp={this.handleInputChange}
     name="oldPassword"
     value = {this.state.oldPassword}
     margin="none"
     label="Old Password"
     endAdornment={
      <InputAdornment position="end">
      <IconButton
      aria-label="Toggle password visibility"
      onClick={this.handleClickshowOldPassword}
      onMouseDown={this.handleMouseDownPassword}
      >
      {this.state.showOldPassword ? <VisibilityOff />: <Visibility />}
      </IconButton>
      </InputAdornment>
    }
    />
    <InputLabel htmlFor="adornment-password">New Password</InputLabel>
    <Input
    id="password"
    fullWidth
    type={this.state.showNewPassword ? 'text': 'password'}
    onChange={this.handleInputChange}
    onKeyUp={this.handleInputChange}
    name="newPassword"
    value = {this.state.newPassword}
    margin="none"
    label="New Password"
    endAdornment={
      <InputAdornment position="end">
      <IconButton
      aria-label="Toggle password visibility"
      onClick={this.handleClickshowNewPassword}
      onMouseDown={this.handleMouseDownPassword}
      >
      {this.state.showNewPassword ? <VisibilityOff />: <Visibility />}
      </IconButton>
      </InputAdornment>
    }
    />
    <InputLabel htmlFor="adornment-password">Confirm Password</InputLabel>
    <Input
    id="password"
    fullWidth
    type={this.state.showConfirmPassword ? 'text': 'password'}
    onChange={this.handleInputChange}
    onKeyUp={this.handleInputChange}
    name="confirmPassword"
    value = {this.state.confirmPassword}
    margin="none"
    label="Confirm Password"
    endAdornment={
      <InputAdornment position="end">
      <IconButton
      aria-label="Toggle password visibility"
      onClick={this.handleClickshowConfirmPassword}
      onMouseDown={this.handleMouseDownPassword}
      >
      {this.state.showConfirmPassword ? <VisibilityOff />: <Visibility />}
      </IconButton>
      </InputAdornment>
    }
    />
    <br />
    <br />
    <p style={{
      width: '100%',
      color: 'red',
      textAlign: 'center'
    }}>{this.state.changePasswordError}</p>
    </DialogContent>    
    <DialogActions>
    <Button variant="flat" onClick={this.handlePasswordDailogClose}>
    Cancel
    </Button>
    <Button onClick={this.handleChangePassword}
    variant="contained"
    color="primary" autoFocus
    disabled={this.state.oldPassword.length === 0 || this.state.newPassword.length === 0 || this.state.confirmPassword.length === 0} >
    Update
    </Button>
    </DialogActions>
    </Dialog>
    <Snackbar
    id="snackbar-profile"
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    onClose={this.closeSnackBar}
    open={this.state.snackBar}
    autoHideDuration={3000}
    SnackbarContentProps={{
      'aria-describedby': 'message-id',
    }}
    message={<span id="message-id">{this.state.snackBarMessage}</span>}
    />
    </div>
    )
  }
}
export default ChangePassword;