import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import GroupData from './groupData';
// import prettyMs from 'pretty-ms';
// const prettyMs = require('pretty-ms');

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  table: {
    width: '100%'
  },
});

function calculateForSingleGroup(group, data, displayKey) {
  var correctAnswers = 0;
  var markEarned = 0;
  var timeSpent = 0;
  var additionalDetails = '';
  for (var i = data.length - 1; i >= 0; i--) {
    if(data[i].isCorrect === true)
      correctAnswers++;
    markEarned = markEarned + data[i].markEarned;
    timeSpent = timeSpent + data[i].timeSpent;
  }
  if(displayKey && displayKey === 'topic') {
    displayKey = group;
  }
  else if(displayKey && displayKey === 'name') {
    displayKey = data[0].quizDetails.title;
    additionalDetails = data[0].quizDetails;
  }
  else if(displayKey && displayKey === 'title') {
    displayKey = data[0].questionId.title;
    additionalDetails = data[0].questionId;
  }
  return {
    groupId: group,
    displayKey: displayKey,
    percentage: (correctAnswers / data.length) * 100,
    numberOfAttempts: data.length,
    correctAnswers: correctAnswers,
    markEarned: markEarned,
    avgTimeSpent: (timeSpent  / data.length / 1000),
    additionalDetails: additionalDetails,
  };
}

class AnalyticsTable extends Component {
  constructor(props) {
    super(props);
    var data = [];
    for(var group in this.props.data) {
      data.push(calculateForSingleGroup(group, this.props.data[group], this.props.displayKey));
    }
    this.state = {
      data: data,
      props: props,
      displayKey: this.props.displayKey,
      additionalDetails: (this.props.additionalDetails) ? this.props.additionalDetails : null,
    }
  }
  componentWillReceiveProps(nextProps) {
    var data = [];
    for(var group in nextProps.data) {
      data.push(calculateForSingleGroup(group, nextProps.data[group], nextProps.displayKey));
    }
    this.setState({
      data: data,
    });
    this.setState({
      displayKey: nextProps.displayKey,
    });
    this.setState({
      additionalDetails: (nextProps.additionalDetails) ? nextProps.additionalDetails : null,
    });
  }
  render() {
    var that = this;
    return (
      <div style={{
        overflowX: 'auto'
      }}>
      {this.state.data.map((n, index) => {
        return (
          <GroupData
          displayKey={that.state.displayKey}
          key={"GroupData" + index}
          data={n}
          additionalDetails={that.state.additionalDetails}
          />
          );
      })}
      </div>
      );
  }  
}

AnalyticsTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AnalyticsTable);