import * as constants from "../config/constants";
import React, { Component } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import UserProfile from "../Models/UserProfile";
import Tooltip from "@material-ui/core/Tooltip";
import Grid from "@material-ui/core/Grid";
import Options from "./options";
import Tags from "../tag/tags";
import BookmarkBorderIcon from "@material-ui/icons/BookmarkBorder";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import OfflineBoltIcon from "@material-ui/icons/OfflineBolt";
import MaximizeIcon from "@material-ui/icons/Maximize";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import ClearIcon from "@material-ui/icons/Clear";
import IconButton from "@material-ui/core/IconButton";
import ReactHtmlParser from "react-html-parser";
import ReactGA from "react-ga";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  cardTitle: {
    fontSize: 16,
    fontWeight: 700,
    textTransform: "uppercase",
  },
});

var startTime;
var endTime;

class CodingPlayGround extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: UserProfile.getUserProfile().email,
      randomId: Math.random(),
    };
  }
  componentDidMount() {
    window.SEC_HTTPS = false;
    window.SEC_BASE = "compilers.widgets.sphere-engine.com";
    (function (d, s, id) {
      window.SEC = window.SEC || (window.SEC = []);
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src =
        (window.SEC_HTTPS ? "https" : "http") +
        "://" +
        window.SEC_BASE +
        "/static/sdk/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "sphere-engine-compilers-jssdk");
  }
  render() {
    let { classes } = this.props;
    return (
      <div
        style={{
          paddingTop: 15,
          height: "80vh",
        }}
        key={this.state.randomId}
      >
        <div
          key={this.state.randomId}
          id={"example-widget" + this.state.randomId}
          className="se-widget"
          data-widget={this.state.sphereEngineQuestionId}
          data-user-id={
            UserProfile.getUserProfile().id + "_" + this.state.attemptCode
          }
        >
          Loading your question...
        </div>
      </div>
    );
  }
}

CodingPlayGround.contextTypes = {
  theme: PropTypes.object,
};

export default withStyles(styles)(CodingPlayGround);
