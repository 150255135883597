import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Options from './options';
import Grid from '@material-ui/core/Grid';
import Tags from '../tag/tags';
import UserProfile from '../Models/UserProfile';
import ReactHtmlParser from 'react-html-parser';
import axios from 'axios';
import { withStyles } from '@material-ui/core/styles';
import * as constants from '../config/constants';

const styles = theme => ({
	cardTitle: {
		fontSize: 16,
		fontWeight: 700,
		textTransform: 'uppercase'
	}
});

class ReviewCodingQuestion extends Component {
	constructor(props) {
		super(props);
		this.state = {
			quizId: this.props.quizId,
			sphereEngineQuestionId: this.props.sphereEngineQuestionId,
			sphereEngineProblemId: this.props.sphereEngineProblemId,
			timeSpent: this.props.timeSpent,
			seqId: this.props.seqId,
			id: this.props.id,
			title: this.props.title,
			explanation: this.props.explanation,
			description: this.props.description,
			options: this.props.options,
			subTopics: this.props.subTopics,
			tags: this.props.tags,
			selectedOption: this.props.selectedOption,
			isCorrect: this.props.isCorrect,
			correctOptions: this.props.correctOptions,
			withMargin: (typeof(this.props.withMargin) !== 'undefined') ? this.props.withMargin : true,
			source: "",
			question: ""
		};
	}
	componentDidMount() {
		let that = this;
		axios.get(constants.API_BASE_URL + '/questions/coding/' + UserProfile.getUserProfile().id + "/" + this.state.sphereEngineProblemId + "/" + this.state.selectedOption)
		.then(function(response) {
			console.log("ReviewCodingQuestion response", response);
			that.setState({
				question: response.data.details.problem.body,
				source: response.data.details.submission
			});
		})
		.catch(function(error) {
		});
	}
	render() {
		
		let { classes } = this.props;

		let margin = (this.state.withMargin === false) ? 0 : '15px';
		return (
			<div className="question">
			<Card style={{
				margin: margin,
				boxShadow: 'none'
			}}>
			<CardContent style={{
				padding: 8
			}}>
			<Grid container>
			<Grid item md={7} style={{
				border: '1px solid rgb(204, 204, 204)',
				borderRadius: 5
			}}>
			<div className="quizQuestionDescription">
			<pre>
			{ReactHtmlParser(this.state.question)}
			</pre>
			</div>
			</Grid>
			<Grid md={5}>
			<div className="quizQuestionOptions">
			<pre>
			{this.state.source}
			</pre>
			</div>
			</Grid>
			</Grid>
			</CardContent>
			</Card>
			</div>
			);
	}
}

ReviewCodingQuestion.contextTypes = {
	theme: PropTypes.object,
};

export default withStyles(styles)(ReviewCodingQuestion);