import React, { Component } from "react";
import Timecode from "react-timecode";
import LinearProgress from "@material-ui/core/LinearProgress";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  linearProgressRoot: {
    height: 10,
  },
});

class MyTimer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      time: this.props.time,
      duration: this.props.duration,
      progress: this.props.progress,
    };
  }
  componentWillReceiveProps(nextProps) {
    this.setState({
      time: nextProps.time,
      duration: nextProps.duration,
      progress: nextProps.progress,
    });
  }
  render() {
    return (
      <div className="quizTimerContainer">
        <Timecode
          className="quizTimer"
          time={this.state.duration - this.state.time}
        />
        <LinearProgress
          style={{
            marginTop: -6,
            borderBottomLeftRadius: 5,
            borderBottomRightRadius: 5,
          }}
          variant="determinate"
          value={Math.round((this.state.time / this.state.duration) * 100)}
        />
      </div>
    );
  }
}

export default withStyles(styles)(MyTimer);
