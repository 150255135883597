import React, { Component } from 'react';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Row, Col } from 'reactstrap';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import AddIcon from '@material-ui/icons/Add';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import SearchIcon from '@material-ui/icons/Search';
import axios from 'axios';
import * as constants from '../config/constants';
import UserProfile from '../Models/UserProfile';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Note from './note';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';

const styles = theme => ({
	root: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	margin: {
		margin: theme.spacing.unit,
	},
	withoutLabel: {
		marginTop: theme.spacing.unit * 3,
	},
	textField: {
		flexBasis: 200,
	},
});

class Notes extends Component {

	constructor(props) {
		super(props);
		this.state = {
			user: UserProfile.getUserProfile(),
			dialogOpen : false,
			notes: [],
			closeIconVisible: false,
			tags : [],
			searchTerm : '',
			selectTagsVisible : false,
			selectedTags: [],
			snackBar : false,
			snackBarMessage : '',
			newNote: {
				userId: UserProfile.getUserProfile().id,
				noteId: null,
				category: 'direct',
				topic: 'direct', 
				subTopic: 'direct',
				title: null,
				description: null,
				tags: ''
			},
			error : {},
			noDataFound: false
		};
		this.searchFocusOff = this.searchFocusOff.bind(this);
		this.searchFocusOn = this.searchFocusOn.bind(this);
		this.searchTags = this.searchTags.bind(this);
		this.makeAllNotesInvisible = this.makeAllNotesInvisible.bind(this);
		this.makeAllNotesVisible = this.makeAllNotesVisible.bind(this);
		this.addCard = this.addCard.bind(this);
		this.closeDialog = this.closeDialog.bind(this);
		this.displayCard = this.displayCard.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
		this.deleteCallBack = this.deleteCallBack.bind(this);
		this.handleSearchChange =this.handleSearchChange.bind(this);
		this.closeSnackBar = this.closeSnackBar.bind(this);
	}
	deleteCallBack(noteId) {
		var notedeleteArray = this.state.notes;
		var allNotes = [];

		for(var i = 0; i < notedeleteArray.length; i++) {
			if(noteId !== notedeleteArray[i].noteId) {
				allNotes.push(notedeleteArray[i])
			}
		}
		this.setState({notes : allNotes});
	}
	componentDidMount(){
		let that = this;
		let copiedStates = Object.assign({}, this.state);
		axios.get(constants.API_BASE_URL + '/note/users/' + that.state.user.id, {
		})
		.then(function (response) {
			let notes = [];
			let tags = [];
			for (let i = response.data.length - 1; i >= 0; i--) {
				notes.push(response.data[i]);
				tags = tags.concat(response.data[i].tags);
			}
			if(notes.length === 0)
				copiedStates.noDataFound = true;
			copiedStates.tags = tags;
			copiedStates.notes = notes;
			that.setState(copiedStates);
		})
		.catch(function (error) {
			copiedStates.noDataFound = true;
			that.setState(copiedStates);
		});
	}
	makeAllNotesVisible() {
		let copiedStates = Object.assign({}, this.state);
		for(let keys in copiedStates.notes) {
			copiedStates.notes[keys].visible = true;
		}
		copiedStates.searchTerm = '';
		copiedStates.selectedTags = [];
		this.setState(copiedStates);
	}
	makeAllNotesInvisible() {
		let copiedStates = Object.assign({}, this.state);
		for(let keys in copiedStates.notes) {
			copiedStates.notes[keys].visible = false;
		}
		this.setState(copiedStates);
	}
	addCard() {
		this.setState({'dialogOpen': true});
	}
	closeDialog() {
		this.setState({'dialogOpen' : false});
	}
	handleInputChange(e) {
		let copiedStates = Object.assign({}, this.state);
		copiedStates.newNote[e.target.name] = e.target.value;
		this.setState({copiedStates});
	}
	validate() {
		let copiedStates = Object.assign({}, this.state);
		let invalid = false;
		copiedStates.snackBar = false;
		if(!copiedStates.newNote.title) {
			copiedStates.snackBarMessage = 'Fill in the title';
			invalid = true;
		} else if(!copiedStates.newNote.description) {
			copiedStates.snackBarMessage = 'Fill in the description';
			invalid = true;
		}
		copiedStates.snackBar = invalid;
		this.setState(copiedStates);
		return !invalid;
	}
	displayCard(event) {
		if(!this.validate()) {
			return;
		}
		let copiedStates = Object.assign({},this.state);
		var response = {
			userId: this.state.user.id,
			title : this.state.newNote.title,
			description : this.state.newNote.description,
			category: this.state.newNote.category,
			topic: this.state.newNote.topic,
			subTopic: this.state.newNote.subTopic,
			tags :this.state.newNote.tags.split(',')
		};
		var that = this;
		axios.post(constants.API_BASE_URL + '/note',response)
		.then(function (responseFromServer) {
			copiedStates.snackBarMessage = "Success";
			copiedStates.snackBar = true;
			copiedStates.notes.push(responseFromServer.data);
			copiedStates.newNote.title = null;
			copiedStates.newNote.description = null;
			copiedStates.newNote.tags = '';	
			that.setState(copiedStates);
			that.closeDialog();
		})
		.catch(function (error) {
		});
	}
	handleSearchChange(event) {
		let copiedStates = Object.assign({}, this.state);
		if(event.target.value !== '') {
			var regex = new RegExp((event.target.value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')) , 'ig');
			for(var key in copiedStates.notes) {
				if(copiedStates.selectedTags.length === 0) {
					if((copiedStates.notes[key].title.search(regex) > -1) || (copiedStates.notes[key].description.search(regex) > -1)) {
						copiedStates.notes[key].visible = true;	
					}
					else {
						copiedStates.notes[key].visible = false;
					}
				}
				else {
					if(copiedStates.notes[key].visible === true) {
						if((copiedStates.notes[key].title.search(regex) > -1) || (copiedStates.notes[key].description.search(regex) > -1)) {
							copiedStates.notes[key].visible = true;	
						}
						else {
							copiedStates.notes[key].visible = false;
						}
					}
				}
			}
		}
		else {
			this.searchTags(false);
		}
		copiedStates.searchTerm = event.target.value;
		this.setState(copiedStates);
	}
	searchTags(tag) {
		let copiedStates = Object.assign({}, this.state);
		if(copiedStates.selectedTags.length === 0) {
			this.makeAllNotesInvisible();
		}
		if(tag !== false) {
			let index = copiedStates.selectedTags.indexOf(tag);
			if(index === -1) {
				copiedStates.selectedTags.push(tag);
			}
			else {
				copiedStates.selectedTags.splice(index, 1);
			}	
		}
		if(copiedStates.selectedTags.length > 0) {
			copiedStates.selectedTags.map(tag => {
				if(tag !== '') {
					for(var key in copiedStates.notes) {
						var tagToSearch = copiedStates.notes[key].tags.join(',');
						var regex = new RegExp((tag.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')) , 'ig');
						if(copiedStates.notes[key].tags.length === 0) {
							copiedStates.notes[key].visible = false;
						}
						else if((tagToSearch.search(regex) > -1)) {
							copiedStates.notes[key].visible = true;
						}
					}
				}
				return true;
			});
		}
		copiedStates.selectTagsVisible = false;
		this.setState(copiedStates);
	}
	searchFocusOff() {
		this.makeAllNotesVisible();
		let copiedStates = Object.assign({}, this.state);
		copiedStates.selectTagsVisible = false;
		copiedStates.selectedTags = [];
		copiedStates.searchTerm = '';
		copiedStates.closeIconVisible = false;
		this.setState(copiedStates);
	}
	searchFocusOn() {
		let copiedStates = Object.assign({}, this.state);
		if(copiedStates.selectedTags.length === 0)
			this.makeAllNotesInvisible();
		copiedStates.selectTagsVisible = true;
		copiedStates.closeIconVisible = true;
		this.setState(copiedStates);
	}
	closeSnackBar() {
		this.setState({
			snackBar : false
		})
	}

	render() {

		const { classes } = this.props;
		var notesArray = this.state.notes;
		var notesComponents = [];
		for (var i = 0; i < notesArray.length; i++) {
			notesComponents.push(
				<Note
				key={notesArray[i].noteId}
				noteId={notesArray[i].noteId}
				title={notesArray[i].title}
				visible={(typeof(notesArray[i].visible) !== 'undefined') ? notesArray[i].visible : true}
				description={notesArray[i].description}
				topic={notesArray[i].topic}
				tags= {notesArray[i].tags}
				category={notesArray[i].category}
				subTopic={notesArray[i].subTopic} 
				updatedAt={notesArray[i].updatedAt}
				deleteCallBack={this.deleteCallBack}
				/>
				);
		}
		if(notesComponents.length === 0) {
			this.state.noDataFound ? notesComponents.push(
				<Typography className="nodatafound" >
				--no data found--
				</Typography>
				) : notesComponents.push(
				<Typography className="circularProgress" component="div">
				<CircularProgress thickness={4} size={50} color="default" />
				</Typography>
				);
			}
			return (
				<div>
				<Row style={{
					marginLeft: 0,
					marginRight: 0,
				}} >
				<Col md={{size: 6, offset: 3}}>
				<FormControl fullWidth className={classNames(classes.margin, classes.textField)}>
				<InputLabel fullWidth htmlFor="adornment-password">{(this.state.selectedTags.length === 0) ? 'Search notes' : 'Search in ' +  this.state.selectedTags[0]}</InputLabel>
				<Input
				disabled={this.state.noDataFound}
				id="adornment-password"
				value={this.state.searchTerm}
				onChange={this.handleSearchChange}
				fullWidth
				label="Search"
				onFocus={this.searchFocusOn}
				endAdornment={
					<InputAdornment position="end">
					<IconButton
					aria-label="Toggle password visibility"
					onClick={this.searchFocusOff}
					onMouseDown={this.handleMouseDownPassword}
					>
					{(this.state.closeIconVisible) ? (<CloseIcon />) : (<SearchIcon />)}
					</IconButton>
					</InputAdornment>
				}
				/>
				</FormControl>
				<Paper style={{
					display: (this.state.selectTagsVisible && this.state.selectedTags.length === 0) ? 'block' : 'none',
					marginTop: '15px'
				}} >
				<AppBar position="static" color="default">
				<Toolbar>
				<Typography component="h5" color="inherit">
				Tags
				</Typography>
				</Toolbar>
				</AppBar>
				{this.state.tags.map(tag => {
					return (<Chip 
						deleteIcon={(this.state.selectedTags.indexOf(tag) > -1) ? (<CloseIcon />) : (<CheckIcon />)}
						style={{
							margin: '15px',
							cursor: 'pointer'
						}} label={tag}
						onDelete={() => this.searchTags(tag)}
						/>);
				})}
				</Paper>
				</Col>
				</Row>
				<br />
				{notesComponents}
				<Button
				onClick={this.addCard}
				style={{
					position: 'fixed',
					bottom: '20px',
					right: '15px'
				}}
				size="medium"
				variant="fab"
				color="primary"
				>
				<AddIcon />
				</Button>
				<Dialog 
				open={this.state.dialogOpen}
				onClose={this.closeDialog}
				aria-labelledby="responsive-dialog-title"
				>
				<DialogTitle id="responsive-dialog-title">{this.state.title}</DialogTitle>
				<DialogContent>
				<DialogContentText>
				<TextField
				ref="title"
				label="Title"
				name="title"
				multiline
				fullWidth
				style={{fontWeight: '600'}}
				margin="normal"
				value={this.state.newNote.title}
				onChange={this.handleInputChange}
				onKeyUp={this.handleInputChange}
				/>
				<TextField
				ref="description"
				label="Description"
				name="description"
				multiline
				fullWidth
				margin="normal"
				value={this.state.newNote.description}
				onChange={this.handleInputChange}
				onKeyUp={this.handleInputChange}
				/>
				<TextField
				ref="tags"
				label="Tags"
				name="tags"
				multiline
				fullWidth
				margin="normal"
				value={this.state.newNote.tags}
				onChange={this.handleInputChange}
				onKeyUp={this.handleInputChange}
				/>
				</DialogContentText>
				</DialogContent>
				<DialogActions>
				<Button onClick={this.closeDialog} color="default">
				Cancel
				</Button>
				<Button color="primary" onClick = {this.displayCard}>
				Add note
				</Button>	
				</DialogActions>
				</Dialog>
				<Snackbar
				style={{
					zIndex: 9999
				}}
				id="snackbar-profile"
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				onClose={this.closeSnackBar}
				open={this.state.snackBar}
				autoHideDuration={1000}
				SnackbarContentProps={{
					'aria-describedby': 'message-id',
				}}
				message={<span id="message-id">{this.state.snackBarMessage}</span>}
				/>

				</div>
				);
		}
	}

	Notes.propTypes = {
		classes: PropTypes.object.isRequired,
	};

	export default withStyles(styles)(Notes);
