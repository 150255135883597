import React, { Component } from 'react';
import Snackbar from '@material-ui/core/Snackbar';

class CustomSnackBar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			message: this.props.message,
			autoHide: (this.props.autoHide) ? this.props.autoHide : 3000,
			openState: (typeof(this.props.openState) !== 'undefined') ? this.props.openState : true,
		}
		this.handleClose = this.handleClose.bind(this);
	}
	handleClose() {
		this.setState({
			openState: !this.state.openState
		});
	}
	render() {
		return (
			<Snackbar
			id="snackbar-login"
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'right',
			}}
			style={{
				width: '100%',
				left: 'auto',
				right: 'auto'
			}}
			open={this.state.openState}
			autoHideDuration={3000}
			onClose={this.handleClose}
			SnackbarContentProps={{
				'aria-describedby': 'message-id-login',
			}}
			message={<span id="message-id-login">{this.state.message}</span>}
			/>
			);
	}
}

export default CustomSnackBar;