import * as constants from "./config/constants";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col } from "reactstrap";
import axios from "axios";
import SignUp from "./signup";
import Login from "./login";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Hidden from "@material-ui/core/Hidden";
import Tab from "@material-ui/core/Tab";
import SwipeableViews from "react-swipeable-views";
import Typography from "@material-ui/core/Typography";
import WelcomeUser from "./welcome/welcomeUser";
import PersonPinIcon from "@material-ui/icons/PersonPin";
import CachedIcon from "@material-ui/icons/Cached";
import { withStyles } from "@material-ui/core/styles";
import ReactGA from "react-ga";

let allImages = [""];
let instructions = [
  "Visit tests for taking more than 500 quizzes",
  "Aptimithra has about 100+ lessons including Algorithms & Data Structures",
  "<a href='https://www.youtube.com/channel/UCLr-SledMO6dzI4oV4izKMQ' target='_blank'>Aptimithra by ethnus</a> uploads a video every week in youtube",
  "Visit <a href='https://infytq.infosys.com/' target='_blank' >InfyTQ</a> and get your certification today",
  "Get to know the <a href='https://careers.wipro.com/elite-nth.aspx' target='_blank'>syllabus</a> for Wipro National Talent Hunt",
];

// let time = '3';
let time = new Date().getHours();

let wishWords = {
  night: ["Nightowl", "Insomniac", "Sleepless", "Woken"],
  earlyMorning: ["Earlybird", "Riser", "The Sun", "Early riser"],
  morning: ["Champion", "Victor", "Conqueror", "Champ", "Winner"],
};
let wishWord = "";
if (time > 3 && time < 7) {
  wishWord =
    wishWords.earlyMorning[
      Math.floor(Math.random() * wishWords.earlyMorning.length)
    ];
} else if (time > 21 || time < 3) {
  wishWord =
    wishWords.night[Math.floor(Math.random() * wishWords.night.length)];
} else {
  wishWord =
    wishWords.morning[Math.floor(Math.random() * wishWords.morning.length)];
}

let colors = {
  5: { c: "linear-gradient(to bottom, #ff512f, #f09819)", m: "Good Morning" },
  6: { c: "linear-gradient(to bottom, #ff512f, #f09819)", m: "Good Morning" },
  7: { c: "linear-gradient(to bottom, #ff512f, #f09819)", m: "Good Morning" },
  8: { c: "linear-gradient(to bottom, #ff512f, #f09819)", m: "Good Morning" },
  9: { c: "linear-gradient(to bottom, #ff512f, #f09819)", m: "Good Morning" },
  10: { c: "linear-gradient(to bottom, #43cea2, #185a9d)", m: "Good Morning" },
  11: { c: "linear-gradient(to bottom, #43cea2, #185a9d)", m: "Good Morning" },
  12: { c: "linear-gradient(to bottom, #43cea2, #185a9d)", m: "Good Noon" },
  13: {
    c: "linear-gradient(to bottom, #43cea2, #185a9d)",
    m: "Good Afternoon",
  },
  14: {
    c: "linear-gradient(to bottom, #43cea2, #185a9d)",
    m: "Good Afternoon",
  },
  15: {
    c: "linear-gradient(to bottom, #f3904f, #3b4371)",
    m: "Good Afternoon",
  },
  16: { c: "linear-gradient(to bottom, #f3904f, #3b4371)", m: "Good Evening" },
  17: { c: "linear-gradient(to bottom, #f3904f, #3b4371)", m: "Good Evening" },
  18: { c: "linear-gradient(to bottom, #f3904f, #3b4371)", m: "Good Evening" },
  19: { c: "linear-gradient(to bottom, #2c3e50, #3498db)", m: "Good Evening" },
  20: { c: "linear-gradient(to bottom, #2c3e50, #3498db)", m: "Good Evening" },
  21: { c: "linear-gradient(to bottom, #2c3e50, #3498db)", m: "Good Evening" },
  22: { c: "linear-gradient(to bottom, #2c3e50, #3498db)", m: "Good Evening" },
  23: { c: "linear-gradient(to bottom, #2c3e50, #3498db)", m: "Good Evening" },
  0: { c: "linear-gradient(to bottom, #2c3e50, #3498db)", m: "Good Evening" },
  1: { c: "linear-gradient(to bottom, #2c3e50, #3498db)", m: "Good Evening" },
  2: { c: "linear-gradient(to bottom, #2c3e50, #3498db)", m: "Good Evening" },
  3: { c: "linear-gradient(to bottom, #2c3e50, #3498db)", m: "Good Evening" },
  4: { c: "linear-gradient(to bottom, #2c3e50, #3498db)", m: "Good Evening" },
};

let randomImage = () => {
  return allImages[Math.floor(Math.random() * allImages.length)];
};

const styles = (theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    width: "100%",
    height: "50vh",
    background: "url(ethnus_transparent.png) center center no-repeat",
  },
  instructions: {
    fontSize: 24,
    textTransform: "uppercase",
    textAlign: "center",
    padding: 16,
    position: "absolute",
    width: "100%",
    left: 0,
    bottom: 0,
    backgroundColor: "white",
    borderTop: "1px solid #888",
  },
  wish: {
    fontSize: 36,
    textTransform: "uppercase",
    textAlign: "center",
    marginBottom: 8,
    padding: 16,
  },
  feed: {
    fontSize: 16,
    textAlign: "left",
    marginBottom: 8,
    padding: 16,
  },
});

function TabContainer({ children, dir }) {
  return (
    <Typography component="div" dir={dir} style={{ padding: 8 * 3 }}>
      {children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired,
};

class SignIn extends Component {
  constructor(props) {
    super(props);
    let tabValue = 1;
    this.state = {
      isLogin: false,
      isLoading: false,
      id: null,
      email: "",
      mobile: null,
      name: "",
      gender: null,
      age: null,
      country: null,
      state: null,
      language: null,
      tabValue: tabValue,
      tab: tabValue,
      isMobile: window.innerWidth <= 500,
      instructionIndex: 0,
      feeds: [],
      feedIndex: 0,
    };
    this.handleSignIn = this.handleSignIn.bind(this);
    this.handleTabChange = this.handleTabChange.bind(this);
    this.handleChangeIndex = this.handleChangeIndex.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  componentDidMount() {
    let that = this;
    setInterval(function () {
      that.setState({
        instructionIndex: that.state.instructionIndex + 1,
      });
    }, 5000);
  }
  handleSignIn(event) {
    event.preventDefault();
    this.setState({ isLogin: true });
    this.render();
  }
  handleChange = (event, value) => {
    this.setState({ tab: value });
  };
  handleTabChange(event, value) {
    ReactGA.event({
      category: "SignIn",
      action: "User changed: " + (value === 0 ? "Register" : "Login"),
    });
    this.setState({ tabValue: value });
  }

  handleChangeIndex(index) {
    this.setState({ tabValue: index });
  }

  render() {
    let selectedInstruction =
      instructions[this.state.instructionIndex % instructions.length];
    let selectedFeed =
      this.state.feeds[this.state.feedIndex % this.state.feeds.length];
    if (selectedFeed) {
      selectedFeed = selectedFeed.content;
    } else {
      selectedFeed = "";
    }

    let { isMobile } = this.state;
    let { classes } = this.props;

    return (
      <div className="frontPageCover">
        <CssBaseline />
        <WelcomeUser />
        <Grid
          container
          classes={{
            root: classes.root,
          }}
        >
          {isMobile === false && (
            <div className={classes.instructions}>
              <div
                dangerouslySetInnerHTML={{ __html: selectedInstruction }}
              ></div>
            </div>
          )}
          <Hidden only="xs">
            <Grid
              item
              xs={0}
              sm={4}
              md={6}
              lg={6}
              style={{
                background: colors[time].c,
                // backgroundImage: "url('republic_day_2020.jpg')",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
            >
              {<div className={classes.image}></div>}
              {<div className={classes.wish}>{colors[time].m + "!"}</div>}
            </Grid>
          </Hidden>
          <Grid item xs={12} sm={8} md={6} lg={6} component={Paper}>
            <AppBar
              position="static"
              style={{
                backgroundColor: "white",
                boxShadow: "none",
              }}
            >
              <Tabs
                value={this.state.tabValue}
                onChange={this.handleTabChange}
                indicatorColor="primary"
                textColor="primary"
                centered
              >
                <Tab
                  label={<Typography>Register</Typography>}
                  icon={<img src="FlatIcons/register.png" width="24" />}
                />
                <Tab
                  label={<Typography>Login</Typography>}
                  icon={<img src="FlatIcons/login.png" width="24" />}
                />
              </Tabs>
            </AppBar>
            <SwipeableViews
              axis="x"
              index={this.state.tabValue}
              onChangeIndex={this.handleChangeIndex}
            >
              <TabContainer dir="x">
                <SignUp />
              </TabContainer>
              <TabContainer dir="x">
                <Login />
              </TabContainer>
            </SwipeableViews>
            <Typography
              style={{
                marginTop: 4,
                textAlign: "center",
              }}
            >
              <a
                style={{
                  fontSize: 18,
                }}
                target="_blank"
                href="https://aptimithra-videos.s3.ap-south-1.amazonaws.com/TestProcedure.mp4"
              >
                For log in related queries, click here
              </a>
              {this.state.tabValue === 1 && (
                <div style={{ marginTop: "10px" }}>
                  <a
                    style={{
                      fontSize: 19,
                    }}
                    href="#"
                    onClick={() => this.handleChangeIndex(0)}
                  >
                    Need an account? Click here to Sign Up
                  </a>
                </div>
              )}
            </Typography>
          </Grid>
        </Grid>
      </div>
    );
  }
}

SignIn.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(SignIn);
