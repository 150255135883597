import React from 'react';
import Lesson from './lesson';
import QuizQuestions from '../quiz/quizQuestions';
import Button from '@material-ui/core/Button';
import ForwardIcon from '@material-ui/icons/Forward';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import TocIcon from '@material-ui/icons/Toc';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import ReactDOM from 'react-dom';

class LessonWithQuiz extends Lesson {
	constructor(props) {
		super(props);
		this.state.nextCB = this.props.nextCB;
		this.state.showQuiz = false;
		this.state.prevCB = this.props.prevCB;
		this.state.tocCB = this.props.tocCB;
		this.state.handleLessonPersonalisationCB = this.props.handleLessonPersonalisationCB;
		this.state.quiz = this.props.quiz;
		this.handleNavigation = this.handleNavigation.bind(this);
		this.finishCallback = this.finishCallback.bind(this);
	}
	handleNavigation(action = "next") {
		if(action === "next")
			this.state.nextCB();
		else if(action === "prev")
			this.state.prevCB();
		else if(action === "showQuiz")
			this.setState({ showQuiz: true });
		else if(action === "toc")
			this.state.tocCB();
		ReactDOM.findDOMNode(this).scrollIntoView({
			behavior: "smooth",
			block: "start"
		});
	}
	finishCallback() {
		this.setState({
			showQuiz: false
		});
		this.state.nextCB();
	}
	render() {
		if(this.state.showQuiz === false) {
			return (
				<div>
				<Lesson
				handleLessonPersonalisationCB={this.state.handleLessonPersonalisationCB}
				readingTime={this.state.readingTime}
				noSticky={this.state.noSticky}
				noteOpened={this.state.noteOpened}
				menuOpened={this.state.menuOpened}
				notesCallBack={this.state.notesCallBack}
				notesEdited={this.state.notesEdited}
				key={this.state.lessonId}
				notes={this.state.notes}
				lessonId={this.state.lessonId}
				isBookmarked={this.state.isBookmarked}
				isFavorite={this.state.isFavorite}
				isLiked={this.state.isLiked}
				category={this.state.category}
				topic={this.state.topic}
				subTopic={this.state.subTopic}
				description={this.state.description}
				title={this.state.title} />
				{
					(this.state.isMobile) ? (
						<div style={{
							padding: '15px'
						}}>
						<br /><br />
						<BottomNavigation
						style={{
							position: 'fixed',
							width: '100%',
							left: 0,
							bottom: 0,
							borderTop: '1px solid rgba(0, 0, 0, 0.12)'
						}}
						value={2}
						onChange={this.handleChange}
						showLabels
						>
						<BottomNavigationAction
						onClick={() => this.handleNavigation("toc")}
						label="Toc" icon={<TocIcon />} />
						<BottomNavigationAction
						onClick={() => this.handleNavigation("prev")}
						label="Prev" icon={<ForwardIcon style={{
							transform: 'rotate(180deg)'
						}} />} />
						<BottomNavigationAction
						label="Next"
						onClick={() => this.handleNavigation("next")}
						icon={<ForwardIcon />} />
						</BottomNavigation>
						</div>
						) : (
						<div style={{
							padding: '15px'
						}}>
						<Button style={{
							margin: 5
						}} variant="outlined" onClick={() => this.handleNavigation("prev")} >
						Previuos
						</Button>
						<Button style={{
							margin: 5
						}} variant="contained" color="primary" onClick={() => this.handleNavigation("next")} >
						Next
						</Button>
						</div>
						)
					}
					</div>
					);
		}
		else {
			var steps = Array.apply(null, {length: this.state.quiz.listOfQuestions.length}).map(Number.call, Number);
			return (
				<div className="containerWithMargin">
				<QuizQuestions
				linear={true}
				mode={this.state.quiz.mode}
				types={this.state.quiz.types}
				timeout={(this.state.quiz.mode === 'timed') ? this.state.quiz.timeout : 0}
				selectedQuizId={this.state.quiz.id} 
				listOfQuestions={this.state.quiz.listOfQuestions} 
				steps={steps}
				enableSubmit={false}
				finishCallback={this.finishCallback}
				optionsReadOnly={false}
				/>
				</div>
				);
		}
		
	}
}

export default LessonWithQuiz;