import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableFooter from "@material-ui/core/TableFooter";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import ShareIcon from "@material-ui/icons/Share";
import Button from "@material-ui/core/Button";
import ShareDialog from "../social/shareDialog";
import ReviewQuestion from "../quiz/reviewQuestion";
import ReviewCodingQuestion from "../quiz/reviewCodingQuestion";
import CircularProgress from "@material-ui/core/CircularProgress";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import TextField from "@material-ui/core/TextField";
import UserProfile from "../Models/UserProfile";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import parseMs from "parse-ms";
import { Row } from "reactstrap";

function formatParsedMs(parsedMs) {
  let ms = "";
  if (parsedMs.days) ms += parsedMs.days + " days ";
  if (parsedMs.hours) ms += parsedMs.hours + " hours ";
  if (parsedMs.minutes) ms += parsedMs.minutes + " mins ";
  if (parsedMs.seconds) ms += parsedMs.seconds + " secs ";
  if (ms === "" && parsedMs.milliseconds) ms += "1 sec";
  return ms;
}

const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
  },
  table: {
    width: "100%",
  },
  tableWrapper: {
    overflowX: "auto",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "67.33%",
    flexShrink: 0,
    align: "left",
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  panelContent: {
    margin: 0,
  },
  panel: {
    boxShadow: "none",
  },
});

class DetailedReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      originalData: [[]],
      data: this.props.data,
      rowsPerPage: 10,
      expanded: {},
      page: 0,
      showQuestion: false,
      searchTerm: "",
      currentAttempt: {},
      showShareDialog: false,
      noDataFound: false,
    };

    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.handleQuestionClick = this.handleQuestionClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleShare = this.handleShare.bind(this);
    this.handleExpandClick = this.handleExpandClick.bind(this);
    this.toggleShareDialogCB = this.toggleShareDialogCB.bind(this);
  }
  handleExpandClick(attemptDetails, index) {
    let copiedStates = Object.assign({}, this.state);
    if (index in copiedStates.expanded) {
      copiedStates.expanded[index] = !copiedStates.expanded[index];
    } else {
      copiedStates.expanded[index] = true;
    }
    this.setState(copiedStates);
  }
  handleClose = () => {
    this.setState({
      showQuestion: false,
    });
  };
  handleQuestionClick = (attemptDetails) => {
    let copiedStates = Object.assign({}, this.state);
    var currentAttempt = attemptDetails;
    copiedStates.showQuestion = true;
    copiedStates.currentAttempt = currentAttempt;
    this.setState(copiedStates);
  };
  handleShare = () => {
    this.setState({
      showShareDialog: true,
    });
  };
  toggleShareDialogCB = () => {
    this.setState({
      showShareDialog: false,
    });
  };
  handleChangePage = (event, page) => {
    this.setState({ page });
  };
  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };
  render() {
    let data = [
      [
        "Question Id",
        "Question Title",
        "IsCorrect",
        "Time Spent",
        "Marks Earned",
      ],
    ];

    let noDataFound = false;
    if (this.state.data.length === 0) {
      noDataFound = true;
    }

    const { classes } = this.props;
    const { rowsPerPage, page } = this.state;
    let shareMessage =
      UserProfile.getUserProfile().name +
      " has attempted " +
      (data.length - 1) +
      " questions. What about you?";
    data.shift();
    data = Object.assign(
      [],
      data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    );

    return (
      <div>
        {this.state.showShareDialog ? (
          <ShareDialog
            showShareDialog={true}
            shareMessage={shareMessage}
            toggleShareDialogCB={this.toggleShareDialogCB}
          />
        ) : (
          <span></span>
        )}
        <Card
          style={{
            textAlign: "left",
            display: "inline-block",
            maxWidth: "100%",
            width: "100%",
            boxShadow: "none",
          }}
        >
          <CardHeader
            title="Question-wise report"
            action={
              <div>
                <Button size="small" color="primary" onClick={this.handleShare}>
                  <ShareIcon />
                </Button>
              </div>
            }
          />
          <CardContent>
            <Table className={classes.table}>
              <TableBody>
                {this.state.data.length > 0 ? (
                  this.state.data.map((question, index) => {
                    console.log("n[3]", question);
                    var summary = (
                      <span>
                        {question.isCorrect
                          ? question.isCorrect
                          : "Unattempted"}
                      </span>
                    );
                    var currentQuestion = question.questionId;
                    return (
                      <TableRow key={index}>
                        <TableCell
                          style={{
                            borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                            padding: 0,
                          }}
                        >
                          <ExpansionPanel
                            classes={{
                              root: classes.panel,
                            }}
                            expanded={this.state.expanded[index] ? true : false}
                            onChange={() =>
                              this.handleExpandClick(question, index)
                            }
                          >
                            <ExpansionPanelSummary
                              classes={{
                                content: classes.panelContent,
                              }}
                              expandIcon={<ExpandMoreIcon />}
                            >
                              <Typography>
                                {"Q" + (index + 1 + rowsPerPage * page) + ". "}{" "}
                                {question.isCorrect ? (
                                  <CheckIcon
                                    style={{
                                      color: "green",
                                    }}
                                  />
                                ) : (
                                  <CloseIcon
                                    style={{
                                      color: "red",
                                    }}
                                  />
                                )}
                              </Typography>
                              <Typography className={classes.heading}>
                                {`${question.questionId.category} | Mark(s): ${
                                  question.questionId.mark
                                } ${
                                  question.questionId.nMark > 0
                                    ? `Negative Mark(s): ${question.questionId.nMark}`
                                    : ""
                                }`}
                              </Typography>
                              <Typography className={classes.secondaryHeading}>
                                {summary}
                              </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails
                              style={{
                                padding: 0,
                              }}
                            >
                              {currentQuestion.category !== "Coding" && (
                                <ReviewQuestion
                                  withMargin={false}
                                  quizLinear={true}
                                  images={currentQuestion.images}
                                  subTopics={currentQuestion.subTopics}
                                  optionsType={currentQuestion.optionsType}
                                  timeSpent={
                                    currentQuestion.timeSpent
                                      ? currentQuestion.timeSpent
                                      : 0
                                  }
                                  selectedOption={
                                    typeof question.selectedAnswer !==
                                    "undefined"
                                      ? question.selectedAnswer
                                      : []
                                  }
                                  seqId={0}
                                  isCorrect={question.isCorrect}
                                  correctOptions={
                                    currentQuestion.correctOptions
                                  }
                                  key={currentQuestion.id}
                                  tags={currentQuestion.tags}
                                  id={currentQuestion.id}
                                  title={currentQuestion.title}
                                  explanation={currentQuestion.explanation}
                                  description={currentQuestion.description}
                                  options={currentQuestion.options}
                                />
                              )}
                              {currentQuestion.category === "Coding" && (
                                <ReviewCodingQuestion
                                  withMargin={false}
                                  quizLinear={true}
                                  sphereEngineQuestionId={
                                    currentQuestion.sphereEngineQuestionId
                                  }
                                  sphereEngineProblemId={
                                    currentQuestion.sphereEngineProblemId
                                  }
                                  timeSpent={
                                    currentQuestion.timeSpent
                                      ? currentQuestion.timeSpent
                                      : 0
                                  }
                                  selectedOption={
                                    typeof question.selectedAnswer !==
                                    "undefined"
                                      ? question.selectedAnswer
                                      : []
                                  }
                                  seqId={0}
                                  markEarned={question.markEarned}
                                  correctOptions={
                                    currentQuestion.correctOptions
                                  }
                                  key={currentQuestion.id}
                                  tags={currentQuestion.tags}
                                  id={currentQuestion.id}
                                  title={currentQuestion.title}
                                  explanation={currentQuestion.explanation}
                                />
                              )}
                            </ExpansionPanelDetails>
                          </ExpansionPanel>
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell
                      style={{
                        borderBottom: "none",
                        textAlign: "center",
                      }}
                    >
                      {noDataFound ? (
                        <Typography
                          style={{
                            fontStyle: "italic",
                            color: "#333",
                          }}
                        >
                          {" "}
                          --no data found--{" "}
                        </Typography>
                      ) : (
                        <CircularProgress thickness={7} />
                      )}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    colSpan={3}
                    count={this.state.data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </CardContent>
        </Card>
      </div>
    );
  }
}

DetailedReport.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DetailedReport);
