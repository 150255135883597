import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {Row, Col} from 'reactstrap';
import parseMs from 'parse-ms';

function formatParsedMs(parsedMs) {
	let ms = '';
	if(parsedMs.days)
		ms += parsedMs.days + ' days ';
	if(parsedMs.hours)
		ms += parsedMs.hours + ' hours ';
	if(parsedMs.minutes)
		ms += parsedMs.minutes + ' mins ';
	if(parsedMs.seconds)
		ms += parsedMs.seconds + ' secs ';
	if(parsedMs.milliseconds && ms === '')
		ms += parsedMs.milliseconds + ' ms ';
	return ms;
}

const styles = theme => ({
	card: {
		maxWidth: 400,
	},
	media: {
		height: 0,
		paddingTop: '56.25%',
	},
	actions: {
		display: 'flex',
	},
	expand: {
		transform: 'rotate(0deg)',
		transition: theme.transitions.create('transform', {
			duration: theme.transitions.duration.shortest,
		}),
		marginLeft: 'auto',
	},
	expandOpen: {
		transform: 'rotate(180deg)',
	},
	heading: {
		fontSize: theme.typography.pxToRem(15),
		flexBasis: '33.33%',
		flexShrink: 0,
		align: 'left'
	},
	secondaryHeading: {
		fontSize: theme.typography.pxToRem(15),
		color: theme.palette.text.secondary,
		width: '66%'
	},
});

function calculateForSingleGroup(group, data, displayKey) {
	var correctAnswers = 0;
	var markEarned = 0;
	var timeSpent = 0;
	var additionalDetails = '';
	for (var i = data.length - 1; i >= 0; i--) {
		if(data[i].isCorrect === true)
			correctAnswers++;
		markEarned = markEarned + data[i].markEarned;
		timeSpent = timeSpent + data[i].timeSpent;
	}
	if(displayKey && displayKey === 'attemptCode') {
		displayKey = data[0].attemptCode;
	}
	return {
		groupId: group,
		displayKey: displayKey,
		percentage: (correctAnswers / data.length) * 100,
		numberOfAttempts: data.length,
		correctAnswers: correctAnswers,
		markEarned: markEarned,
		avgTimeSpent: formatParsedMs(parseMs(Math.round(timeSpent  / data.length))),
		additionalDetails: additionalDetails,
	};
}

class GroupData extends Component {
	constructor(props) {
		super(props);
		this.state = {
			props: props,
			data: this.props.data,
			additionalDetails: (this.props.additionalDetails) ? (this.props.additionalDetails) : null,
			expanded: false
		}
		this.handleExpandClick = this.handleExpandClick.bind(this);
	}
	handleExpandClick = (groupId) => {
		this.setState({ expanded: !this.state.expanded });
		if(this.state.additionalDetails && this.state.additionalDetails[groupId]) {
			var currentQuizDetails = [];
			for(var key in this.state.additionalDetails[groupId]) {
				currentQuizDetails.push(
					calculateForSingleGroup(key, this.state.additionalDetails[groupId][key], 'attemptCode')
					);
			}
			this.setState({
				currentQuizDetails: currentQuizDetails
			});
		}
	};
	render() {
		const { classes } = this.state.props;
		var summary = (
			<div>
			Percentage {Math.round(this.state.data.percentage)} %
			{(this.state.additionalDetails) ? (<span> Attempts ({Object.keys(this.state.additionalDetails[this.state.data.groupId]).length})</span>) : ''}
			</div>
			);
		

		return (
			<ExpansionPanel expanded={this.state.expanded} onChange={() => this.handleExpandClick(this.state.data.groupId)} >
			<ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
			<Typography className={classes.heading}>{this.state.data.displayKey}</Typography>
			<Typography component='div' className={classes.secondaryHeading}>{summary}</Typography>
			</ExpansionPanelSummary>
			<ExpansionPanelDetails>
			<Typography style={{
				width: '100%'
			}} component="div">
			{(this.state.additionalDetails) ?  (
				<div>
				{(this.state.currentQuizDetails) ? (
					this.state.currentQuizDetails.map((n, index) => {
						let summary = (
							<Row>
							<Col md={6}>
							Percentage {Math.round(n.percentage)} %
							<br />
							Correct Answers {n.correctAnswers === 0 ? "0" : n.correctAnswers}
							</Col>
							<Col md={6}>
							Number Of Attempts {n.numberOfAttempts}
							<br />
							Avg Time Spent {n.avgTimeSpent}
							</Col>
							</Row>
							);
						return (
							<ExpansionPanel 
							key={"GroupExpansionPanel" + index}
							style={{
								width: '100%',
								cursor: 'default'
							}}
							expanded={false}
							onChange={(event) => {
								event.stopPropagation();
								event.preventDefault();
								return false;
							}} >
							<ExpansionPanelSummary>
							<Typography className={classes.heading}>A{index + 1}</Typography>
							<Typography className={classes.secondaryHeading}>{summary}</Typography>
							</ExpansionPanelSummary>
							<ExpansionPanelDetails>
							</ExpansionPanelDetails>
							</ExpansionPanel>
							);
					})
					) : (''
					)}
					</div>
					) : (
					<div>
					<Row>
					<Col md={6}>
					<List>
					<ListItem>
					<ListItemText primary={Math.round(this.state.data.percentage) + "%"} secondary="Percentage" />
					</ListItem>
					<ListItem>
					<ListItemText primary={this.state.data.numberOfAttempts} secondary="Number Of Attempts" />
					</ListItem>
					</List>
					</Col>
					<Col md={6}>
					<List>
					<ListItem>
					<ListItemText primary={this.state.data.correctAnswers === 0 ? "0" : this.state.data.correctAnswers} secondary="Correct Answers" />
					</ListItem>
					<ListItem>
					<ListItemText primary={formatParsedMs(parseMs(this.state.data.avgTimeSpent * 1000))} secondary="Average Time Spent" />
					</ListItem>
					</List>
					</Col>
					</Row>
					</div>
					)
				}


				</Typography>
				</ExpansionPanelDetails>
				</ExpansionPanel>
				);
	}
}

GroupData.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(GroupData);