import React, { Component } from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';

function shuffleArray(array) {
	var currentIndex = array.length, temporaryValue, randomIndex;
	while (0 !== currentIndex) {
		randomIndex = Math.floor(Math.random() * currentIndex);
		currentIndex -= 1;
		temporaryValue = array[currentIndex];
		array[currentIndex] = array[randomIndex];
		array[randomIndex] = temporaryValue;
	}
	return array;
}

class Options extends Component {
	constructor(props) {
		super(props);
		this.state = {
			questionId: this.props.questionId,
			options: (typeof(this.props.options) === 'undefined') ? [] : this.props.options,
			selectedOption: this.props.selectedOption,
			answerCallback: this.props.answerCallback,
			correctOptions: this.props.correctOptions,
			isCorrect: this.props.isCorrect,
			optionsType: this.props.optionsType,
			readOnly: (typeof(this.props.readOnly) === 'undefined') ? false : this.props.readOnly,
			shuffleOptions: (typeof(this.props.shuffleOptions) === 'undefined') ? true : this.props.shuffleOptions,
		};
		if(this.state.optionsType === 'single' || this.state.optionsType === 'multi') {
			let options = [];
			for(var key in this.state.options) {
				options.push({
					value: key,
					label: this.state.options[key]
				});
			}
			if(this.props.shuffleOptions === true) {
				this.state.options = shuffleArray(options);
			}
			else {
				this.state.options = options;	
			}
		}
		this.handleChange = this.handleChange.bind(this);
	}
	componentWillReceiveProps(nextProps) {
		let copiedStates = Object.assign({}, this.state);
		copiedStates.readOnly = typeof(nextProps.readOnly) !== 'undefined' ? nextProps.readOnly : false;
		copiedStates.correctOptions = nextProps.correctOptions;
		copiedStates.isCorrect = nextProps.isCorrect;
		copiedStates.selectedOption = (nextProps.selectedOption) ? (nextProps.selectedOption) : [];
		this.setState(copiedStates);
	}
	handleChange(event) {
		if(this.state.readOnly === false) {
			let copiedStates = Object.assign({}, this.state);
			if(this.state.optionsType === 'single') {
				copiedStates.selectedOption = event.target.value;
			}
			else if(this.state.optionsType === 'multi') {
				if(copiedStates.selectedOption.includes(event.target.value))
					copiedStates.selectedOption.splice(copiedStates.selectedOption.indexOf(event.target.value), 1);
				else
					copiedStates.selectedOption.push(event.target.value);
			}
			else if(this.state.optionsType === 'text') {
				copiedStates.selectedOption = event.target.value;
			}
			this.setState(copiedStates);
			this.state.answerCallback(copiedStates.selectedOption);
		}
	}
	render() {
		let listOfOptions = [];
		let colorRadio = "primary";
		for (let i = this.state.options.length - 1; i >= 0; i--) {
			colorRadio = "primary";
			if(this.state.optionsType === 'single') {
				let radio;
				if(this.state.readOnly) {
					if(this.state.correctOptions.indexOf(this.state.options[i].value) > -1) {
						colorRadio = green[500];
					}
					else {
						colorRadio = red[500];
					}
					radio = (
						<Radio
						disableRipple={this.state.readOnly}
						style={{
							color: colorRadio
						}}
						/>
						);
				} else {
					radio = (
						<Radio
						color={colorRadio}
						disableRipple={this.state.readOnly}
						/>
						);

				}
				listOfOptions.push(
					<FormControlLabel
					key={this.state.options[i].value}
					value={this.state.options[i].value}
					control={radio} 
					label={this.state.selectedOption[0] === this.state.options[i].value ? <b>{this.state.options[i].label}</b> : this.state.options[i].label} />
					);			
			}
			else if(this.state.optionsType === 'multi') {
				listOfOptions.push(
					<FormControlLabel
					key={this.state.options[i].value}
					control={<Checkbox checked={(this.state.selectedOption.includes(this.state.options[i].value))}
					onChange={this.handleChange}
					color={colorRadio}
					value={this.state.options[i].value} /> }
					label={this.state.options[i].label} />
					);
			}
		}
		if(this.state.optionsType === 'single') {
			listOfOptions = <RadioGroup aria-label="gender" name="gender1" value={this.state.selectedOption.length > 0 && this.state.selectedOption[0]} onChange={this.handleChange} >{listOfOptions}</RadioGroup>;
		}
		else if(this.state.optionsType === 'multi') {
			listOfOptions = <FormGroup>{listOfOptions}</FormGroup>	
		}
		else if(this.state.optionsType === 'text') {
			listOfOptions.push(<TextField
				id="multiline-flexible"
				label="Type your answer here"
				multiline={true}
				rowsMax="4"
				value={this.state.selectedOption}
				onChange={this.handleChange}
				margin="normal"
				fullWidth
				/>);
		}
		return (
			<div>
			{listOfOptions}
			</div>
			);
	}
}

export default Options;