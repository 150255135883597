import * as constants from "./config/constants";
import React, { Component } from "react";
import { Form } from "react-bootstrap";
import axios from "axios";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import IconButton from "@material-ui/core/IconButton";
import ArrowBack from "@material-ui/icons/ArrowBack";
import CustomSnackBar from "./ui_components/customSnackBar";
import UserProfile from "./Models/UserProfile";
import Snackbar from "@material-ui/core/Snackbar";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import ReactGA from "react-ga";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        id: null,
        email: "",
        mobile: null,
        name: "",
        password: "",
        gender: null,
        age: null,
        country: null,
        state: null,
        language: null,
        showPassword: false,
        confirmPassword: "",
        newPassword: "",
        code: "",
        showConfirmPassword: false,
        showNewPassword: false,
        resendCodeMessage: "",
        haveOtpMessage: "",
      },
      haveOtp: false,
      forgotFormData: {
        email: "",
      },
      haveOtpDialog: false,
      changeDialog: false,
      enterCodeDialog: false,
      isLogin: false,
      isLoading: false,
      snackBar: false,
      snackBarMessage: "",
      loadState: false,
      forgotPasswordDialog: false,
    };
    this.forgotPasswordDialogToggle =
      this.forgotPasswordDialogToggle.bind(this);
    this.closeSnackBar = this.closeSnackBar.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
    this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleForgotInputChange = this.handleForgotInputChange.bind(this);
    this.handleSnackBarClose = this.handleSnackBarClose.bind(this);
    this.forgotPassword = this.forgotPassword.bind(this);
    this.enterCodeDialogToggle = this.enterCodeDialogToggle.bind(this);
    this.handleCodeInputChange = this.handleCodeInputChange.bind(this);
    this.openChangePasswordDialog = this.openChangePasswordDialog.bind(this);
    this.closeChangePasswordDialog = this.closeChangePasswordDialog.bind(this);
    this.handleResetPassword = this.handleResetPassword.bind(this);
    this.handleClickshowNewPassword =
      this.handleClickshowNewPassword.bind(this);
    this.handleClickshowConfirmPassword =
      this.handleClickshowConfirmPassword.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.sendEmailandOTP = this.sendEmailandOTP.bind(this);
    this.handlehaveOtpDialog = this.handlehaveOtpDialog.bind(this);
  }
  sendEmailandOTP() {
    let copiedStates = Object.assign({}, this.state);
    let that = this;
    let request = {
      email: this.state.formData.email,
      code: this.state.formData.code,
    };
    axios
      .post(
        constants.API_BASE_URL + "/users/resetPasswordCodeValidate",
        request
      )
      .then(function (response) {
        if (response.data.code === 200) {
          copiedStates.haveOtpMessage = "";
          copiedStates.haveOtpDialog = false;
          copiedStates.changeDialog = true;
          copiedStates.enterCodeDialog = false;
        } else if (response.data.code === 504) {
          copiedStates.haveOtpMessage =
            "Invalid code. Please check your code & email again";
        } else {
          copiedStates.haveOtpMessage = "Error";
        }
        ReactGA.event({
          category: "Login",
          action: "Reset code validated",
        });
        that.setState(copiedStates);
      })
      .catch(function (error) {
        that.setState(copiedStates);
      });
  }
  handleClickshowNewPassword = () => {
    this.setState({ showNewPassword: !this.state.showNewPassword });
  };
  handleClickshowConfirmPassword = () => {
    this.setState({ showConfirmPassword: !this.state.showConfirmPassword });
  };
  handleBack = (step) => {
    let copiedStates = Object.assign({}, this.state);
    copiedStates.forgotPasswordError = "";
    copiedStates.resendCodeMessage = "";
    copiedStates.formData.code = "";
    copiedStates.haveOtpMessage = "";
    copiedStates.showConfirmPassword = false;
    copiedStates.showNewPassword = false;
    copiedStates.confirmPassword = false;
    copiedStates.newPassword = false;

    copiedStates.forgotFormData.email = "";
    if (step === 1) {
      if (copiedStates.haveOtpDialog === true) {
        copiedStates.forgotPasswordDialog = true;
        copiedStates.haveOtpDialog = false;
        copiedStates.changeDialog = false;
      } else if (copiedStates.enterCodeDialog === true) {
        copiedStates.forgotPasswordDialog = true;
        copiedStates.enterCodeDialog = false;
        copiedStates.changeDialog = false;
      }
    } else if (step === 2) {
      copiedStates.forgotPasswordDialog = false;
      copiedStates.enterCodeDialog = true;
      copiedStates.changeDialog = false;
    } else if (step === 3) {
      copiedStates.forgotPasswordDialog = false;
      copiedStates.enterCodeDialog = false;
      copiedStates.changeDialog = true;
    }
    this.setState(copiedStates);
  };
  forgotPasswordDialogToggle() {
    let copiedStates = Object.assign({}, this.state);
    copiedStates.forgotPasswordDialog = !copiedStates.forgotPasswordDialog;
    copiedStates.forgotPasswordError = "";
    this.setState(copiedStates);
  }
  closeSnackBar() {
    this.setState({ snackBar: false });
  }
  handleSnackBarClose() {
    this.setState({ snackBar: !this.state.snackBar });
  }
  handleLogin(event) {
    event.preventDefault();
    let response = {
      email: this.state.formData.email,
      password: this.state.formData.password,
    };
    let copiedStates = Object.assign({}, this.state);
    copiedStates.loadState = true;
    let that = this;
    axios
      .post(constants.API_BASE_URL + "/users/login", response)
      .then(function (response) {
        if (response.status === 200) {
          if (response.data.code === 200) {
            copiedStates.snackBarMessage = response.data.message;
            response.data.details.isLoggedIn = true;
            ReactGA.event({
              category: "Login",
              action: "Success",
            });
            response.data.details.isLoggedIn = true;
            UserProfile.setUserProfile(response.data.details);

            let url_string = window.location.href;
            let url = new URL(url_string);
            let redirectedURL = url.searchParams.get("redirect");
            window.location.href = redirectedURL;
          } else {
            if (response.data.message) {
              copiedStates.snackBarMessage = response.data.message;
            } else {
              copiedStates.snackBarMessage = "Login failed";
            }
            ReactGA.event({
              category: "Login",
              action: "Error: Invalid user / password",
            });
            UserProfile.setUserProfile(null);
          }
        } else if (response.status === 409) {
          copiedStates.snackBarMessage =
            "This email is already registered with us. Please login";
          ReactGA.event({
            category: "Login",
            action: "Error: Registered email",
          });
        }
        copiedStates.loadState = false;
        copiedStates.snackBar = true;
        that.setState(copiedStates);
      })
      .catch(function (error) {
        copiedStates.snackBar = true;
        copiedStates.snackBarMessage = "Error";
        ReactGA.event({
          category: "Login",
          action: "Error: Network error",
        });
        that.setState(copiedStates);
      });
    this.setState(copiedStates);
  }
  handleInputChange(e) {
    let copiedStates = Object.assign({}, this.state);
    copiedStates.forgotPasswordError = "";
    copiedStates.formData[e.target.name] = e.target.value;
    this.setState(copiedStates);
  }
  handleForgotInputChange(e) {
    let copiedStates = Object.assign({}, this.state);
    copiedStates.forgotPasswordError = "";
    copiedStates.forgotFormData[e.target.name] = e.target.value;
    this.setState(copiedStates);
  }
  handleCodeInputChange(e) {
    let copiedStates = Object.assign({}, this.state);
    copiedStates.resendCodeMessage = "";
    copiedStates.haveOtpMessage = "";
    copiedStates.formData[e.target.name] = e.target.value;
    this.setState(copiedStates);
  }
  forgotPassword = (resend = false) => {
    let copiedStates = Object.assign({}, this.state);
    copiedStates.forgotLoadState = true;
    copiedStates.haveOtp = false;
    let that = this;
    let request = {
      email: this.state.forgotFormData.email,
    };
    axios
      .post(constants.API_BASE_URL + "/users/resetPassword", request)
      .then(function (response) {
        if (response.data.details) {
          copiedStates.forgotLoadState = false;
          copiedStates.forgotPasswordDialog = false;
          if (resend === true)
            copiedStates.resendCodeMessage =
              "Please check " +
              that.state.forgotFormData.email +
              " for reset password code ";
          copiedStates.enterCodeDialog = true;
        } else {
          copiedStates.forgotLoadState = false;
          copiedStates.forgotPasswordError = "Invalid email";
        }
        ReactGA.event({
          category: "Login",
          action: "Requested reset code",
        });
        that.setState(copiedStates);
      })
      .catch(function (error) {});
    this.setState(copiedStates);
  };
  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };
  enterCodeDialogToggle = () => {
    this.setState({
      enterCodeDialog: !this.state.enterCodeDialog,
    });
  };
  openChangePasswordDialog() {
    let copiedStates = Object.assign({}, this.state);
    copiedStates.haveOtp = false;
    copiedStates.enterCodeDialog = false;
    copiedStates.changeDialog = true;
    this.setState(copiedStates);
  }
  handlehaveOtpDialog() {
    let copiedStates = Object.assign({}, this.state);
    copiedStates.haveOtp = true;
    copiedStates.enterCodeDialog = true;
    this.setState(copiedStates);
  }
  closeChangePasswordDialog() {
    let copiedStates = Object.assign({}, this.state);
    copiedStates.formData.newPassword = "";
    copiedStates.formData.confirmPassword = "";
    copiedStates.forgotPasswordDialog = false;
    copiedStates.changeDialog = false;
    this.setState(copiedStates);
  }
  handleResetPassword() {
    let copiedStates = Object.assign({}, this.state);
    var that = this;
    let request = {
      confirmPassword: that.state.formData.confirmPassword,
      newPassword: that.state.formData.newPassword,
      code: that.state.formData.code,
      email: that.state.formData.email,
    };
    if (
      that.state.formData.newPassword === that.state.formData.confirmPassword
    ) {
      axios
        .post(constants.API_BASE_URL + "/users/resetPasswordValidate", request)
        .then(function (response) {
          if (response.data.details) {
            response.data.details.isLoggedIn = true;
            UserProfile.setUserProfile(response.data.details);
            let url_string = window.location.href;
            let url = new URL(url_string);
            let redirectedURL = url.searchParams.get("redirect");
            window.location.href = redirectedURL;
          } else {
            copiedStates.forgotPasswordError =
              response.data.message === "Some error occurred while finding"
                ? "Invalid code"
                : response.data.message;
            UserProfile.setUserProfile(null);
          }
          ReactGA.event({
            category: "Login",
            action: "Resetting password",
          });
          copiedStates.loadState = false;
          that.setState(copiedStates);
        })
        .catch(function (error) {});
    } else {
      copiedStates.forgotPasswordError =
        "New password and Confirm password should be same";
      that.setState(copiedStates);
    }
  }
  render() {
    let emailDisplay = this.state.haveOtp ? "block" : "none";
    return (
      <div>
        <Dialog
          disableBackdropClick={true}
          open={this.state.forgotPasswordDialog}
          onClose={this.forgotPasswordDialogToggle}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Forgot Password</DialogTitle>
          <DialogContent>
            <p>Please enter your email address to reset your password</p>
            <TextField
              style={{
                marginTop: 16,
              }}
              id="email"
              variant="outlined"
              label="Email"
              placeholder="example@example.com"
              name="email"
              onChange={this.handleForgotInputChange}
              onKeyUp={this.handleForgotInputChange}
              fullWidth
            />
            <br />
            <br />
            <Typography
              style={{
                color: "red",
                fontSize: "0.875rem",
              }}
            >
              {this.state.forgotPasswordError}
            </Typography>
            <br />
            <br />
            <div></div>
          </DialogContent>
          <DialogActions>
            <div
              style={{
                width: "100%",
              }}
            >
              <span
                style={{
                  fontSize: "0.875rem",
                  padding: 15,
                }}
              >
                <a href="#openOTP" onClick={this.handlehaveOtpDialog}>
                  I have Aptimthra OTP
                </a>
              </span>
              <Button
                style={{
                  float: "right",
                }}
                variant="contained"
                size="small"
                color="primary"
                onClick={this.forgotPassword}
                disabled={
                  this.state.forgotFormData.email.length === 0 ||
                  this.state.forgotLoadState
                }
              >
                {this.state.forgotLoadState ? "Sending..." : "Send code"}
              </Button>
              <Button
                style={{
                  float: "right",
                  marginLeft: 8,
                  marginRight: 8,
                }}
                onClick={this.forgotPasswordDialogToggle}
              >
                Close
              </Button>
            </div>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.enterCodeDialog}
          disableBackdropClick={true}
          onClose={this.enterCodeDialogToggle}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            <IconButton
              style={{
                marginLeft: -15,
              }}
              onClick={() => this.handleBack(1)}
            >
              <ArrowBack />
            </IconButton>
            Enter code
          </DialogTitle>
          <DialogContent>
            <p>
              Please check your email for reset password code. Your password
              reset code will be 6 characters long.{" "}
            </p>
            <TextField
              style={{
                marginTop: 16,
              }}
              id="email"
              variant="outlined"
              label="Email"
              placeholder="Enter email"
              name="email"
              onChange={this.handleForgotInputChange}
              onKeyUp={this.handleCodeInputChange}
              style={{
                display: emailDisplay,
              }}
              fullWidth
            />
            <TextField
              style={{
                marginTop: 16,
              }}
              id="code"
              variant="outlined"
              label="Code"
              placeholder="Enter code"
              name="code"
              onChange={this.handleCodeInputChange}
              onKeyUp={this.handleCodeInputChange}
              fullWidth
            />
            <div
              style={{
                textAlign: "left",
                paddingTop: 15,
                paddingBottom: 15,
              }}
            >
              <span
                style={{
                  width: "100%",
                  color: "red",
                  textAlign: "center",
                }}
              >
                {this.state.haveOtpMessage
                  ? " " + this.state.haveOtpMessage
                  : ""}
              </span>
              <span
                style={{
                  width: "100%",
                  color: "green",
                  textAlign: "center",
                }}
              >
                {this.state.resendCodeMessage
                  ? " " + this.state.resendCodeMessage
                  : ""}
              </span>
            </div>
          </DialogContent>
          <DialogActions>
            <span
              style={{
                width: "100%",
                color: "green",
                marginLeft: 15,
                fontSize: "0.875rem",
                textAlign: "left",
              }}
            >
              <a
                href="#resendCode"
                onClick={() => {
                  this.forgotPassword(true);
                }}
              >
                Resend Code?
              </a>
            </span>
            <Button
              style={{
                float: "left",
              }}
              onClick={this.enterCodeDialogToggle}
            >
              Close
            </Button>
            <Button
              style={{
                float: "right",
              }}
              variant="contained"
              color="primary"
              onClick={this.sendEmailandOTP}
              disabled={this.state.formData.code.length !== 6}
            >
              Continue
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.changeDialog}
          disableBackdropClick={true}
          onClose={this.closeChangePasswordDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            <IconButton
              style={{
                marginLeft: -15,
              }}
              onClick={() => this.handleBack(2)}
            >
              <ArrowBack />
            </IconButton>
            Change Password
          </DialogTitle>
          <DialogContent>
            <FormControl
              fullWidth
              style={{
                marginTop: 16,
              }}
              variant="outlined"
            >
              <InputLabel htmlFor="adornment-password">New Password</InputLabel>
              <OutlinedInput
                id="adornment-password"
                variant="outlined"
                fullWidth
                type={this.state.showNewPassword ? "text" : "password"}
                onChange={this.handleInputChange}
                onKeyUp={this.handleInputChange}
                name="newPassword"
                labelWidth={110}
                value={this.state.formData.newPassword}
                label="Old Password"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Toggle password visibility"
                      onClick={this.handleClickshowNewPassword}
                      onMouseDown={this.handleMouseDownPassword}
                    >
                      {this.state.showNewPassword ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>

            <FormControl
              fullWidth
              style={{
                marginTop: 16,
              }}
              variant="outlined"
            >
              <InputLabel htmlFor="adornment-password">
                Confirm Password
              </InputLabel>
              <OutlinedInput
                id="password"
                fullWidth
                variant="outlined"
                type={this.state.showConfirmPassword ? "text" : "password"}
                onChange={this.handleInputChange}
                onKeyUp={this.handleInputChange}
                name="confirmPassword"
                value={this.state.formData.confirmPassword}
                label="Confirm Password"
                labelWidth={150}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Toggle password visibility"
                      onClick={this.handleClickshowConfirmPassword}
                      onMouseDown={this.handleMouseDownPassword}
                    >
                      {this.state.showConfirmPassword ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            <br />
            <br />
            <p
              style={{
                color: "red",
                width: "100%",
                textAlign: "center",
              }}
            >
              {this.state.forgotPasswordError}
            </p>
          </DialogContent>
          <DialogActions>
            <Button
              style={{
                float: "left",
              }}
              onClick={this.closeChangePasswordDialog}
            >
              Close
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={this.handleResetPassword}
              disabled={
                this.state.formData.newPassword.length === 0 ||
                this.state.formData.confirmPassword.length === 0
              }
            >
              Update
            </Button>
          </DialogActions>
          <DialogActions></DialogActions>
        </Dialog>

        <Dialog
          disableBackdropClick={true}
          open={this.state.haveOtpDialog}
          onClose={this.haveOtpClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            <IconButton
              style={{
                marginLeft: -15,
              }}
              onClick={() => this.handleBack(1)}
            >
              <ArrowBack />
            </IconButton>
            Enter email and code
          </DialogTitle>
          <DialogContent>
            <p>
              Please enter your email address and password reset code that you
              have already.{" "}
            </p>
            <TextField
              label="Email"
              placeholder="email"
              variant="outlined"
              name="email"
              onChange={this.handleCodeInputChange}
              onKeyUp={this.handleCodeInputChange}
              fullWidth
            />
            <TextField
              label="Code"
              variant="outlined"
              placeholder="enter code"
              name="code"
              onChange={this.handleCodeInputChange}
              onKeyUp={this.handleCodeInputChange}
              fullWidth
            />
            <div
              style={{
                textAlign: "left",
                paddingTop: 15,
                paddingBottom: 15,
              }}
            >
              <span
                style={{
                  width: "100%",
                  color: "red",
                  textAlign: "center",
                }}
              >
                {this.state.haveOtpMessage
                  ? " " + this.state.haveOtpMessage
                  : ""}
              </span>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              style={{
                float: "left",
              }}
              onClick={this.haveOtpClose}
            >
              Close
            </Button>
            <Button
              style={{
                float: "right",
              }}
              variant="contained"
              color="primary"
              onClick={this.sendEmailandOTP}
              disabled={
                this.state.formData.email.length === 0 ||
                this.state.formData.code.length !== 6
              }
            >
              Continue
            </Button>
          </DialogActions>
        </Dialog>

        <Form onSubmit={this.handleLogin}>
          <TextField
            style={{
              marginTop: 16,
            }}
            autoComplete="username"
            id="email"
            variant="outlined"
            label="Email"
            placeholder="example@example.com"
            name="email"
            type="email"
            onChange={this.handleInputChange}
            onKeyUp={this.handleInputChange}
            fullWidth
          />

          <FormControl
            fullWidth
            variant="outlined"
            style={{
              marginTop: 16,
            }}
          >
            <InputLabel htmlFor="password">Password</InputLabel>
            <OutlinedInput
              id="password"
              fullWidth
              type={this.state.showPassword ? "text" : "password"}
              autoComplete="password"
              onChange={this.handleInputChange}
              onKeyUp={this.handleInputChange}
              name="password"
              label="Password"
              labelWidth={75}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Toggle password visibility"
                    onClick={this.handleClickShowPassword}
                    onMouseDown={this.handleMouseDownPassword}
                  >
                    {this.state.showPassword ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <br />
          <br />
          <div
            style={{
              textAlign: "right",
              paddingTop: 15,
              paddingBottom: 15,
            }}
          >
            <a href="#forgotPassword" onClick={this.forgotPasswordDialogToggle}>
              Trouble logging in?
            </a>
          </div>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            type="submit"
            disabled={this.state.loadState}
          >
            {this.state.loadState ? "Logging in..." : "Login"}
          </Button>
          {this.state.snackBar ? (
            <CustomSnackBar message={this.state.snackBarMessage} />
          ) : (
            ""
          )}
        </Form>
        <Snackbar
          style={{
            zIndex: 6000,
          }}
          id="snackbar-profile"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          onClose={this.closeSnackBar}
          open={this.state.snackBar}
          message={<span id="message-id">{this.state.snackBarMessage}</span>}
        />
      </div>
    );
  }
}

export default Login;
