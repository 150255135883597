import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col } from "reactstrap";
import * as constants from "../config/constants";
import AttemptWiseReport from "./attemptWiseReport";
import SingleAttemptData from "./singleAttemptData";
import Card from "@material-ui/core/Card";
import moment from "moment";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import RemoveRedEyeIcon from "@material-ui/icons/RemoveRedEye";
import Button from "@material-ui/core/Button";
import CardMedia from "@material-ui/core/CardMedia";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Chip from "@material-ui/core/Chip";
import Typography from "@material-ui/core/Typography";
import ModeCommentIcon from "@material-ui/icons/ModeComment";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import axios from "axios";
import UserProfile from "../Models/UserProfile";
import ReactGA from "react-ga";
import { withStyles } from "@material-ui/core/styles";
import ReactStoreIndicator from "react-score-indicator";
import { Chart } from "react-google-charts";

const styles = (theme) => ({
  cardTitle: {
    fontSize: 16,
    fontWeight: 700,
    textTransform: "uppercase",
  },
  chipLabel: {
    fontSize: 12,
  },
  paper: {
    borderRadius: 0,
    border: "1px solid #ccc",
    boxShadow: "none",
  },
  iconRoot: {
    minWidth: 32,
  },
});

let getBackground = (category) => {
  let colors = {
    questions: "#c8e6c9",
    correct: "#e1bee7",
    time: "#009688",
  };
  return colors[category];
};

let getBorder = (category) => {
  let colors = {
    questions: "#43a047",
    correct: "#9c27b0",
    time: "#009688",
  };
  return "1px dashed " + colors[category];
};

function secondsToString(seconds) {
  let numyears = Math.floor(seconds / 31536000);
  let numdays = Math.floor((seconds % 31536000) / 86400);
  let numhours = Math.floor(((seconds % 31536000) % 86400) / 3600);
  let numminutes = Math.floor((((seconds % 31536000) % 86400) % 3600) / 60);
  let numseconds = (((seconds % 31536000) % 86400) % 3600) % 60;

  let output = "";
  if (numyears > 0) {
    output += numyears + " yrs ";
  }
  if (numyears === 0 && numdays > 0) {
    output += numdays + " days ";
  }
  if (numdays === 0 && numhours > 0) {
    if (numhours === 1) output += numhours + " hr ";
    else output += numhours + " hrs ";
  }
  if (numhours === 0 && numminutes > 0) {
    if (numminutes === 1) output += numminutes + " min ";
    else output += numminutes + " mins ";
  }
  if (numminutes === 0 && numseconds > 0) {
    if (numseconds === 1) output += numseconds + " sec ";
    else output += numseconds + " secs ";
  }
  return output;
}

class AttemptData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: window.innerWidth <= 500,
      userId: UserProfile.getUserProfile().id,
      attemptData: this.props.attemptData,
      quizData: this.props.attemptData.quizData,
      smallItem: false,
      AttemptCode: false,
      limit: 12,
    };

    this.increaseLimit = this.increaseLimit.bind(this);
    ReactGA.initialize("UA-125256246-1", {
      gaOptions: {
        userId: UserProfile.getUserProfile().email,
      },
    });
    ReactGA.pageview("/reports");
  }
  increaseLimit = () => {
    this.setState({
      limit: this.state.limit + 12,
    });
  };
  componentWillReceiveProps(nextProps) {
    this.setState({
      attemptData: nextProps.attemptData,
    });
  }
  render() {
    var maxWidth = "100%";
    const { classes } = this.props;
    var tablesToDisplay = [];
    var value = this.state.tabValue;
    const isMobile = window.innerWidth <= 500;
    let that = this;

    let positionOptions = {
      marginTop: 32,
      marginRight: 150,
      marginLeft: 150,
      marginBottom: 32,
    };

    let cardWidth = 380;

    let { smallItem } = this.state;
    if (isMobile) {
      positionOptions = {
        marginTop: 32,
        marginRight: 0,
        marginLeft: 0,
        marginBottom: 32,
      };
      cardWidth = 320;
    }

    let timeChartData = [];

    Object.keys(this.state.attemptData).map((a) => {
      let mathPercent = Math.round(
        (that.state.attemptData[a].correct /
          that.state.attemptData[a].attempts) *
          100
      );
      let clr =
        mathPercent > 75
          ? "url(FlatIcons/circle_0.png)"
          : mathPercent > 50
          ? "url(FlatIcons/circle_1.png)"
          : "url(FlatIcons/circle_2.png)";

      let chartData = [];
      if (typeof this.state.attemptData[a] !== "undefined") {
        timeChartData.push([
          moment(that.state.attemptData[a].date).format("MMM DD, YYYY"),
          Math.round(
            (that.state.attemptData[a].correct /
              this.state.attemptData[a].quizData.listOfQuestions.length) *
              100
          ),
          Math.round(
            (that.state.attemptData[a].categoryData.SoftSkills.correct /
              that.state.attemptData[a].categoryData.SoftSkills.attempts) *
              100
          ),
          Math.round(
            (that.state.attemptData[a].categoryData.Coding.correct /
              that.state.attemptData[a].categoryData.Coding.attempts) *
              100
          ),
          Math.round(
            (that.state.attemptData[a].categoryData.Quants.correct /
              that.state.attemptData[a].categoryData.Quants.attempts) *
              100
          ),
          Math.round(
            (that.state.attemptData[a].categoryData.Verbal.correct /
              that.state.attemptData[a].categoryData.Verbal.attempts) *
              100
          ),
          Math.round(
            (that.state.attemptData[a].categoryData.Reasoning.correct /
              that.state.attemptData[a].categoryData.Reasoning.attempts) *
              100
          ),
          Math.round(
            (that.state.attemptData[a].categoryData.Programming.correct /
              that.state.attemptData[a].categoryData.Programming.attempts) *
              100
          ),
        ]);
      }
    });

    timeChartData = timeChartData.reverse();
    timeChartData.unshift([
      "Date",
      "Overall",
      "Quants",
      "Verbal",
      "Reasoning",
      "Programming",
      "Coding",
      "SoftSkills",
    ]);

    return (
      <div style={positionOptions}>
        <Grid
          container
          justify="center"
          style={{
            marginBottom: 32,
          }}
        >
          <Grid
            md={10}
            sm={12}
            xs={12}
            lg={10}
            xl={10}
            style={{
              border: "1px solid #ccc",
            }}
          >
            <Chart
              chartType="LineChart"
              data={timeChartData}
              width="100%"
              height="550px"
              legendToggle={true}
              options={{
                title: "Your performance",
                curveType: "function",
                hAxis: {
                  title: "Date",
                },
                vAxis: {
                  title: "Percentage",
                },
              }}
            />
          </Grid>
        </Grid>

        <Grid container justify="center">
          {this.state.AttemptCode && (
            <AttemptWiseReport
              attemptCode={this.state.AttemptCode}
              showResults={true}
              closeCB={() => this.closeAttemptWiseReport()}
            />
          )}
          {Object.keys(this.state.attemptData).map((a, index) => {
            if (index < this.state.limit) {
              console.log(
                "that.state.attemptData[a].totalMarks",
                that.state.attemptData[a]
              );

              let mathPercent = Math.round(
                (that.state.attemptData[a].markEarned /
                  that.state.attemptData[a].totalMarks) *
                  100
              );
              let clr =
                mathPercent > 75
                  ? "url(FlatIcons/circle_0.png)"
                  : mathPercent > 50
                  ? "url(FlatIcons/circle_1.png)"
                  : "url(FlatIcons/circle_2.png)";

              let AttemptCode = a;

              return (
                <SingleAttemptData
                  quizData={this.state.attemptData[a].quizData}
                  attemptData={this.state.attemptData}
                  timeSpent={this.state.attemptData[a].timeSpent}
                  date={this.state.attemptData[a].date}
                  quizTitle={this.state.attemptData[a].quizTitle}
                  attempts={this.state.attemptData[a].attempts}
                  AttemptCode={AttemptCode}
                />
              );
            }
          })}
          {Object.keys(this.state.attemptData).length - this.state.limit >
            0 && (
            <Grid container alignItems="center" justify="center">
              <Grid item xs={12} md={12} lg={12} xl={12}>
                <Button
                  color="secondary"
                  style={{
                    textAlign: "center",
                  }}
                  variant="outlined"
                  onClick={(event) => this.increaseLimit()}
                >
                  view more
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
      </div>
    );
  }
}
AttemptData.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(AttemptData);
