import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import UserProfile from '../Models/UserProfile';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import MobileStepper from '@material-ui/core/MobileStepper';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import {Row, Col} from 'reactstrap';
import ReactHtmlParser from 'react-html-parser';
import ReactGA from 'react-ga';

const styles = theme => ({
	root: {
		flexGrow: 1,
	},
	header: {
		display: 'flex',
		alignItems: 'center',
		height: 50,
		paddingLeft: theme.spacing.unit * 4,
		marginBottom: 20,
		backgroundColor: theme.palette.background.default,
	},
	img: {
		overflow: 'hidden',
		width: '100%',
		padding: 10
	},
});

const tutorialSteps = [{
	label: '1. Click on settings',
	imgPath: 'logo.png',
	instructions: '<p class="instructions">Welcome to <b>Aptimithra</b> App. Your friend for cracking tests</p>',
}, {
	label: '1. Click on settings',
	imgPath: 'General/em-step-01.png',
	instructions: '<p class="instructions">Click on the <b>Settings</b> icon of google chrome</p>',
}, {
	label: '2. Add to home screen',
	imgPath: 'General/em-step-02.png',
	instructions: '<p class="instructions">Select <b>Add to Home screen</b></p>',
}, {
	label: '2. Add to home screen',
	imgPath: 'General/em-step-03.png',
	instructions: '<p class="instructions">Enjoy <b>Aptimithra</b> by clicking on the app from your Home screen</p>',
}];

const tutorialStepsDesktop = [{
	label: '1. Click on settings',
	imgPath: 'logo.png',
	instructions: '<p class="instructions">Welcome to <b>Aptimithra</b> App. Your friend for cracking tests</p>',
}, {
	label: '1. Click on settings',
	imgPath: 'General/ed-step-01.png',
	instructions: '<p class="instructions">Click on the <b>Settings</b> icon of google chrome</p>',
}, {
	label: '2. Add to home screen',
	imgPath: 'General/ed-step-02.png',
	instructions: '<p class="instructions">Select <b>Install Aptimithra from Ethnus</b></p>',
}, {
	label: '2. Add to home screen',
	imgPath: 'General/ed-step-03.png',
	instructions: '<p class="instructions">Enjoy <b>Aptimithra</b> by clicking on the app from your Home screen</p>',
}];

class WelcomeUser extends Component {
	constructor(props) {
		super();
		ReactGA.initialize('UA-125256246-1');
		ReactGA.modalview("WelcomeUser");
		this.state = {
			openSteps: true,
			activeStep: 0,
			isMobile: window.innerWidth <= 500,
		}
		this.openStepsToggle = this.openStepsToggle.bind(this);
	}
	openStepsToggle = ()  => {
		this.setState({
			openStepsToggle: !this.state.openSteps
		})
	}
	handleNext = () => {
		ReactGA.event({
			category: 'WelcomeUser',
			action: 'Clicked Next'
		});
		this.setState(prevState => ({
			activeStep: prevState.activeStep + 1,
		}));
	};
	handleStepChange = activeStep => {
		this.setState({ activeStep });
	};
	handleSkip = () => {
		if(this.state.activeStep === tutorialSteps.length - 1) {
			ReactGA.event({
				category: 'WelcomeUser',
				action: 'Clicked Finish'
			});
		} else {
			ReactGA.event({
				category: 'WelcomeUser',
				action: 'Clicked Skip'
			});
		}
		this.setState({
			openSteps: false
		});
	};
	render() {

		const { classes, theme } = this.props;
		const { activeStep, isMobile } = this.state;
		const maxSteps = tutorialSteps.length;

		if(!UserProfile.getUserProfile()) {
			if(isMobile) {
				return (
					<Dialog
					fullScreen
					open={this.state.openSteps}
					onClose={this.openStepsToggle}
					>
					<DialogContent style={{
						padding: 0
					}}>
					<SwipeableViews
					axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
					index={this.state.activeStep}
					onChangeIndex={this.handleStepChange}
					enableMouseEvents
					>
					{
						tutorialSteps.map((step, index) => {
							let even = index % 2;
							let firstColSize = even === 0 ? 4 : 8;
							let secondColSize = even === 0 ? 8 : 4;
							return (
								<Row style={{
									marginLeft: 0,
									marginRight: 0,
								}}>
								<Col className="v-center center" sm={{
									size: secondColSize
								}} style={{
									padding: 20,
									paddingBottom: 0
								}}>
								{ReactHtmlParser(step.instructions)}
								</Col>
								<Col className="v-center center" sm={{
									size: firstColSize
								}} style={{
									padding: 15
								}} >
								<img key={step.label} className={classes.img} src={step.imgPath} alt={step.label} />
								</Col>
								</Row>
								);
						})
					}
					</SwipeableViews>
					</DialogContent>
					<DialogActions classes={{
						root: 'welcomeUserActionRoot'
					}}>
					<MobileStepper
					steps={maxSteps}
					position="static"
					activeStep={activeStep}
					className={classes.mobileStepper}
					nextButton={
						<Button size="small" onClick={() => {activeStep === maxSteps - 1 ? this.handleSkip() : this.handleNext()}}>
						{activeStep === maxSteps - 1 ? "Finish" : "Next"}
						{activeStep !== maxSteps - 1 ? (theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />) : ""}
						</Button>
					}
					backButton={
						<Button size="small" onClick={this.handleSkip}>
						Skip
						</Button>
					}
					/>
					</DialogActions>
					</Dialog>
					);
			} else {
				return (
					<Dialog
					maxWidth={'md'}
					fullWidth
					open={this.state.openSteps}
					onClose={this.openStepsToggle}
					>
					<DialogContent style={{
						padding: 0
					}}>
					<SwipeableViews
					axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
					index={this.state.activeStep}
					onChangeIndex={this.handleStepChange}
					enableMouseEvents
					>
					{
						tutorialStepsDesktop.map((step, index) => {
							let width = index === 0 ? "400px" : "100%";
							return (
								<div key={index}>
								<Row style={{
									marginLeft: 0,
									marginRight: 0,
								}}>
								<Col className="v-center center" sm={{
									size: 12
								}} style={{
									padding: 15,
									paddingBottom: 0
								}} >
								{ReactHtmlParser(step.instructions)}
								</Col>
								</Row>
								<Row style={{
									marginLeft: 0,
									marginRight: 0,
								}}>
								<Col className="v-center center" sm={{
									size: 12
								}} style={{
									padding: 15,
									textAlign: 'center'
								}} >
								<img align="center" key={step.label} className={classes.img} style={{
									width: width,
									margin: '0 auto'
								}} src={step.imgPath} alt={step.label} />
								</Col>
								</Row>
								</div>
								);
						})
					}
					</SwipeableViews>
					</DialogContent>
					<DialogActions classes={{
						root: 'welcomeUserActionRoot'
					}}>
					<MobileStepper
					steps={maxSteps}
					position="static"
					activeStep={activeStep}
					className={classes.mobileStepper}
					nextButton={
						<Button size="small" onClick={() => {activeStep === maxSteps - 1 ? this.handleSkip() : this.handleNext()}}>
						{activeStep === maxSteps - 1 ? "Finish" : "Next"}
						{activeStep !== maxSteps - 1 ? (theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />) : ""}
						</Button>
					}
					backButton={
						<Button size="small" onClick={this.handleSkip}>
						Skip
						</Button>
					}
					/>
					</DialogActions>
					</Dialog>
					);
			}
		}
		return (<div></div>)
	}
}

WelcomeUser.propTypes = {
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(WelcomeUser);
