import React, { Component } from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import Avatar from '@material-ui/core/Avatar';
import Stars from '@material-ui/icons/Stars';
import { withTheme } from '@material-ui/core/styles';

class TimeAnalysis extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showShareDialog: false,
			data: (this.props.data) ? this.props.data : [],
			subTopicWiseMessage: (this.props.subTopicWiseMessage) ? this.props.subTopicWiseMessage : ''
		}
	}
	componentWillReceiveProps(nextProps) {
		this.setState({
			data: nextProps.data
		});
		this.setState({
			subTopicWiseMessage: nextProps.subTopicWiseMessage
		});
	}
	render = () => {

		const {theme} = this.props;
		let data = this.state.data;
		let subTopicWiseMessage = this.state.subTopicWiseMessage;
		let timeChartData = [];
		let numberOfAttemptsChartData = [];
		let timeObject = {};

		let timeMessage = '';
		let numberOfAttemptsMessage = '';
		if(Array.isArray(data)) {
			if(data.length === 0) {
				return (
					<Typography style={{
						textAlign: 'center',
						fontSize: '18px'
					}}>
					Please take some tests and come back here. We will have awesome suggestions and recommendations for you!
					</Typography>);
			}
			let correct;
			let numberOfAttempts = {};
			let timeSpent = {};
			let timeLabels = [{
				label: "Less than 20 seconds",
				min: 0,
				max: 20,
				display: "less than 20 seconds"
			}, {
				label: "Less than 40 seconds",
				min: 20,
				max: 40,
				display: "from 20 to 40 seconds"
			}, {
				label: "Less than 60 seconds",
				min: 40,
				max: 60,
				display: "from 40 to 60 seconds"
			}, {
				label: "Less than 80 seconds",
				min: 60,
				max: 80,
				display: "from 60 to 80 seconds"
			}, {
				label: "Less than 100 seconds",
				min: 80,
				max: 100,
				display: "from 80 to 100 seconds"
			}, {
				label: "More than 100 seconds",
				min: 100,
				max: 120000,
				display: "more than 100 seconds"
			}];
			timeLabels.map(t => {
				timeObject[t.label] = {
					correct: 0,
					wrong: 0,
					attempts: 0
				}
				return 0;
			});
			data.map(attempt => {
				correct = attempt.isCorrect ? 1 : 0;
				timeSpent = Math.round(attempt.timeSpent / 1000);
				timeLabels.map(t => {
					if(timeSpent >= t.min && timeSpent < t.max ) {
						timeSpent = t.label;
					}
					return 0;
				});
				if(correct)
					timeObject[timeSpent].correct += 1;
				else 
					timeObject[timeSpent].wrong += 1;
				timeObject[timeSpent].attempts += 1;
				if(numberOfAttempts[attempt.numberOfAttempts]) {
					if(correct)
						numberOfAttempts[attempt.numberOfAttempts].correct += 1;
					else 
						numberOfAttempts[attempt.numberOfAttempts].wrong += 1;
					numberOfAttempts[attempt.numberOfAttempts].attempts += 1;
				} else {
					numberOfAttempts[attempt.numberOfAttempts] = {
						correct: correct,
						wrong: correct === 0 ? 1 : 0,
						attempts: 1
					}
				}
				return 0;
			});
			let timePercentageArr = [];
			let numberOfAttemptsPercentageArr = [];
			Object.keys(timeObject).map(time => {
				timeLabels.map(t => {
					if(t.label === time ) {
						timePercentageArr.push({
							label: t.display,
							attempts: (timeObject[time].correct / timeObject[time].attempts) * 100
						});
					}
					return 0;
				});
				timeChartData.push([
					time,
					(timeObject[time].correct / timeObject[time].attempts) * 100,
					"Attempts: " + timeObject[time].attempts + "<br />Correct Answers: " + timeObject[time].correct + "<br />Incorrect Answers: " + timeObject[time].wrong 
					]);
				return 0;
			});
			timePercentageArr.sort((a, b) => {
				return b.attempts - a.attempts
			});
			if(timePercentageArr[0]) {
				timeMessage = "Spend " + timePercentageArr[0].label.toLowerCase() + " for a question";
			}
			Object.keys(numberOfAttempts).map(nOfAttempts => {
				numberOfAttemptsPercentageArr.push({
					label: nOfAttempts,
					percentage: (numberOfAttempts[nOfAttempts].correct / numberOfAttempts[nOfAttempts].attempts) * 100
				});
				numberOfAttemptsChartData.push([
					parseInt(nOfAttempts, 10),
					(numberOfAttempts[nOfAttempts].correct / numberOfAttempts[nOfAttempts].attempts) * 100,
					"<b>Number of attempts: " + nOfAttempts + "</b><hr />Questions: " + numberOfAttempts[nOfAttempts].attempts + "<br />Correct Answers: " + numberOfAttempts[nOfAttempts].correct + "<br />Incorrect Answers: " + numberOfAttempts[nOfAttempts].wrong 
					]);
				return 0;
			});
			numberOfAttemptsPercentageArr.sort((a, b) => {
				return b.percentage - a.percentage
			});
			if(numberOfAttemptsPercentageArr[0]) {
				numberOfAttemptsMessage = "Attempt a question " + numberOfAttemptsPercentageArr[0].label.toLowerCase() + " times";
			}
		}
		return (
			<div>

			<Typography variant={"subheading"} >You are scoring well if you</Typography>
			<List style={{
				textAlign: 'center'
			}}>
			<ListItem>
			<Avatar>
			<Stars />
			</Avatar>
			<ListItemText primary={timeMessage ? timeMessage : <div style={{
				maxWidth: '300px'
			}}><LinearProgress /></div> } />
			</ListItem>
			<ListItem>
			<Avatar>
			<Stars />
			</Avatar>
			<ListItemText primary={numberOfAttemptsMessage ? numberOfAttemptsMessage : <div style={{
				maxWidth: '200px'
			}}><LinearProgress /></div> } />
			</ListItem>
			<ListItem>
			<Avatar>
			<Stars />
			</Avatar>
			<ListItemText primary={subTopicWiseMessage ? subTopicWiseMessage : <div style={{
				maxWidth: '300px'
			}}><LinearProgress /></div> } />
			</ListItem>
			</List>
			</div>
			);
	}
}

TimeAnalysis.contextTypes = {
    theme: PropTypes.object,
};

export default withTheme(TimeAnalysis);