import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Row, Col } from 'reactstrap';
import PersonaliseInfo from './personaliseInfo';
import AchievementInfo from './achievementInfo';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import RestoreIcon from '@material-ui/icons/Restore';
import SvgIcon from '@material-ui/core/SvgIcon';
import SwipeableViews from 'react-swipeable-views';

const styles = {
	bottomNavigation: {
		position: 'fixed',
		width: '100%',
		left: 0,
		bottom: 0,
		borderTop: '1px solid rgba(0, 0, 0, 0.12)'
	}
};

class Performance extends Component {
	constructor() {
		super();
		this.state = {
			isMobile: window.innerWidth <= 500,
			tab: 0
		}
		this.handleChange = this.handleChange.bind(this);
		this.handleChangeIndex = this.handleChangeIndex.bind(this);
	}
	handleChangeIndex = index => {
		this.setState({ tab: index });
	};
	handleChange = (event, value) => {
		this.setState({ tab: value });
	};
	render() {
		const { classes } = this.props;

		if(this.state.isMobile === false) {
			return (
				<Row style={{
					marginLeft: 0,
					marginRight: 0,
					width: '100%',
				}} >
				<Col md={{
					size: 4,
					offset: 2
				}}>
				<PersonaliseInfo />
				</Col>
				<Col md={4}>
				<AchievementInfo />
				</Col>
				</Row>
				);
		} else {
			return (
				<div>

				<SwipeableViews
				index={this.state.tab}
				onChangeIndex={this.handleChangeIndex}
				>
				<PersonaliseInfo />
				<AchievementInfo />
				</SwipeableViews>

				<br />
				<br />

				<BottomNavigation
				value={this.state.tab}
				onChange={this.handleChange}
				showLabels
				className={classes.bottomNavigation}
				>
				<BottomNavigationAction label="Recents" icon={<RestoreIcon />} />
				<BottomNavigationAction label="Rankings" icon={
					<SvgIcon version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 297 297" enableBackground="new 0 0 297 297">
					<g>
					<path d="M148.5,50.045c13.798,0,25.023-11.225,25.023-25.023S162.298,0,148.5,0s-25.023,11.225-25.023,25.023   S134.702,50.045,148.5,50.045z"/>
					<path d="m174.438,57.566c-0.054-0.018-6.469-1.988-6.469-1.988-1.635-0.5-3.392-0.401-4.957,0.283l-14.512,6.334-14.512-6.333c-1.564-0.683-3.321-0.782-4.957-0.283 0,0-6.415,1.97-6.469,1.988-10.143,3.379-16.958,12.835-16.958,23.527v34.941c0,3.949 3.201,7.149 7.149,7.149h71.493c3.949,0 7.149-3.201 7.149-7.149v-34.943c0.001-10.692-6.814-20.147-16.957-23.526z"/>
					<path d="m184.247,137.919h-71.493c-3.949,0-7.149,3.201-7.149,7.149v144.782c0,3.949 3.201,7.149 7.149,7.149h71.493c3.949,0 7.149-3.201 7.149-7.149v-144.782c-2.84217e-14-3.948-3.201-7.149-7.149-7.149z"/>
					<path d="m247.569,102.342c13.798,0 25.023-11.225 25.023-25.023s-11.225-25.023-25.023-25.023-25.023,11.225-25.023,25.023 11.225,25.023 25.023,25.023z"/>
					<path d="m273.509,109.861c-0.055-0.018-6.471-1.987-6.471-1.987-1.634-0.5-3.391-0.401-4.956,0.283l-14.512,6.333-14.512-6.333c-1.565-0.683-3.321-0.783-4.956-0.283 0,0-6.416,1.969-6.471,1.987-10.143,3.381-16.957,12.837-16.957,23.528v34.941c0,3.949 3.201,7.149 7.149,7.149h71.493c3.949,0 7.149-3.201 7.149-7.149v-34.941c0.001-10.691-6.814-20.146-16.956-23.528z"/>
					<path d="m283.316,190.215h-71.493c-3.949,0-7.149,3.201-7.149,7.149v92.487c0,3.949 3.201,7.149 7.149,7.149h71.493c3.949,0 7.149-3.201 7.149-7.149v-92.487c0.001-3.949-3.2-7.149-7.149-7.149z"/>
					<path d="m49.431,151.158c13.798,0 25.023-11.225 25.023-25.023 0-13.798-11.225-25.023-25.023-25.023s-25.023,11.225-25.023,25.023c3.55271e-15,13.798 11.225,25.023 25.023,25.023z"/>
					<path d="m75.369,158.678c-0.054-0.018-6.469-1.988-6.469-1.988-1.634-0.499-3.392-0.401-4.957,0.283l-14.512,6.333-14.512-6.333c-1.564-0.683-3.322-0.781-4.957-0.283 0,0-6.415,1.97-6.469,1.988-10.143,3.379-16.958,12.835-16.958,23.527v34.941c0,3.949 3.201,7.149 7.149,7.149h71.493c3.949,0 7.149-3.201 7.149-7.149v-34.941c0.001-10.693-6.815-20.148-16.957-23.527z"/>
					<path d="m85.177,239.031h-71.493c-3.949,0-7.149,3.201-7.149,7.149v43.67c0,3.949 3.201,7.149 7.149,7.149h71.493c3.949,0 7.149-3.201 7.149-7.149v-43.67c0.001-3.948-3.2-7.149-7.149-7.149z"/>
					</g>
					</SvgIcon>
				} />
				</BottomNavigation>
				</div>
				);
		}
	}
}

Performance.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Performance);