import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import { withStyles } from '@material-ui/core/styles';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import Slide from '@material-ui/core/Slide';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import UserProfile from '../Models/UserProfile';
import Avatar from '@material-ui/core/Avatar';
import moment from 'moment';
import ShareDialog from '../social/shareDialog';
import * as constants from '../config/constants';
import axios from 'axios';

const styles = theme => ({
	appBar: {
		position: 'relative',
	},
	flex: {
		flex: 1,
	},
	
});

function Transition(props) {
	return <Slide direction="up" {...props} />;
}
class InvitedFriends extends Component {
	constructor(props) {
		super(props);
		let user = UserProfile.getUserProfile();
		this.state = {
			showShareDialog : false,
			dialogOpen : this.props.dialogOpen,
			friendDialogCallback : (this.props.friendDialogCallback) ? this.props.friendDialogCallback : null,
			friends : [],
			inviteCode: (user) ? user.inviteCode : '',
			user: user,
		}
		this.handleClose = this.handleClose.bind(this);
		this.toggleShareDialogCB = this.toggleShareDialogCB.bind(this);
		this.handleInvite = this.handleInvite.bind(this);
		this.getFriendDetails = this.getFriendDetails.bind(this);
	}
	componentDidMount(){
		let that = this;
		axios.get(constants.API_BASE_URL + "/users/" + this.state.user.id + "/friendsInfo/:all")
		.then(function(response) {
			that.setState({
				friends: response.data.details.friends
			});
		})
		.catch(function(error) {

		});
	}
	getFriendDetails(friendId){
		axios.get(constants.API_BASE_URL + "/users/" + this.state.user.id + "/friendsInfo/" + friendId)
		.then(function(response) {
		})
		.catch(function(error) {
		});
	}
	toggleShareDialogCB() {
		this.setState({
			showShareDialog : false
		});
	}
	handleInvite() {
		this.setState({
			showShareDialog : true
		});
	}
	componentWillReceiveProps(nextProps){
		this.setState({
			dialogOpen: nextProps.dialogOpen
		})
	}
	handleClose() {
		this.setState({
			dialogOpen : false
		});
		(this.state.friendDialogCallback) && this.state.friendDialogCallback('User closed by pressing cancel button');
	}
	render() {
		const { classes } = this.props;
		var friendList =[];
		if(this.state.friends)
			this.state.friends.map((friend, i) => {
				friendList.push(
					<List key={i}>
					<ListItem button onClick={() => this.getFriendDetails(friend.id)} >
					<Avatar >
					{friend.name ? friend.name.substring(0,1).toUpperCase() : "U"}
					</Avatar>
					<ListItemText primary={friend.name} secondary={"Joined " + (friend.createdAt ? moment(friend.createdAt).fromNow() : "just now")} />
					</ListItem>
					</List>
					);
				return true;
			});
		if(friendList.length === 0) {
			friendList = (<Typography>No friends for you! Please invite others to join</Typography>);
		}
		return(
			<div className="containerWithMargin" >
			<ShareDialog
			showShareDialog={this.state.showShareDialog}
			shareUrl={constants.HOST_URL + "?code=" + this.state.inviteCode}
			shareMessage={"I am inviting you: " + this.state.inviteCode}
			toggleShareDialogCB = {this.toggleShareDialogCB}
			/>

			<Dialog
			fullScreen
			open={this.state.dialogOpen}
			onClose={this.handleClose}
			transition={Transition}
			>
			<AppBar className={classes.appBar}>
			<Toolbar>
			<IconButton color="inherit" onClick={this.handleClose} aria-label="Close">
			<CloseIcon />
			</IconButton>
			<Typography variant="title" color="inherit" className={classes.flex}>
			Friend List
			</Typography>
			</Toolbar>
			</AppBar>
			<Card style = {{
				width : '100%'
			}}>
			<CardContent>
			{friendList}
			</CardContent>
			</Card>
			<Button
			onClick={this.handleInvite}
			style={{
				position: 'fixed',
				bottom: '20px',
				right: '15px'
			}}
			size="medium"
			variant="fab"
			color="primary"
			>
			<GroupAddIcon />
			</Button>
			</Dialog>
			</div>
			)

	}
}
InvitedFriends.propTypes = {
	classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(InvitedFriends);