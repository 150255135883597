import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import * as constants from '../config/constants';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import {
	FacebookShareButton,
	LinkedinShareButton,
	TwitterShareButton,
	TelegramShareButton,
	WhatsappShareButton,
	RedditShareButton,
	FacebookIcon,
	TwitterIcon,
	LinkedinIcon,
	TelegramIcon,
	WhatsappIcon,
	RedditIcon,
} from 'react-share';

class ShareDialog extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showShareDialog: this.props.showShareDialog,
			shareMessage: this.props.shareMessage,
			toggleShareDialogCB: this.props.toggleShareDialogCB,
			shareUrl : (this.props.shareUrl) ? this.props.shareUrl : constants.HOST_URL
		};
		this.toggleShareDialog = this.toggleShareDialog.bind(this);
	}
	componentWillReceiveProps(nextProps) {
		let copiedStates = Object.assign({}, this.state);
		copiedStates.showShareDialog = nextProps.showShareDialog;
		copiedStates.shareMessage = nextProps.shareMessage;
		copiedStates.shareUrl = nextProps.shareUrl;
		copiedStates.toggleShareDialogCB = nextProps.toggleShareDialogCB;
		this.setState(copiedStates);
	}
	toggleShareDialog = () => {
		this.setState({
			showShareDialog: !this.state.showShareDialog
		});
		(this.state.toggleShareDialogCB) && this.state.toggleShareDialogCB();
	}
	render() {
		let shareSize = 44;
		let shareButtonStyle = {
			padding: '5px',
			display: 'inline-block',
			cursor: 'pointer'
		};

		return (
		
			<Dialog
			style={{
				textAlign: 'center'
			}}
			open={this.state.showShareDialog}
			onClose={this.toggleShareDialog}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			>
			<DialogTitle id="alert-dialog-title">SHARE VIA</DialogTitle>
			<DialogContent>
			<DialogContentText id="alert-dialog-description">

			<Typography component="div" style={shareButtonStyle}>
			<FacebookShareButton
			url={this.state.shareUrl}
			quote={this.state.shareMessage}
			hashtag={"#assessment"}
			>
			<FacebookIcon size={shareSize} round/>
			</FacebookShareButton>
			</Typography>

			<Typography component="div" style={shareButtonStyle}>
			<TwitterShareButton
			url={this.state.shareUrl}
			title={this.state.shareMessage}
			hashtags={["assessment", "skills", "eguru", "ethnus"]}
			>
			<TwitterIcon
			size={shareSize}
			round />
			</TwitterShareButton>
			</Typography>

			<Typography component="div" style={shareButtonStyle}>
			<TelegramShareButton
			url={this.state.shareUrl}
			title={this.state.shareMessage}
			>
			<TelegramIcon size={shareSize} round/>
			</TelegramShareButton>
			</Typography>

			<Typography component="div" style={shareButtonStyle}>
			<WhatsappShareButton
			url={this.state.shareMessage + " - " + this.state.shareUrl}
			quote={this.state.shareMessage}
			separator=" "
			>
			<WhatsappIcon size={shareSize} round/>
			</WhatsappShareButton>
			</Typography>

			<Typography component="div" style={shareButtonStyle}>
			<RedditShareButton
			url={this.state.shareUrl}
			quote={this.state.shareMessage}
			separator=":: "
			>
			<RedditIcon size={shareSize} round/>
			</RedditShareButton>
			</Typography>

			<Typography component="div" style={shareButtonStyle}>
			<LinkedinShareButton
			url={this.state.shareUrl}
			title={this.state.shareUrl}
			description={this.state.shareMessage}
			>
			<LinkedinIcon size={shareSize} round/>
			</LinkedinShareButton>
			</Typography>

			</DialogContentText>
			</DialogContent>
			<DialogActions>
			<Button onClick={this.toggleShareDialog}>
			Close
			</Button>
			</DialogActions>
			</Dialog>
			);
	}
}

export default ShareDialog;