import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import Tags from '../tag/tags';
import UserProfile from '../Models/UserProfile';
import * as constants from '../config/constants';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Zoom from '@material-ui/core/Zoom';
import Snackbar from '@material-ui/core/Snackbar';
function Transition(props) {
	return <Zoom in={true} {...props} />;
}

class Note extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user: UserProfile.getUserProfile(),
			noteId: this.props.noteId,
			category: this.props.category,
			topic: this.props.topic,
			visible: this.props.visible,
			subTopic: this.props.subTopic,
			title: this.props.title,
			description: this.props.description,
			descriptionLimit: (this.props.descriptionLimit) ? this.props.descriptionLimit : 75,
			displaySubheader: (typeof(this.props.displaySubheader) !== 'undefined') ? this.props.displaySubheader : true,
			displayTags: (typeof(this.props.displayTags) !== 'undefined') ? this.props.displayTags : true,
			updatedAt : this.props.updatedAt,
			tags : this.props.tags.join(','),
			deleteDialog : false,
			dialogOpen: false,
			anchorEl: null,
			menuOpen : null,
			deleteCallBack: this.props.deleteCallBack,
			display: 'inline-block',
			error : {},
			snackBar : false,
			snackBarMessage : '',
			editDataToSubmit : {
				title : this.props.title,
				description : this.props.description,
				tags : this.props.tags.join(',')
			}
		};
		this.editModeToggle = this.editModeToggle.bind(this);
		this.handleDialogClose = this.handleDialogClose.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
		this.updateNote = this.updateNote.bind(this);
		this.DeleteCard = this.DeleteCard.bind(this);
		this.handleClick = this.handleClick.bind(this);
		this.handleDeleteDialog = this.handleDeleteDialog.bind(this);
		this.handleDeleteDialogClose = this.handleDeleteDialogClose.bind(this);
		this.handleMenu = this.handleMenu.bind(this);
		this.validate = this.validate.bind(this);
		this.closeSnackBar = this.closeSnackBar.bind(this);
	}
	componentWillReceiveProps(nextProps) {
		this.setState({
			visible: nextProps.visible
		});
	}
	validate() {
		let copiedStates = Object.assign({}, this.state);
		let invalid = false;
		copiedStates.snackBar = false;
		if(!copiedStates.editDataToSubmit.title) {
			copiedStates.snackBarMessage = 'Title cannot be empty';
			invalid = true;
		} else if(!copiedStates.editDataToSubmit.description) {
			copiedStates.snackBarMessage = 'Description cannot be empty';
			invalid = true;
		}
		copiedStates.snackBar = invalid;
		this.setState(copiedStates);
		return !invalid;
	}
	editModeToggle() {
		let copiedStates = Object.assign({},this.state);
		copiedStates.dialogOpen =  true;
		copiedStates.description = this.state.description;
		copiedStates.display = 'none';
		copiedStates.editDataToSubmit.title = copiedStates.title;
		copiedStates.editDataToSubmit.description = copiedStates.description;
		copiedStates.editDataToSubmit.tags = copiedStates.tags;
		copiedStates.anchorEl = null;
		this.setState(copiedStates);
	}
	handleDialogClose() {
		this.setState({dialogOpen: false});
		this.setState({
			display: 'inline-block'
		});
	}
	handleClick(event) {
		this.setState({ anchorEl: event.currentTarget });
	}
	handleDeleteDialog() {
		this.setState({ anchorEl: null });
		this.setState({deleteDialog : true});
	}
	handleDeleteDialogClose() {
		this.setState({deleteDialog : false});
	}
	handleMenu() {
		this.setState({anchorEl : null});
	}
	updateNote() {
		if(!this.validate()) {
			return;
		}
		let copiedStates = Object.assign({},this.state);
		var that = this;	
		axios.put(constants.API_BASE_URL + '/note/' + that.state.noteId, {
			noteId: that.state.noteId,
			tags: that.state.editDataToSubmit.tags.split(','),
			description : that.state.editDataToSubmit.description,
			title : that.state.editDataToSubmit.title,
			userId: that.state.user.id,
		})
		.then(function (response) {
			copiedStates.snackBar = true;
			copiedStates.snackBarMessage = "Success";
			copiedStates.title = response.data.details.title;
			copiedStates.description = response.data.details.description;
			copiedStates.tags = response.data.details.tags.join(',');
			copiedStates.dialogOpen = false;
			copiedStates.display = 'inline-block';
			that.setState(copiedStates);
		})
		.catch(function (error) {
		});
	}
	DeleteCard() {
		var that = this; 
		axios.delete(constants.API_BASE_URL + '/note/' + that.state.noteId)
		.then(function (response) {
			that.state.deleteCallBack(that.state.noteId);
			that.setState({deleteDialog : false});
		})
		.catch(function (error) {
		});

	}
	handleInputChange(e) {
		let copiedStates = Object.assign({}, this.state);
		copiedStates.editDataToSubmit[e.target.name] = e.target.value;
		this.setState(copiedStates);
	}

	closeSnackBar() {
		this.setState({
			snackBar : false
		})
	}
	render() {
		const { anchorEl } = this.state;
		const display = this.state.display;
		const updatedDate = new Date(this.state.updatedAt);
		const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

		var dateFormat = months[updatedDate.getMonth()];
		dateFormat += ' ' + updatedDate.getDate();
		dateFormat += ', ' + updatedDate.getFullYear();
		dateFormat += ', ' + updatedDate.getHours();
		dateFormat += ':' + updatedDate.getMinutes();

		return (
			<span>
			<Card 
			hidden={!this.state.visible}
			style={{
				textAlign: 'left',
				height: 'auto',
				display: display,
				margin: '15px',
				width: '280px'
			}} >
			<CardHeader
			title={<Typography component="h3" style={{fontWeight: '600'}}>{this.state.title}</Typography>}
			subheader={(this.state.displaySubheader) ? ((this.state.category === 'direct') ? '' :  this.state.category + ' > ' +  this.state.topic + ' > ' + this.state.subTopic) : ''}
			action={
				<IconButton
				aria-label="More"
				aria-owns={anchorEl ? 'simple-menu' : null}
				aria-haspopup="true"
				onClick={this.handleClick} >
				<MoreVertIcon  />
				</IconButton>
			}
			/>
			<CardContent >
			<Typography component="div" style={{fontWeight: '400'}}>
			{(this.state.description) ? 
				(<div>{this.state.description.substring(0,this.state.descriptionLimit)}<br />...</div>) :
				''}
				{(this.state.displayTags) ? <Tags tags={this.state.tags.split(',')} /> : ''}
				</Typography>

				</CardContent>
				<CardActions >
				<Menu
				id="simple-menu"
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={this.handleMenu}			
				>
				<MenuItem onClick={this.editModeToggle}>Edit</MenuItem>
				<MenuItem onClick = {this.handleDeleteDialog}>Delete</MenuItem>
				</Menu>
				</CardActions>
				<Chip style={{margin: '5px'}} label={dateFormat}></Chip>
				</Card>

				<Dialog
				open={this.state.deleteDialog}
				onClose={this.handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				>
				<DialogTitle id="alert-dialog-title">{" You want to delete the note ?"}</DialogTitle>
				<DialogContent>
				<DialogContentText id="alert-dialog-description">

				</DialogContentText>
				</DialogContent>
				<DialogActions>
				<Button  onClick = {this.DeleteCard} color="primary">
				Yes
				</Button>
				<Button onClick = {this.handleDeleteDialogClose} color="primary" autoFocus>
				No

				</Button>
				</DialogActions>
				</Dialog>
				<Dialog
				fullWidth
				maxWidth='sm'
				transition={Transition}
				open={this.state.dialogOpen}
				onClose={this.handleDialogClose}
				>
				<DialogTitle>Edit your note</DialogTitle>
				<DialogContent>
				<TextField
				InputProps={{
					disableUnderline: true
				}}
				fullWidth
				label="Title"
				name="title"
				style={{fontWeight: '600'}}
				value={this.state.editDataToSubmit.title}
				onKeyUp = {this.handleInputChange}
				onChange = {this.handleInputChange}
				/>		
				<TextField
				label="Description"
				name="description"
				InputProps={{
					disableUnderline: true
				}}
				autoFocus
				value={this.state.editDataToSubmit.description}
				id="multiline-static"
				onKeyUp = {this.handleInputChange}
				onChange = {this.handleInputChange}
				multiline
				fullWidth
				margin="normal"
				/>
				<TextField InputProps={{
					disableUnderline: true
				}}
				label="Tags"
				value={this.state.editDataToSubmit.tags}
				name="tags"
				fullWidth
				onKeyUp = {this.handleInputChange}
				onChange = {this.handleInputChange}
				/>
				</DialogContent>
				<DialogActions>
				<Button onClick={this.handleDialogClose} color="primary">
				Cancel
				</Button>
				<Button onClick={this.updateNote} color="primary">	
				save
				</Button>
				</DialogActions>
				</Dialog>
				<Snackbar
				style={{
					zIndex: 9999
				}}
				id="snackbar-profile"
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				onClose={this.closeSnackBar}
				open={this.state.snackBar}
				autoHideDuration={1000}
				SnackbarContentProps={{
					'aria-describedby': 'message-id',
				}}
				message={<span id="message-id">{this.state.snackBarMessage}</span>}
				/>
				</span>
				);
}
}
export default Note;
