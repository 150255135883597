import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import * as constants from '../config/constants';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';
import ModeCommentIcon from '@material-ui/icons/ModeComment';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import axios from 'axios';
import UserProfile from '../Models/UserProfile';
import ReactGA from 'react-ga';
import { withStyles } from '@material-ui/core/styles';


const styles = theme => ({
	cardTitle: {
		fontSize: 16,
		fontWeight: 700,
		textTransform: 'uppercase'
	},
	chipLabel: {
		fontSize: 12
	},
	media: {
		height: 0,
		paddingTop: '56.25%',
	},
});

let getCardImage = (category) => {
	return '/ExpressionIcons/' + category + '.png';
}

let getBorder = (category) => {
	let colors = {
		'questions' : '#43a047',
		'correct': '#9c27b0',
		'time': '#009688',
	}
	return '1px dashed ' + colors[category];
}

function secondsToString(seconds) {
	let numyears = Math.floor(seconds / 31536000);
	let numdays = Math.floor((seconds % 31536000) / 86400); 
	let numhours = Math.floor(((seconds % 31536000) % 86400) / 3600);
	let numminutes = Math.floor((((seconds % 31536000) % 86400) % 3600) / 60);
	let numseconds = (((seconds % 31536000) % 86400) % 3600) % 60;

	let output = "";
	if(numyears > 0) {
		output += numyears + " yrs ";
	}
	if(numyears ===0 && numdays > 0) {
		output += numdays + " days ";
	}
	if(numdays === 0 && numhours > 0) {
		if(numhours === 1)
			output += numhours + " hr ";
		else
			output += numhours + " hrs ";
	}
	if(numhours === 0 && numminutes > 0) {
		if(numminutes === 1)
			output += numminutes + " min ";
		else
			output += numminutes + " mins ";
	}
	if(numminutes === 0 && numseconds > 0) {
		if(numseconds === 1)
			output += numseconds + " sec ";
		else
			output += numseconds + " secs ";
	}
	return output;
}

class CategoryData extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isMobile: window.innerWidth <= 500,
			userId: UserProfile.getUserProfile().id,
			categoryData: this.props.categoryData,
			smallItem: false
		};
		this.handleScroll = this.handleScroll.bind(this);
		ReactGA.initialize('UA-125256246-1', {
			gaOptions: {
				userId: UserProfile.getUserProfile().email
			}
		});
		ReactGA.pageview("/reports");
	}
	handleScroll() {
	}
	componentDidMount() {
	}
	componentWillReceiveProps(nextProps) {
		this.setState({
			categoryData: nextProps.categoryData
		});
	}
	render() {
		var maxWidth = '100%';
		const { classes } = this.props;
		var tablesToDisplay = [];
		var value = this.state.tabValue;
		const isMobile = window.innerWidth <= 500;
		let that = this;

		let positionOptions = {
			width: '100%',
		};

		let { smallItem } = this.state;
		if(smallItem) {
			positionOptions = {
				position: 'fixed',
				top: 0,
				left: 0,
				width: '100%',
				background: 'white',
				zIndex: 1101,
			}
		}

		return (
			<div style={positionOptions}>
			<Grid container justify="center">
			{
				Object.keys(this.state.categoryData).map(a => {

					if(a === "Uncategorised")
						return;

					let mathPercent = that.state.categoryData[a].attempts > 0 ? Math.round(that.state.categoryData[a].correct / that.state.categoryData[a].attempts * 100) : 0;
					let clr = mathPercent > 75 ? 'url(FlatIcons/pass.png)' : (mathPercent > 50 ? 'url(FlatIcons/justpass.png)' : 'url(FlatIcons/fail.png)');

					return (
						<Grid item xs={12} sm={6} md={3} lg={2} xl={2}>
						<Card key={a} style={{
							width: 'calc(100% - 16px)',
							maxWidth: '100%',
							margin: 8,
							boxShadow: smallItem ? 'none' : '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)'
						}}>
						<CardHeader
						avatar={
							<Avatar style={{
								fontSize: 14,
								background: clr,
								backgroundSize: 'contain',
								backgroundPosition: 'center'
							}}>{mathPercent + "%"}</Avatar>
						}
						style={{
							textAlign: "left",
							borderBottom: 'none',
						}}
						classes={{
							title: classes.cardTitle
						}}
						title={a.toUpperCase()}
						/>
						</Card>
						</Grid>
						);
				})
			}
			</Grid>
			</div>
			);
	}
}
CategoryData.propTypes = {
	classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(CategoryData);