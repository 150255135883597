import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import DialogContent from "@material-ui/core/DialogContent";
import UserProfile from "../Models/UserProfile";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import CardContent from "@material-ui/core/CardContent";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import DescriptionIcon from "@material-ui/icons/Description";
import TimerIcon from "@material-ui/icons/Timer";
import HomeIcon from "@material-ui/icons/Home";
import ShuffleIcon from "@material-ui/icons/Shuffle";
import AssessmentIcon from "@material-ui/icons/Assessment";
import TabIcon from "@material-ui/icons/Tab";
import { EducationDetailsDialog } from "../components";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    height: 250,
  },
  input: {
    display: "flex",
  },
  valueContainer: {
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    alignItems: "center",
  },
  chip: {
    margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`,
  },
  noOptionsMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
  },
  singleValue: {
    fontSize: 16,
    marginBottom: 8,
  },
  placeholder: {
    position: "absolute",
    left: 2,
    fontSize: 16,
  },
  paper: {
    marginTop: theme.spacing.unit,
  },
  divider: {
    height: theme.spacing.unit * 2,
  },
  cardTitle: {
    fontSize: "1rem",
    fontWeight: 700,
    textTransform: "uppercase",
  },
});

function secondsToString(seconds) {
  var numyears = Math.floor(seconds / 31536000);
  var numdays = Math.floor((seconds % 31536000) / 86400);
  var numhours = Math.floor(((seconds % 31536000) % 86400) / 3600);
  var numminutes = Math.floor((((seconds % 31536000) % 86400) % 3600) / 60);
  var numseconds = (((seconds % 31536000) % 86400) % 3600) % 60;
  let toReturn = "";
  if (numyears) toReturn += numyears + " years ";
  if (numdays) toReturn += numdays + " days ";
  if (numhours) toReturn += numhours + " hr ";
  if (numminutes) toReturn += numminutes + " min ";
  if (numseconds) toReturn += numseconds + " s ";
  return toReturn;
}

function NoOptionsMessage(props) {
  return (
    <Typography
      component="div"
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Control(props) {
  return (
    <TextField
      id="institutionNameTextField"
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
      }}
      {...props.selectProps.textFieldProps}
    />
  );
}

function Option(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

function Placeholder(props) {
  return (
    <Typography
      component="div"
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function SingleValue(props) {
  return (
    <Typography
      component="div"
      className={props.selectProps.classes.singleValue}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function ValueContainer(props) {
  return (
    <div className={props.selectProps.classes.valueContainer}>
      {props.children}
    </div>
  );
}

function Menu(props) {
  return (
    <Paper
      square
      className={props.selectProps.classes.paper}
      {...props.innerProps}
    >
      {props.children}
    </Paper>
  );
}

class QuizInstructions extends Component {
  constructor(props) {
    super(props);
    let userData = UserProfile.getUserProfile();
    let institution = {
      label: userData.institutionName,
      value: userData.institutionName,
    };

    this.state = {
      formDataToSubmit: userData,
      institutionName: institution,
      quizStates: props.quizStates,
      instructionCloseCallback: props.instructionCloseCallback,
      instructionDialog: true,
      educationalDialogOpen: false,
      agreed: true,
      quizInstructions: [
        "Each question has four or more options – one or more of the options may be correct. So, mark the response(s) that are correct according to you",
        "Once you have decided your answer, click the 'Next' button",
        "At the end of the test, you can see your score and a detailed report",
        "Stay honest on this test",
      ],
    };

    this.toggleInstructionDialog = this.toggleInstructionDialog.bind(this);
    this.acceptAndProceed = this.acceptAndProceed.bind(this);

    this.handleEducationalDialogClose =
      this.handleEducationalDialogClose.bind(this);
  }

  acceptAndProceed = () => {
    if (
      typeof this.state.formDataToSubmit.institutionName === "undefined" ||
      this.state.formDataToSubmit.institutionName === ""
    ) {
      this.setState({ educationalDialogOpen: true });
    } else if (
      typeof this.state.formDataToSubmit.usn === "undefined" ||
      this.state.formDataToSubmit.usn === ""
    ) {
      this.setState({ educationalDialogOpen: true });
    } else if (
      typeof this.state.formDataToSubmit.grade === "undefined" ||
      this.state.formDataToSubmit.grade === ""
    ) {
      this.setState({ educationalDialogOpen: true });
    } else if (
      typeof this.state.formDataToSubmit.passoutYear === "undefined" ||
      this.state.formDataToSubmit.passoutYear === ""
    ) {
      this.setState({ educationalDialogOpen: true });
    } else if (
      typeof this.state.formDataToSubmit.highestQualification === "undefined" ||
      this.state.formDataToSubmit.highestQualification === ""
    ) {
      this.setState({ educationalDialogOpen: true });
    } else if (
      typeof this.state.formDataToSubmit.division === "undefined" ||
      this.state.formDataToSubmit.division === ""
    ) {
      this.setState({ educationalDialogOpen: true });
    } else if (
      typeof this.state.formDataToSubmit.branch === "undefined" ||
      this.state.formDataToSubmit.highestQualification === ""
    ) {
      this.setState({ educationalDialogOpen: true });
    } else if (
      typeof this.state.formDataToSubmit.semester === "undefined" ||
      this.state.formDataToSubmit.semester === ""
    ) {
      this.setState({ educationalDialogOpen: true });
    } else {
      this.props.instructionCloseCallback(true);
    }
  };
  toggleInstructionDialog = () => {
    this.setState({
      ...this.state,
      instructionDialog: false,
    });
    this.props.instructionCloseCallback(false);
  };

  // //TODO: Check if this and other things are required?
  // handleQualificationChange = (name, value) => {
  //   let copiedStates = Object.assign({}, this.state);
  //   copiedStates.highestQualification = value;
  //   copiedStates.formDataToSubmit.highestQualification = value.value;
  //   this.setState(copiedStates);
  // };

  handleUserDataChange = (name, value) => {
    let copiedStates = Object.assign({}, this.state);
    copiedStates.formDataToSubmit[name] = value.value;
    this.setState(copiedStates);
  };
  handleEducationalDialogClose(startTest) {
    let that = this;
    that.setState({ ...that.state, educationalDialogOpen: false });
    if (startTest) {
      this.props.instructionCloseCallback(true);
    }
  }

  render() {
    // let handleKeyDown = this.handleKeyDown;
    const { classes, theme } = this.props;
    const selectStyles = {
      input: (base) => ({
        ...base,
        color: theme.palette.text.primary,
      }),
    };

    return (
      <div>
        {/* Not getting the new details from this component */}
        <EducationDetailsDialog
          userData={this.state.formDataToSubmit}
          dialogOpen={this.state.educationalDialogOpen}
          closeDialogCB={this.handleEducationalDialogClose}
        />

        <Dialog
          fullScreen
          open={this.state.instructionDialog}
          onClose={this.toggleInstructionDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle
            style={{
              textAlign: "center",
              fontSize: 24,
            }}
          >
            ALL THE BEST!
          </DialogTitle>

          <DialogContent>
            <Card
              style={{
                boxShadow: "none",
              }}
            >
              <CardHeader
                classes={{
                  title: classes.cardTitle,
                }}
                style={{
                  borderBottom: "none",
                }}
                title={this.state.quizStates.title}
                subheader={this.state.quizStates.description}
              />
              <CardContent>
                <Grid container spacing={24}>
                  <Grid xs={6} md={4}>
                    <List dense>
                      <ListItem>
                        <ListItemIcon>
                          <DescriptionIcon
                            style={{
                              color: "#08796B",
                            }}
                          />
                        </ListItemIcon>
                        <ListItemText
                          classes={{
                            root: classes.primaryText,
                          }}
                          primary={this.state.quizStates.listOfQuestions.length}
                          secondary="Questions"
                        />
                      </ListItem>
                    </List>
                  </Grid>
                  <Grid xs={6} md={4}>
                    <List dense>
                      <ListItem dense>
                        <ListItemIcon>
                          <TimerIcon
                            style={{
                              color: "#7D59A4",
                            }}
                          />
                        </ListItemIcon>
                        <ListItemText
                          classes={{
                            root: classes.primaryText,
                          }}
                          classes={{
                            root: classes.primaryText,
                          }}
                          primary={secondsToString(
                            this.state.quizStates.timeout
                          )}
                          secondary="Time"
                        />
                      </ListItem>
                    </List>
                  </Grid>
                  <Grid xs={6} md={4}>
                    <List dense>
                      <ListItem>
                        <ListItemIcon>
                          <HomeIcon
                            style={{
                              color: "#1B75D2",
                            }}
                          />
                        </ListItemIcon>
                        <ListItemText
                          classes={{
                            root: classes.primaryText,
                          }}
                          primary={
                            this.state.quizStates.isCompanySpecific === true
                              ? this.state.quizStates.company
                              : "No"
                          }
                          secondary="Company"
                        />
                      </ListItem>
                    </List>
                  </Grid>
                  <Grid xs={6} md={4}>
                    <List dense>
                      <ListItem dense>
                        <ListItemIcon>
                          <ShuffleIcon
                            style={{
                              color: "#388E3C",
                            }}
                          />
                        </ListItemIcon>
                        <ListItemText
                          classes={{
                            root: classes.primaryText,
                          }}
                          primary={this.state.quizStates.linear ? "Yes" : "No"}
                          secondary="Adaptive"
                        />
                      </ListItem>
                    </List>
                  </Grid>
                  <Grid xs={6} md={4}>
                    <List dense>
                      <ListItem dense>
                        <ListItemIcon>
                          <AssessmentIcon
                            style={{
                              color: "#7D59A4",
                            }}
                          />
                        </ListItemIcon>
                        <ListItemText
                          classes={{
                            root: classes.primaryText,
                          }}
                          primary={
                            this.state.quizStates.showResults
                              ? "Enabled"
                              : "Disabled"
                          }
                          secondary="Results"
                        />
                      </ListItem>
                    </List>
                  </Grid>
                  <Grid xs={6} md={4}>
                    <List dense>
                      <ListItem dense>
                        <ListItemIcon>
                          <TabIcon
                            style={{
                              color: "RED",
                            }}
                          />
                        </ListItemIcon>
                        <ListItemText
                          classes={{
                            root: classes.primaryText,
                          }}
                          primary={"Not Allowed"}
                          secondary="Tab Switching"
                        />
                      </ListItem>
                    </List>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>

            <br />
            <Divider variant="middle" />
            <br />

            <Card
              style={{
                boxShadow: "none",
              }}
            >
              <CardHeader
                classes={{
                  title: classes.cardTitle,
                }}
                style={{
                  borderBottom: "none",
                }}
                title="Instructions"
              />
              <CardContent>
                <Grid container>
                  <Grid xs={12} md={6}>
                    <List>
                      <ListItem key={0}>
                        <ListItemText
                          primary={
                            "1. Each question has four or more options – one or more of the options may be correct. So, mark the response(s) that are correct according to you"
                          }
                        />
                      </ListItem>
                      <ListItem key={2}>
                        <ListItemText
                          primary={
                            "2. Once you have decided and marked your answer, click the 'Next' button"
                          }
                        />
                      </ListItem>
                      <ListItem key={10}>
                        <ListItemText
                          primary={
                            "3. You can change your answers before final submission"
                          }
                        />
                      </ListItem>
                      <ListItem key={3}>
                        <ListItemText
                          primary={
                            "4. At the end of the test, if the test results are enabled, you can see your score and a detailed report"
                          }
                        />
                      </ListItem>
                      <ListItem key={4}>
                        <ListItemText primary={"5. Stay honest on this test"} />
                      </ListItem>
                    </List>
                  </Grid>
                  <Grid
                    xs={12}
                    md={6}
                    style={{
                      borderLeft: "1px solid rgb(204, 204, 204)",
                    }}
                  >
                    <List>
                      <ListItem key={11}>
                        <ListItemText
                          primary={
                            "6. Switching tabs may lead to disqualification"
                          }
                        />
                      </ListItem>
                      <ListItem key={24}>
                        <ListItemText
                          primary={
                            <span>
                              7. Do not press <strong>F5</strong> during the
                              test. Do not <strong>CLOSE</strong> the test
                              window unless instructed
                            </span>
                          }
                        />
                      </ListItem>
                      <ListItem key={0}>
                        <ListItemText
                          primary={
                            <div>
                              8. Bookmarked question will look like{" "}
                              <Avatar
                                style={{
                                  backgroundColor: "red",
                                  width: 24,
                                  height: 24,
                                  fontSize: 12,
                                  display: "inline-flex",
                                  color: "white",
                                }}
                              >
                                1
                              </Avatar>
                            </div>
                          }
                        />
                      </ListItem>
                      <ListItem key={2}>
                        <ListItemText
                          primary={
                            <div>
                              9. Answered question will look like{" "}
                              <Avatar
                                style={{
                                  backgroundColor: "green",
                                  width: 24,
                                  height: 24,
                                  fontSize: 12,
                                  display: "inline-flex",
                                  color: "white",
                                }}
                              >
                                1
                              </Avatar>
                            </div>
                          }
                        />
                      </ListItem>
                      <ListItem key={3}>
                        <ListItemText
                          primary={
                            <div>
                              10. Questions yet to be answered will look like{" "}
                              <Avatar
                                style={{
                                  backgroundColor: "gray",
                                  width: 24,
                                  height: 24,
                                  fontSize: 12,
                                  display: "inline-flex",
                                  color: "white",
                                }}
                              >
                                1
                              </Avatar>
                            </div>
                          }
                        />
                      </ListItem>
                      <ListItem key={4}>
                        <ListItemText
                          primary={
                            <div>
                              11. Current Question will look like{" "}
                              <Avatar
                                style={{
                                  backgroundColor: "black",
                                  width: 24,
                                  height: 24,
                                  fontSize: 12,
                                  display: "inline-flex",
                                  color: "white",
                                }}
                              >
                                1
                              </Avatar>
                            </div>
                          }
                        />
                      </ListItem>
                    </List>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              disabled={!this.state.agreed}
              onClick={this.acceptAndProceed}
              color="primary"
            >
              I Agree and Understand
            </Button>
            <Button
              onClick={this.toggleInstructionDialog}
              color="secondary"
              autoFocus
            >
              No
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

QuizInstructions.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(QuizInstructions);
