import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col } from "reactstrap";
import * as constants from "../config/constants";
import AttemptWiseReport from "./attemptWiseReport";
import Card from "@material-ui/core/Card";
import moment from "moment";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import RemoveRedEyeIcon from "@material-ui/icons/RemoveRedEye";
import Button from "@material-ui/core/Button";
import CardMedia from "@material-ui/core/CardMedia";
import PulseLoader from "react-spinners/PulseLoader";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Chip from "@material-ui/core/Chip";
import Typography from "@material-ui/core/Typography";
import ModeCommentIcon from "@material-ui/icons/ModeComment";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import axios from "axios";
import UserProfile from "../Models/UserProfile";
import ReactGA from "react-ga";
import { withStyles } from "@material-ui/core/styles";
import ReactStoreIndicator from "react-score-indicator";
import { Chart } from "react-google-charts";

const styles = (theme) => ({
  cardTitle: {
    fontSize: 16,
    fontWeight: 700,
    textTransform: "uppercase",
  },
  chipLabel: {
    fontSize: 12,
  },
  paper: {
    borderRadius: 0,
    border: "1px solid #ccc",
    boxShadow: "none",
  },
  iconRoot: {
    minWidth: 32,
  },
});
function secondsToString(seconds) {
  let numyears = Math.floor(seconds / 31536000);
  let numdays = Math.floor((seconds % 31536000) / 86400);
  let numhours = Math.floor(((seconds % 31536000) % 86400) / 3600);
  let numminutes = Math.floor((((seconds % 31536000) % 86400) % 3600) / 60);
  let numseconds = (((seconds % 31536000) % 86400) % 3600) % 60;

  let output = "";
  if (numyears > 0) {
    output += numyears + " yrs ";
  }
  if (numyears === 0 && numdays > 0) {
    output += numdays + " days ";
  }
  if (numdays === 0 && numhours > 0) {
    if (numhours === 1) output += numhours + " hr ";
    else output += numhours + " hrs ";
  }
  if (numhours === 0 && numminutes > 0) {
    if (numminutes === 1) output += numminutes + " min ";
    else output += numminutes + " mins ";
  }
  if (numminutes === 0 && numseconds > 0) {
    if (numseconds === 1) output += numseconds + " sec ";
    else output += numseconds + " secs ";
  }
  return output;
}

class SingleAttemptData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quizData: this.props.quizData,
      date: this.props.date,
      quizTitle: this.props.quizTitle,
      attemptData: this.props.attemptData,
      attempts: this.props.attempts,
      timeSpent: this.props.timeSpent,
      AttemptCode: this.props.AttemptCode,
      showAnalysis: false,
      ranks: [],
      // loadingRanks: true
      loadingRanks: false,
    };
    this.loadReport = this.loadReport.bind(this);
  }
  loadReport(AttemptCode) {
    this.setState({
      showAnalysis: true,
    });
  }
  closeAttemptWiseReport() {
    this.setState({
      showAnalysis: false,
    });
  }
  getRanking() {
    return;
    let copiedStates = Object.assign({}, this.state);
    var that = this;
    axios
      .get(
        constants.API_BASE_URL +
          "/ranking/quizRank/" +
          this.state.quizData.id +
          "/" +
          UserProfile.getUserProfile().id
      )
      .then(function (response) {
        copiedStates.ranks = response.data.details;
        copiedStates.loadingRanks = false;
        that.setState(copiedStates);
      })
      .catch(function (error) {});
  }
  componentDidMount() {
    let that = this;
    setTimeout(function () {
      that.getRanking();
    }, 400);
  }
  render() {
    var maxWidth = "100%";
    const { classes } = this.props;
    var tablesToDisplay = [];
    var value = this.state.tabValue;
    const isMobile = window.innerWidth <= 500;
    let that = this;

    let positionOptions = {
      marginTop: 32,
      marginRight: 150,
      marginLeft: 150,
      marginBottom: 32,
    };

    let cardWidth = 380;

    let { smallItem } = this.state;
    if (isMobile) {
      positionOptions = {
        marginTop: 32,
        marginRight: 0,
        marginLeft: 0,
        marginBottom: 32,
      };
      cardWidth = 320;
    }

    let timeChartData = [];
    let a = this.state.AttemptCode;

    let mathPercent = Math.round(
      (that.state.attemptData[a].correct / that.state.attemptData[a].attempts) *
        100
    );
    let clr =
      mathPercent > 75
        ? "url(FlatIcons/circle_0.png)"
        : mathPercent > 50
        ? "url(FlatIcons/circle_1.png)"
        : "url(FlatIcons/circle_2.png)";

    return (
      <Grid item md={6} lg={6} xl={4} sm={12} xs={12}>
        {this.state.showAnalysis && (
          <AttemptWiseReport
            attemptCode={this.state.AttemptCode}
            showResults={true}
            quizName={that.state.quizTitle.toUpperCase()}
            closeCB={() => this.closeAttemptWiseReport()}
          />
        )}

        <Card
          style={{
            maxWidth: cardWidth,
            width: cardWidth,
            display: "inline-block",
            margin: 8,
          }}
          classes={{
            root: classes.paper,
          }}
        >
          <CardHeader
            avatar={
              <Avatar
                style={{
                  fontSize: 14,
                  background: clr,
                  color: "#222",
                  fontWeight: 600,
                  backgroundSize: "contain",
                  backgroundPosition: "center",
                }}
              >
                {mathPercent + "%"}
              </Avatar>
            }
            style={{
              textAlign: "left",
              display: "flex",
              alignItems: "center",
              paddingTop: 16,
              paddingLeft: 16,
              paddingRight: 16,
              paddingBottom: 16,
            }}
            classes={{
              title: classes.cardTitle,
            }}
            title={that.state.quizTitle.toUpperCase()}
            subheader={moment(that.state.date).fromNow()}
          />
          <CardContent
            style={{
              paddingBottom: 0,
            }}
          >
            <Grid container spacing={24}>
              <Grid xs={6}>
                <List dense>
                  <ListItem>
                    <ListItemIcon classes={{ root: classes.iconRoot }}>
                      <img src="FlatIcons/faq.png" width="24" />
                    </ListItemIcon>
                    <ListItemText
                      classes={{
                        root: classes.primaryText,
                      }}
                      primary={
                        that.state.attempts +
                        " / " +
                        this.state.quizData.listOfQuestions.length
                      }
                      secondary="Attempts"
                    />
                  </ListItem>
                </List>
              </Grid>
              <Grid xs={6}>
                <List dense>
                  <ListItem dense>
                    <ListItemIcon classes={{ root: classes.iconRoot }}>
                      <img src="FlatIcons/clock.png" width="24" />
                    </ListItemIcon>
                    <ListItemText
                      classes={{
                        root: classes.primaryText,
                      }}
                      classes={{
                        root: classes.primaryText,
                      }}
                      primary={
                        secondsToString(
                          Math.round(that.state.timeSpent / 1000)
                        ) +
                        "/" +
                        secondsToString(this.state.quizData.timeout)
                      }
                      secondary="Time"
                    />
                  </ListItem>
                </List>
              </Grid>
            </Grid>
            <Grid
              container
              style={{
                marginBottom: 16,
              }}
            >
              <Grid xs={6}>
                <List dense>
                  <ListItem>
                    <ListItemIcon classes={{ root: classes.iconRoot }}>
                      <img src="FlatIcons/company.png" width="24" />
                    </ListItemIcon>
                    <ListItemText
                      classes={{
                        root: classes.primaryText,
                      }}
                      primary={mathPercent + "%"}
                      secondary="Percent"
                    />
                  </ListItem>
                </List>
              </Grid>
              <Grid xs={6}>
                <List dense>
                  <ListItem>
                    <ListItemIcon classes={{ root: classes.iconRoot }}>
                      <img src="FlatIcons/company.png" width="24" />
                    </ListItemIcon>
                    <ListItemText
                      classes={{
                        root: classes.primaryText,
                      }}
                      primary={moment(that.state.date).format("MMM DD, YYYY")}
                      secondary="Date"
                    />
                  </ListItem>
                </List>
              </Grid>
            </Grid>

            {this.state.loadingRanks && (
              <Grid container justify="center">
                <Grid
                  item
                  md={12}
                  style={{
                    padding: 8,
                  }}
                >
                  <PulseLoader
                    sizeUnit={"px"}
                    size={6}
                    color={"#212121"}
                    loading={true}
                  />
                </Grid>
              </Grid>
            )}
            {this.state.loadingRanks === false && (
              <Grid
                container
                justify="center"
                style={{
                  display: "none",
                }}
              >
                <Grid
                  item
                  md={12}
                  xs={12}
                  sm={12}
                  lg={12}
                  xl={12}
                  style={{
                    padding: 8,
                    border: "1px solid #ccc",
                  }}
                >
                  <div
                    style={{
                      marginTop: -16,
                      marginBottom: 8,
                    }}
                  >
                    <span
                      style={{
                        background: "#fff",
                        width: 100,
                        textAlign: "center",
                        alignItems: "center",
                        padding: 5,
                        border: "1px solid #ccc",
                        marginLeft: "-70%",
                      }}
                    >
                      Top Scorers
                    </span>
                  </div>
                  {this.state.ranks.map((r, index) => {
                    let float =
                      index === 0 ? "center" : index === 1 ? "left" : "right";
                    if (isMobile) {
                      float = "";
                    }
                    let marginTop = index > 0 && (index - 1) * 20;
                    return (
                      <div>
                        <Chip
                          size="small"
                          style={{
                            float: float,
                            borderRadius: 0,
                            marginTop: marginTop,
                            background: "#fff",
                          }}
                          avatar={
                            <img src={"General/medal-" + index + ".png"} />
                          }
                          label={r.user}
                        />
                      </div>
                    );
                  })}
                </Grid>
              </Grid>
            )}

            <CardActions
              style={{
                float: "right",
                marginTop: 16,
              }}
            >
              <Button
                color="primary"
                variant="outlined"
                size="small"
                onClick={() => this.loadReport(this.state.AttemptCode)}
              >
                View Report
              </Button>
            </CardActions>
          </CardContent>
        </Card>
      </Grid>
    );
  }
}

SingleAttemptData.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(SingleAttemptData);
