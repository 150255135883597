import * as constants from "./config/constants";
import React, { Component } from "react";
import { FormGroup, Form } from "react-bootstrap";
import axios from "axios";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Snackbar from "@material-ui/core/Snackbar";
import UserProfile from "./Models/UserProfile";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Input from "@material-ui/core/Input";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import IconButton from "@material-ui/core/IconButton";
import ReactGA from "react-ga";

class SignUp extends Component {
  constructor(props) {
    let inviteCode = new URL(window.location.href).searchParams.get("code");
    super(props);
    this.state = {
      formData: {
        id: null,
        email: "",
        mobile: null,
        name: "",
        password: "",
        gender: null,
        age: null,
        country: null,
        state: null,
        language: null,
        inviteCode: inviteCode,
        showPassword: false,
        loadState: false,
      },
      isLogin: false,
      isLoading: false,
    };
    this.handleSignIn = this.handleSignIn.bind(this);
    this.closeSnackBar = this.closeSnackBar.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSnackBarClose = this.handleSnackBarClose.bind(this);
    this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
    ReactGA.initialize("UA-125256246-1");
  }
  closeSnackBar = () => {
    this.setState({ snackBar: false });
  };
  handleSnackBarClose() {
    this.setState({ snackBar: !this.state.snackBar });
  }
  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };
  handleSignIn(event) {
    event.preventDefault();
    let copiedStates = Object.assign({}, this.state);
    copiedStates.loadState = true;
    ReactGA.event({
      category: "User",
      action: "Register Clicked",
    });
    let response = {
      email: this.state.formData.email,
      mobile: this.state.formData.mobile,
      password: this.state.formData.password,
      name: this.state.formData.name,
      gender: this.state.formData.gender,
      age: this.state.formData.age,
      country: this.state.formData.country,
      state: this.state.formData.state,
      language: this.state.formData.language,
      inviteCode: this.state.formData.inviteCode,
    };
    let that = this;
    axios
      .post(constants.API_BASE_URL + "/users", response)
      .then(function (response) {
        copiedStates.loadState = false;
        if (response.status === 200) {
          if (response.data.code !== 200) {
            copiedStates.snackBarMessage = response.data.message;
            ReactGA.event({
              category: "User",
              action: response.data.message,
            });
          } else {
            if (response.data.message) {
              copiedStates.snackBarMessage = response.data.message;
            } else {
              copiedStates.snackBarMessage = "Successfully registered!";
            }
            ReactGA.event({
              category: "User",
              action: "Created",
            });
            response.data.details.isLoggedIn = true;
            UserProfile.setUserProfile(response.data.details);

            let url_string = window.location.href;
            let url = new URL(url_string);
            let redirectedURL = url.searchParams.get("redirect");
            window.location.href = redirectedURL;
          }
        } else if (response.status === 409) {
          copiedStates.snackBarMessage =
            "This email is already registered with us. Please login";
          ReactGA.event({
            category: "User",
            action: "Error: Email already registered",
          });
        }
        copiedStates.snackBar = true;
        that.setState(copiedStates);
      })
      .catch(function (error) {
        copiedStates.loadState = false;
        copiedStates.snackBar = true;
        copiedStates.snackBarMessage = "Error";
        ReactGA.event({
          category: "User",
          action: "Error: Network",
        });
      });
    this.setState(copiedStates);
  }
  handleInputChange(e) {
    let copiedStates = Object.assign({}, this.state);
    copiedStates.formData[e.target.name] = e.target.value;
    this.setState({ copiedStates });
  }
  render() {
    return (
      <div>
        <Form onSubmit={this.handleSignIn}>
          <TextField
            style={{
              marginTop: 16,
            }}
            variant="outlined"
            id="full-name"
            label="Full name"
            placeholder="Full name"
            name="name"
            onChange={this.handleInputChange}
            onKeyUp={this.handleInputChange}
            autoComplete="off"
            fullWidth
            required
          />
          <TextField
            style={{
              marginTop: 16,
            }}
            variant="outlined"
            id="mobile-number"
            label="Mobile number"
            placeholder="Mobile Number"
            autoComplete="off"
            name="mobile"
            onChange={this.handleInputChange}
            onKeyUp={this.handleInputChange}
            fullWidth
            required
          />
          <TextField
            style={{
              marginTop: 16,
            }}
            variant="outlined"
            id="email-sign"
            label="Email"
            placeholder="example@example.com"
            autoComplete="off"
            name="email"
            onChange={this.handleInputChange}
            onKeyUp={this.handleInputChange}
            fullWidth
            required
          />
          <FormControl
            fullWidth
            style={{
              marginTop: 16,
            }}
            variant="outlined"
          >
            <InputLabel htmlFor="passwordSignup">Password</InputLabel>
            <OutlinedInput
              id="passwordSignup"
              fullWidth
              labelWidth={75}
              type={this.state.showPassword ? "text" : "password"}
              autoComplete="off"
              onChange={this.handleInputChange}
              onKeyUp={this.handleInputChange}
              name="password"
              label="Password"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Toggle password visibility"
                    onClick={this.handleClickShowPassword}
                    onMouseDown={this.handleMouseDownPassword}
                  >
                    {this.state.showPassword ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>

          <TextField
            style={{
              marginTop: 16,
            }}
            id="outlined-select-currency"
            select
            fullWidth
            name="gender"
            label="Gender"
            value={
              this.state.formData.gender ? this.state.formData.gender : "male"
            }
            onChange={this.handleInputChange}
            variant="outlined"
          >
            <MenuItem value="male">Male</MenuItem>
            <MenuItem value="female">Female</MenuItem>
            <MenuItem value="other">Other</MenuItem>
          </TextField>

          <Button
            style={{
              marginTop: 16,
            }}
            fullWidth
            variant="contained"
            color="primary"
            type="submit"
            disabled={this.state.loadState}
          >
            {this.state.loadState ? "Registering..." : "Register"}
          </Button>
        </Form>

        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          onClose={this.closeSnackBar}
          open={this.state.snackBar}
          autoHideDuration={3000}
          message={this.state.snackBarMessage}
        />
      </div>
    );
  }
}

export default SignUp;
