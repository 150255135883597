import React, { Component } from "react";
import UserProfile from "../Models/UserProfile";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Row, Col } from "reactstrap";
import EducationInfo from "./educationInfo";
import JoiningInfo from "./joiningInfo";
import PersonalInfo from "./personalInfo";
import PersonaliseInfo from "./personaliseInfo";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import Grid from "@material-ui/core/Grid";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import RestoreIcon from "@material-ui/icons/Restore";
import LocalLibraryIcon from "@material-ui/icons/LocalLibrary";
import SwipeableViews from "react-swipeable-views";
import ReactGA from "react-ga";
import Rate from "../rating/rate";

const styles = {
  bottomNavigation: {
    position: "fixed",
    width: "100%",
    left: 0,
    bottom: 0,
    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
  },
};

class Profile extends Component {
  constructor() {
    super();
    this.state = {
      isMobile: window.innerWidth <= 500,
      tab: 0,
      rateDialog: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeIndex = this.handleChangeIndex.bind(this);
    ReactGA.initialize("UA-125256246-1", {
      gaOptions: {
        userId: UserProfile.getUserProfile().email,
      },
    });
    ReactGA.pageview("/profile");
    this.callBackClose = this.callBackClose.bind(this);
  }
  componentDidMount() {
    let that = this;
    setTimeout(() => {
      that.setState({
        rateDialog: true,
      });
    }, 2000);
  }
  handleChangeIndex = (index) => {
    let tab;
    if (index === 0) tab = "PersonaliseInfo";
    else if (index === 1) tab = "PersonalInfo";
    else if (index === 2) tab = "EducationInfo";
    ReactGA.event({
      category: "Profile",
      action: "Changed Swipe: " + tab,
    });
    this.setState({ tab: index });
  };
  handleChange = (event, value) => {
    let tab;
    if (value === 0) tab = "PersonaliseInfo";
    else if (value === 1) tab = "PersonalInfo";
    else if (value === 2) tab = "EducationInfo";
    ReactGA.event({
      category: "Profile",
      action: "Changed Bottom: " + tab,
    });
    this.setState({ tab: value });
  };
  callBackClose() {
    this.setState({
      rateDialog: false,
    });
  }
  render() {
    const { classes } = this.props;

    if (this.state.isMobile === false) {
      return (
        <div className="containerWithMargin">
          <Grid container>
            <Grid item md={4} sm={12}>
              <PersonalInfo />
            </Grid>
            <Grid item md={8}>
              <Grid container>
                <Grid item md={12}>
                  <JoiningInfo />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item md={6}>
                  <PersonaliseInfo />
                </Grid>
                <Grid item md={6}>
                  <EducationInfo />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Rate
            rateDialog={this.state.rateDialog}
            callBackClose={this.callBackClose}
          />
        </div>
      );
    }
    return (
      <div className="containerWithMargin">
        <SwipeableViews
          index={this.state.tab}
          onChangeIndex={this.handleChangeIndex}
        >
          <PersonalInfo />
          <PersonaliseInfo />
          <EducationInfo />
        </SwipeableViews>
        <br />
        <br />
        <BottomNavigation
          value={this.state.tab}
          onChange={this.handleChange}
          showLabels
          className={classes.bottomNavigation}
        >
          <BottomNavigationAction label="Recents" icon={<RestoreIcon />} />
          <BottomNavigationAction
            label="Personal"
            icon={<PermIdentityIcon />}
          />
          <BottomNavigationAction
            label="Education"
            icon={<LocalLibraryIcon />}
          />
        </BottomNavigation>
      </div>
    );
  }
}

Profile.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Profile);
