import React, { Component } from 'react';
import * as constants from '../config/constants';
import axios from 'axios';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import TextField from '@material-ui/core/TextField';

class SubTopicSearch extends Component {
	constructor(props) {
		super(props);
		this.state ={
			subtopicArr : [],
			searchTerm : '',
			checkedArray: (this.props.selectedOptions) ? this.props.selectedOptions : [],
			checked : false,
			subTopicSelectedCB: (this.props.subTopicSelectedCB) ? this.props.subTopicSelectedCB : null
		}
		this.componentDidMount = this.componentDidMount.bind(this);
		this.handleCheckbox = this.handleCheckbox.bind(this);
		this.handleSearchChange = this.handleSearchChange.bind(this);
		this.handleSelectAll = this.handleSelectAll.bind(this);
		this.getCheckedArr =this.getCheckedArr.bind(this);
	}
	componentDidMount() {
		var that = this;
		axios.get(constants.API_BASE_URL + "/questions/categories/all")
		.then(function(response) {
			var details = [];
			for(var key in response.data['details']) {
				let checkedState = false;
				if(that.state.checkedArray && that.state.checkedArray.indexOf(key) > -1)
					checkedState = true;
				details.push({
					subTopic: key,
					count: response.data['details'][key],
					visible: true,
					checked : checkedState
				});
			}
			that.setState({
				subtopicArr : details
			});
		})
		.catch(function (error) {
			// console.log(error);
		});
	}
	handleSearchChange(event) {
		let copiedStates = Object.assign({},this.state);
		if(event.target.value !== '') {
			for(let i = 0 ; i< copiedStates.subtopicArr.length ; i++){
				var regex = new RegExp((event.target.value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')) , 'ig');
				if((copiedStates.subtopicArr[i].subTopic.search(regex) > -1)) {
					copiedStates.subtopicArr[i].visible = true;
				}
				else {
					copiedStates.subtopicArr[i].visible = false;
				}
			}		
		}
		else {
			for(let i = 0 ; i< copiedStates.subtopicArr.length ; i++) {
				copiedStates.subtopicArr[i].visible = true;
			}
		}
		copiedStates.searchTerm = event.target.value;
		this.setState(copiedStates);
	}
	handleCheckbox(event) {
		let copiedStates = Object.assign({},this.state);
		for (let i = 0; i < copiedStates.subtopicArr.length; i++) {
			if(event.target.value === copiedStates.subtopicArr[i].subTopic){
				copiedStates.subtopicArr[i].checked = event.target.checked;
				if(event.target.checked) {
					copiedStates.checkedArray.push(event.target.value);
				}
				else {
					let index = this.state.checkedArray.indexOf(event.target.value);
					copiedStates.checkedArray.splice(index,1);
				}
				break;
			}
		}
		(this.state.subTopicSelectedCB) && this.state.subTopicSelectedCB(copiedStates.checkedArray);
		this.setState(copiedStates);
	}
	handleSelectAll(event) {
		let  copiedStates = Object.assign({},this.state);
		if(event.target.checked) {			
			for(let i = 0 ; i< copiedStates.subtopicArr.length ; i++) {
				if(copiedStates.subtopicArr[i].visible === true && (copiedStates.checkedArray.indexOf(copiedStates.subtopicArr[i].subTopic) === -1)) {
					copiedStates.subtopicArr[i].checked = true;
					copiedStates.checkedArray.push(copiedStates.subtopicArr[i].subTopic);
				}
			}
		}
		else {
			copiedStates.selectAllChecked = event.target.checked;
			for(let i = 0 ; i< copiedStates.subtopicArr.length ; i++) {	
				copiedStates.subtopicArr[i].checked = false;
				var index = this.state.checkedArray.indexOf(event.target.value);
				copiedStates.checkedArray.splice(index,1);			
			}
		}
		copiedStates.selectAllChecked = event.target.checked;
		(this.state.subTopicSelectedCB) && this.state.subTopicSelectedCB(copiedStates.checkedArray);
		this.setState(copiedStates);
	}
	getCheckedArr(checkedArray) {
		return this.state.checkedArray
	}
	render(){	
		var subtopicComponent = [];
		for(var i =0;i<this.state.subtopicArr.length;i++) {
			subtopicComponent.push(
				<FormControlLabel
				control={
					<Checkbox
					checked={(this.state.checkedArray.indexOf(this.state.subtopicArr[i].subTopic) > -1) ? 'checked' : false}
					onChange={this.handleCheckbox}
					value={this.state.subtopicArr[i].subTopic}
					/>
				}
				label={this.state.subtopicArr[i].subTopic + "(" + this.state.subtopicArr[i].count + ")"}
				style = {{
					display: (this.state.subtopicArr[i].visible) ? 'inline-flex' : 'none'
				}}
				/>);
		}
		return(
			<div>
			<TextField
			fullWidth
			id="input-with-icon-textfield"
			label="Search for topics"
			value={this.state.searchTerm}
			onChange={this.handleSearchChange}
			/>
			<FormControlLabel
			control={
				<Checkbox
				checked={this.state.selectAllChecked}
				onClick={this.handleSelectAll}
				value="Select all"
				/>
			}
			label={(this.state.searchTerm) ? "Select Below" : "Select All"}
			/>
			<FormGroup row>
			{subtopicComponent}
			</FormGroup>
			</div>
			)
	}
}

export default SubTopicSearch;