import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Options from './options';
import Grid from '@material-ui/core/Grid';
import Tags from '../tag/tags';
import ReactHtmlParser from 'react-html-parser';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
	cardTitle: {
		fontSize: 16,
		fontWeight: 700,
		textTransform: 'uppercase'
	}
});

class ReviewQuestion extends Component {
	constructor(props) {
		super(props);
		this.state = {
			seqId: this.props.seqId,
			id: this.props.id,
			title: this.props.title,
			explanation: this.props.explanation,
			description: this.props.description,
			options: this.props.options,
			subTopics: this.props.subTopics,
			tags: this.props.tags,
			selectedOption: this.props.selectedOption,
			optionsType: this.props.optionsType,
			beginRecording: false,
			recordings: [],
			stopButtonDisabled: true,
			startButtonDisabled: false,
			timeSpent: this.props.timeSpent,
			isCorrect: this.props.isCorrect,
			correctOptions: this.props.correctOptions,
			withMargin: (typeof(this.props.withMargin) !== 'undefined') ? this.props.withMargin : true,
		};
	}
	render() {
		
		let { classes } = this.props;

		let margin = (this.state.withMargin === false) ? 0 : '15px';
		return (
			<div className="question">
			<Card style={{
				margin: margin,
				boxShadow: 'none'
			}}>
			<CardContent style={{
				padding: 8
			}}>
			<Grid container>
			<Grid item md={9} style={{
				border: '1px solid rgb(204, 204, 204)',
				borderRadius: 5
			}}>
			<div className="quizQuestionDescription">
			{ReactHtmlParser(this.state.description)}
			</div>
			</Grid>
			<Grid md={3}>
			<div className="quizQuestionOptions">
			<Options
			options={this.state.options}
			optionsType={this.state.optionsType}
			selectedOption={this.state.selectedOption}
			correctOptions={this.state.correctOptions}
			isCorrect={this.state.isCorrect}
			answerCallback={this.answerCallback}
			questionId={this.state.id}
			readOnly={true}
			/>
			</div>
			</Grid>
			</Grid>
			<Grid container>
			<Grid item md={12} style={{
				padding: 8
			}}>
			{ReactHtmlParser(this.state.explanation)}
			</Grid>
			</Grid>
			</CardContent>
			</Card>
			</div>
			);
	}
}

ReviewQuestion.contextTypes = {
	theme: PropTypes.object,
};

export default withStyles(styles)(ReviewQuestion);