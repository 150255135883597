import React, { Component } from "react";
import PropTypes from "prop-types";
import * as constants from "../config/constants";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import Typography from "@material-ui/core/Typography";
import Select from "react-select";
import { withStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import Edit from "@material-ui/icons/Edit";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import UserProfile from "../Models/UserProfile";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Snackbar from "@material-ui/core/Snackbar";
import MenuItem from "@material-ui/core/MenuItem";
import axios from "axios";
import ReactGA from "react-ga";
import { EducationDetailsDialog } from "../components";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    height: 250,
  },
  input: {
    display: "flex",
  },
  valueContainer: {
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    alignItems: "center",
  },
  chip: {
    margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`,
  },
  noOptionsMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
  },
  singleValue: {
    fontSize: 16,
    marginBottom: 8,
  },
  placeholder: {
    position: "absolute",
    left: 2,
    fontSize: 16,
  },
  paper: {
    marginTop: theme.spacing.unit,
  },
  divider: {
    height: theme.spacing.unit * 2,
  },
  cardTitle: {
    fontSize: 16,
    fontWeight: 700,
    textTransform: "uppercase",
  },
});

function NoOptionsMessage(props) {
  return (
    <Typography
      component="div"
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Control(props) {
  return (
    <TextField
      id="institutionNameTextField"
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
      }}
      {...props.selectProps.textFieldProps}
    />
  );
}

function Option(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

function Placeholder(props) {
  return (
    <Typography
      component="div"
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function SingleValue(props) {
  return (
    <Typography
      component="div"
      className={props.selectProps.classes.singleValue}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function ValueContainer(props) {
  return (
    <div className={props.selectProps.classes.valueContainer}>
      {props.children}
    </div>
  );
}

function Menu(props) {
  return (
    <Paper
      square
      className={props.selectProps.classes.paper}
      {...props.innerProps}
    >
      {props.children}
    </Paper>
  );
}

let timeOut = [];

const components = {
  Option,
  Control,
  NoOptionsMessage,
  Placeholder,
  SingleValue,
  ValueContainer,
  Menu,
};

class EducationInfo extends Component {
  constructor() {
    super();
    let userData = Object.assign({}, UserProfile.getUserProfile());
    let institution = {
      label: userData.institutionName,
      value: userData.institutionName,
    };
    this.state = {
      formData: userData,
      educationalDialogOpen: false,
      snackBar: false,
      error: {},
      listOfColleges: [],
      institutionName: institution,
      previousInstitutionName: "",
    };
    // this.editModeToggle = this.editModeToggle.bind(this);
    this.editModeEducationToggle = this.editModeEducationToggle.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);

    ReactGA.initialize("UA-125256246-1", {
      gaOptions: {
        userId: UserProfile.getUserProfile().email,
      },
    });
  }

  // shouldComponentUpdate(nextProps, nextState) {
  //   console.log(this.state, nextState);
  //   return true;
  // }

  editModeEducationToggle() {
    let copiedStates = Object.assign({}, this.state);
    copiedStates.formData = Object.assign({}, this.state.formData);
    copiedStates.educationalDialogOpen = true;
    this.setState(copiedStates);
  }

  handleDialogClose(next, updatedFormData) {
    if (next)
      this.setState({
        ...this.state,
        formData: updatedFormData,
        educationalDialogOpen: false,
      });
    else this.setState({ ...this.state, educationalDialogOpen: false });
  }

  render() {
    const { classes, theme } = this.props;
    const selectStyles = {
      input: (base) => ({
        ...base,
        color: theme.palette.text.primary,
      }),
    };
    return (
      <div>
        <EducationDetailsDialog
          userData={this.state.formData}
          dialogOpen={this.state.educationalDialogOpen}
          closeDialogCB={this.handleDialogClose}
        />

        <Card className="cardWithMargins profileCard">
          <CardHeader
            classes={{
              title: classes.cardTitle,
            }}
            style={{
              textAlign: "left",
            }}
            title="Educational Details"
            subheader={
              this.state.formData.institutionName
                ? this.state.formData.institutionName.length > 30
                  ? this.state.formData.institutionName.substring(0, 30) + "..."
                  : this.state.formData.institutionName
                : "Please add your educational details"
            }
            action={
              this.state.formData.institutionName ? (
                <IconButton
                  variant="fab"
                  color="primary"
                  aria-label="edit"
                  onClick={this.editModeEducationToggle}
                >
                  <Edit role="button" />
                </IconButton>
              ) : (
                <IconButton
                  variant="fab"
                  color="primary"
                  aria-label="edit"
                  onClick={this.editModeEducationToggle}
                >
                  <AddIcon role="button" />
                </IconButton>
              )
            }
          />
          {this.state.formData.institutionName ? (
            <CardContent>
              <Typography component="div">
                <List>
                  <ListItem>
                    <ListItemIcon>
                      <img src="FlatIcons/department.png" width="24" />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        !this.state.formData.degree
                          ? "Kindly update your details here"
                          : `${this.state.formData.degree} - ${this.state.formData.branch}`
                      }
                    />
                  </ListItem>

                  <li>
                    <Divider />
                  </li>
                  <ListItem>
                    <ListItemIcon>
                      <img src="FlatIcons/schedule.png" width="24" />
                    </ListItemIcon>
                    <ListItemText primary={this.state.formData.passoutYear} />
                  </ListItem>
                </List>
              </Typography>
            </CardContent>
          ) : (
            <CardContent>Please add your educational details</CardContent>
          )}
        </Card>
      </div>
    );
  }
}

EducationInfo.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(EducationInfo);
