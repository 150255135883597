import axios from 'axios';
import UserProfile from './Models/UserProfile';
import * as constants from './config/constants';

var CryptoJS = require("crypto-js");

var IndexedDB = (function() {
	var db;
	var objectStore;

	var getConnection = function(callBack) {

		var req = indexedDB.open('dbName', 12);
		req.onsuccess = function (evt) {
			db = this.result;
			callBack(db);
		};	
		req.onerror = function (evt) {
			console.error("openDb:", evt.target.errorCode);
		};

		req.onupgradeneeded = function(event) {
			var db = event.target.result;
			objectStore = db.createObjectStore("Lessons", {keyPath: "lessonId"});
		}
	};

	var addition = function() {
		getConnection(function() {
			axios.get(constants.API_BASE_URL + '/lessons', {
			})
			.then(function (response) {
				var request = db.transaction(["Lessons"], "readwrite").objectStore("Lessons");
				response.data.map(function(lesson) {
					try {
						request.get(lesson.lessonId).onsuccess = function() {
							var encrypted = CryptoJS.AES.encrypt(JSON.stringify(lesson), UserProfile.getUserProfile()._id).toString();
							if(!request.result) {
								request.put({
									lessonId: lesson.lessonId,
									data: encrypted
								});
							}
						}
						return true;
					}
					catch(error) {
						return false;
					}
				});
			});
		});
	}

	var retrieveAll =function(retrieveCompleted) {
		getConnection(function() {
			objectStore = db.transaction(["Lessons"], "readwrite").objectStore("Lessons");
			var lessons = [];
			objectStore.openCursor().onsuccess = function(event) {
				var cursor = event.target.result;
				if (cursor) {
					var bytes  = CryptoJS.AES.decrypt(cursor.value.data.toString(), UserProfile.getUserProfile()._id);
					var data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
					lessons.push({
						lessonId: cursor.value.lessonId,
						category: data.category,
						topic: data.topic,
						subTopic: data.subTopic,
						description: data.description,
						title: data.title,
					});
					cursor.continue();
				}
				else {
					retrieveCompleted(lessons);
				}
			};
		});
		
	}

	/*var updateData = function() {
	}*/

	var remove = function() {
		axios.get(constants.API_BASE_URL + '/lessons', {
		})
		.then(function (response) {
			var request = db.transaction(["Lessons"], "readwrite")
			.objectStore("Lessons")
			.delete(response.data[0]['lessonId']);
			request.onsuccess = function(event) {
			};
		});
	}
	return {
		getConnection: getConnection,
		addition: addition,
		retrieveAll : retrieveAll,
		remove : remove,
		// updateData : updateData
	}



})();
export default IndexedDB;
