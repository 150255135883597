import React, { Component } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MailIcon from '@material-ui/icons/Mail';
import DraftsIcon from '@material-ui/icons/Drafts';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import ReactHtmlParser from 'react-html-parser';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import axios from 'axios';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import moment from 'moment';
import UserProfile from '../Models/UserProfile';
import * as constants from '../config/constants';

function Transition(props) {
	return <Slide direction="up" {...props} />;
}

const styles = theme => ({
	root: {
		flexGrow: 1,
	},
	flex: {
		flex: 1,
	},
	menuButton: {
		marginLeft: -12,
		marginRight: 20,
		color: 'white'
	},
	menuButtonMobile: {
		marginLeft: -12,
		color: 'white'
	},
	headerIcons: {
		color: 'white'
	}
});

class NoticationList extends Component {

	constructor(props) {
		super(props);	
		this.state ={
			notification : [],
			currentNotification: {},
			anchorElNotification : this.props.anchorElNotification,
			countCallback: (this.props.countCallback) ? this.props.countCallback : null,
			closeCallback : (this.props.closeCallback) ? this.props.closeCallback : null,
			userId: UserProfile.getUserProfile().id,
			displayDialog: false
		}
		this.componentDidMount = this.componentDidMount.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleIconUpdate = this.handleIconUpdate.bind(this);
		this.handleReadAll = this.handleReadAll.bind(this);	
		this.readNotification = this.readNotification.bind(this);	
		this.handleCloseDialog = this.handleCloseDialog.bind(this);	
	}
	componentDidMount() {
		
		var that = this;
		axios.get(constants.API_BASE_URL + '/notification/users/' + this.state.userId)
		.then(function(response){
			var notification = response.data.details;
			that.setState({
				notification : notification
			});
			let count = 0;
			notification.map(n => {
				(n.read !== true) && count++;
				return count;
			});
			(that.state.countCallback) && that.state.countCallback(count);
		})
		.catch(function(error){
		});
		
	}
	componentWillReceiveProps(nextProps){
		this.setState({anchorElNotification: nextProps.anchorElNotification});
	}
	handleCloseDialog() {
		this.setState({
			displayDialog: false
		})
	}
	handleClose() {
		this.setState({
			anchorElNotification : null
		});
		(this.state.closeCallback) && this.state.closeCallback('User closed by pressing cancel button');
	}
	handleIconUpdate(notificationId, notificationStatus) {
		let that = this;
		let copiedStates = Object.assign({}, this.state);
		axios.put(constants.API_BASE_URL + '/notification/' + notificationId, {
			read : !notificationStatus
		})
		.then(function (response) {
			copiedStates.notification.map(n => {
				(n.id === response.data.details.id) && (n.read = response.data.details.read);
				return true;
			});
			that.setState(copiedStates);
			let count = 0;
			copiedStates.notification.map(n => {
				(n.read !== true) && count++;
				return true;
			});
			(that.state.countCallback) && that.state.countCallback(count);
		})
		.catch(function (error) {
			console.log(error);
		});
	}
	readNotification(index) {
		let copiedStates = Object.assign({}, this.state);
		let currentNotification = copiedStates.notification[index];
		let that = this;
		this.setState({
			currentNotification: currentNotification,
			displayDialog: true
		}, function() {
			that.handleIconUpdate(currentNotification.id, currentNotification.read);
		});
	}
	handleReadAll() {
		let copiedStates = Object.assign({}, this.state);
		for(var i = 0 ;i < copiedStates.notification.length; i++) {
			if(typeof(copiedStates.notification[i].read) === "undefined" || copiedStates.notification[i].read === false)
				this.handleIconUpdate(copiedStates.notification[i].id, copiedStates.notification[i].read);			
		}
	}
	render() {
		var notificationArr = this.state.notification ? this.state.notification : [];
		var notificationComponent = [];

		let { classes } = this.props;

		notificationComponent.push(
			<MenuItem key={-1} component="div">
			<ListItemText
			disableTypography
			primary={
				<Button 
				style = {{
					float : 'right'
				}}
				onClick = {this.handleReadAll}
				size="small"
				color="secondary"
				>
				Read All
				</Button>
			}
			/>
			</MenuItem>);
		notificationArr.map((notification, i) => {
			notificationComponent.push(
				<MenuItem key={i} component="div" onClick={() => this.readNotification(i)} >
				<ListItemIcon>
				<Avatar 
				src = {notification.icon}
				/>
				</ListItemIcon>
				<ListItemText
				primary={notification.title}
				secondary={moment(notification.createdAt).fromNow()} />
				<ListItemIcon>
				<IconButton
				style={{
					marginRight: 0
				}}
				onClick={() => this.handleIconUpdate(notification.id,notification.read)}>
				{(notification.read === true) ?
					(<Tooltip title={"Mark as unread"}>
						<MailIcon />
						</Tooltip>) : 
					(<Tooltip title={"Mark as read"}>
						<DraftsIcon />
						</Tooltip>)
				}
				</IconButton>
				</ListItemIcon>
				</MenuItem>
				);
			return true;
		});
		if(notificationArr.length === 0) {
			notificationComponent.pop();
			notificationComponent.push(
				<MenuItem key={Math.random()} component="div">
				<ListItemText
				dense
				primary={"No notifications!"}
				/>
				</MenuItem>
				);
		}
		return(
			<div>

			<Dialog
			aria-labelledby="customized-dialog-title"
			open={this.state.displayDialog}
			onClose={this.handleCloseDialog}
			transition={Transition}
			>
			<DialogTitle id="customized-dialog-title" onClose={() => this.handleCloseDialog()}>
			{this.state.currentNotification.title}
			</DialogTitle>
			<DialogContent dividers style={{
				backgroundColor: '#e8e8e8 !important',
			}}>
			<Typography className="yourSuggestion">
			{ReactHtmlParser(this.state.currentNotification.description)}
			</Typography>
			</DialogContent>
			<DialogActions>
			<Button autoFocus onClick={this.handleCloseDialog} color="primary">
			Close
			</Button>
			</DialogActions>
			</Dialog>

			<Menu style ={{top : '30px'}}
			anchorEl={this.state.anchorElNotification}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'right',
			}}
			transformOrigin={{
				vertical: 'bottom',
				horizontal: 'right',
			}}
			open={Boolean(this.state.anchorElNotification)}
			onClose={this.handleClose}>
			{notificationComponent}
			</Menu>
			</div>
			)
	}
}

NoticationList.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NoticationList);