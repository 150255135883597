import React, { Component } from "react";
import Avatar from "@material-ui/core/Avatar";
import * as constants from "../config/constants";
import Tags from "../tag/tags";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Edit from "@material-ui/icons/Edit";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import CallIcon from "@material-ui/icons/Call";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import { FormGroup } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import axios from "axios";
import UserProfile from "../Models/UserProfile";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import blue from "@material-ui/core/colors/blue";
import red from "@material-ui/core/colors/red";
import firebase from "firebase/app";
import "firebase/storage";
import { withStyles } from "@material-ui/core/styles";
import ReactGA from "react-ga";
let CryptoJS = require("crypto-js");

const styles = (theme) => ({
  cardTitle: {
    fontSize: 16,
    fontWeight: 700,
    textTransform: "uppercase",
  },
});

class PersonalInfo extends Component {
  constructor() {
    super();
    let examsDescription = {
      PLACEMENTS: {
        description: "Way to your dream job",
        image: "ExamIcons/Placements.png",
      },
      "GRE / GMAT": {
        description: "Want to get into to higher education",
        image: "ExamIcons/HigherEducation.png",
      },
      CAT: {
        description: "Indian MBA is your aspiration",
        image: "ExamIcons/CAT.jpg",
      },
      IBPS: {
        description: "You see a bank officer in you",
        image: "ExamIcons/IBPS.png",
      },
      RAILWAY: {
        description: "Wanted to get into India's largest firm",
        image: "ExamIcons/Railway.png",
      },
    };
    const exams = Object.keys(examsDescription);
    let userData = UserProfile.getUserProfile();
    let examObject = {};
    let preferredExamsNeeded = true;

    if (userData.preferredExams) {
      userData.preferredExams = userData.preferredExams.filter((e) => {
        return e !== "";
      });
      if (
        (userData &&
          userData.preferredExams &&
          userData.preferredExams.length > 0) ||
        userData.skippedExams === true
      )
        preferredExamsNeeded = false;
      exams.map(function (exam) {
        examObject[exam] =
          userData && userData.preferredExams.indexOf(exam) > -1 ? true : false;
        return true;
      });
    }

    if (userData.profilePic) {
      let that = this;
      if (!firebase.apps.length) {
        let config = {
          apiKey: "AIzaSyAqANsEPMGHRRHqC3ApoPgFFaMaiQ0bxBQ",
          authDomain: "react-web-app-v1.firebaseapp.com",
          storageBucket: "react-web-app-v1.appspot.com",
        };
        firebase.initializeApp(config);
      }

      let storageRef = firebase.storage().ref();
      storageRef
        .child(userData.profilePic)
        .getDownloadURL()
        .then(function (url) {
          userData.profilePic = url;
          that.forceUpdate();
        });
    } else {
      userData.profilePic =
        userData.gender === "male" ? "male.jpg" : "female.jpg";
    }

    this.state = {
      formData: userData,
      formDataToSubmit: Object.assign({}, userData),
      dialogOpen: false,
      snackBar: false,
      snackBarMessage: "",
      exams: examObject,
      preferredExamsNeeded: preferredExamsNeeded,
      preferredExamsDialogOpen: preferredExamsNeeded,
      submitted: false,
      error: {},
      picChanged: false,
      examsDescription: examsDescription,
      displayNext: true,
      displaySubmit: false,
    };
    this.closeSnackBar = this.closeSnackBar.bind(this);
    this.editModeToggle = this.editModeToggle.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleDialogSave = this.handleDialogSave.bind(this);
    this.handleDialogNext = this.handleDialogNext.bind(this);
    this.handleExamDialogOpenDialogSave =
      this.handleExamDialogOpenDialogSave.bind(this);
    this.handleDeleteExam = this.handleDeleteExam.bind(this);
    this.handleExamDialogOpenDialogClose =
      this.handleExamDialogOpenDialogClose.bind(this);
    this.validate = this.validate.bind(this);
    this.uploadProfilePic = this.uploadProfilePic.bind(this);
    this.previewProfileImg = this.previewProfileImg.bind(this);
    this.handleExamDialogSkip = this.handleExamDialogSkip.bind(this);
    ReactGA.initialize("UA-125256246-1", {
      gaOptions: {
        userId: UserProfile.getUserProfile().email,
      },
    });
  }
  handleDialogNext() {
    let copiedStates = Object.assign({}, this.state);
    copiedStates.displaySubmit = true;
    copiedStates.displayNext = false;
    this.setState(copiedStates);
  }
  handleExamDialogSkip() {
    let copiedStates = Object.assign({}, this.state);
    let userProfile = UserProfile.getUserProfile();
    userProfile.skippedExams = true;
    userProfile.isLoggedIn = true;
    UserProfile.setUserProfile(userProfile);
    copiedStates.preferredExamsDialogOpen = false;
    userProfile = UserProfile.getUserProfile();
    this.setState(copiedStates);
  }
  closeSnackBar() {
    this.setState({ snackBar: false });
  }
  handleDeleteExam(exam) {
    let copiedStates = Object.assign({}, this.state);
    copiedStates.exams[exam] = !copiedStates.exams[exam];
    this.setState(copiedStates);
  }
  editModeToggle() {
    let copiedStates = Object.assign({}, this.state);
    copiedStates.dialogOpen = true;
    copiedStates.formDataToSubmit = Object.assign({}, this.state.formData);
    this.setState(copiedStates);
  }

  handleInputChange(e) {
    let copiedStates = Object.assign({}, this.state);
    copiedStates.formDataToSubmit[e.target.name] = e.target.value;
    this.setState(copiedStates);
  }
  handleDialogSave(event) {
    event.preventDefault();
    ReactGA.event({
      category: "PersonalInfo",
      action: "Updating",
    });
    let copiedStates = Object.assign({}, this.state);
    let that = this;
    let examsToSubmit = [];
    for (let exam in this.state.exams) {
      if (this.state.exams[exam] === true) {
        examsToSubmit.push(exam);
      }
    }
    if (!this.validate()) {
      return;
    }
    let request = {
      email: this.state.formDataToSubmit.email,
      mobile: this.state.formDataToSubmit.mobile,
      password: this.state.formDataToSubmit.password,
      name: this.state.formDataToSubmit.name,
      gender: this.state.formDataToSubmit.gender,
      age: this.state.formDataToSubmit.age,
      country: this.state.formDataToSubmit.country,
      state: this.state.formDataToSubmit.state,
      language: this.state.formDataToSubmit.language,
      id: this.state.formDataToSubmit.id,
    };
    if (examsToSubmit.length > 0) {
      request.preferredExams = examsToSubmit.join(",");
    } else {
      request.preferredExams = [];
    }
    if ("institutionName" in this.state.formDataToSubmit)
      request.institutionName = this.state.formDataToSubmit.institutionName;
    if ("highestQualification" in this.state.formDataToSubmit)
      request.highestQualification =
        this.state.formDataToSubmit.highestQualification;
    if ("grade" in this.state.formDataToSubmit)
      request.grade = this.state.formDataToSubmit.grade;
    if ("passoutYear" in this.state.formDataToSubmit)
      request.passoutYear = this.state.formDataToSubmit.passoutYear;

    let id = this.state.formDataToSubmit.id;

    this.uploadProfilePic(function (fullPathUploadedImage) {
      if (fullPathUploadedImage) request.profilePic = fullPathUploadedImage;

      axios
        .put(constants.API_BASE_URL + "/users/" + id, request)
        .then(function (response) {
          copiedStates.displayNext = true;
          copiedStates.displaySubmit = false;
          let olderPic = copiedStates.formData.profilePic;
          copiedStates.snackBarMessage = response.data.message;
          copiedStates.formData = response.data.details;

          if (fullPathUploadedImage) {
            let storageRef = firebase.storage().ref();
            storageRef
              .child(response.data.details.profilePic)
              .getDownloadURL()
              .then(function (url) {
                copiedStates.snackBar = false;
                copiedStates.formData.profilePic = url;
                that.setState(copiedStates);
              });
          } else {
            copiedStates.formData.profilePic = olderPic;
          }

          ReactGA.event({
            category: "PersonalInfo",
            action: "Updated",
          });

          response.data.details.isLoggedIn = true;
          UserProfile.setUserProfile(response.data.details);
          copiedStates.snackBar = true;
          copiedStates.dialogOpen = false;
          that.setState(copiedStates);
          return false;
        })
        .catch(function (error) {
          copiedStates.snackBarMessage = "Error";
          copiedStates.snackBar = true;
          that.setState(copiedStates);
          ReactGA.event({
            category: "PersonalInfo",
            action: "Error",
          });
        });
    });
  }
  handleDialogClose() {
    let copiedStates = Object.assign({}, this.state);
    copiedStates.dialogOpen = false;
    copiedStates.displayNext = true;
    copiedStates.displaySubmit = false;
    this.setState(copiedStates);
  }
  handleExamDialogOpenDialogSave(event) {
    event.preventDefault();
    let examsToSubmit = [];
    for (var exam in this.state.exams) {
      if (this.state.exams[exam] === true) {
        examsToSubmit.push(exam);
      }
    }
    let request = {
      preferredExams: examsToSubmit.join(","),
      id: this.state.formDataToSubmit.id,
    };
    let that = this;
    axios
      .put(
        constants.API_BASE_URL + "/users/" + this.state.formDataToSubmit.id,
        request
      )
      .then(function (response) {
        if (response.status === 200) {
          if (response.data.code === 406) {
            that.setState({
              snackBarMessage:
                "Something went while updating. Please try again",
            });
            response.data.details[0].isLoggedIn = true;
            UserProfile.setUserProfile(response.data.details[0]);
          } else if (response.data.code === 200) {
            if (response.data.message) {
              that.setState({ snackBarMessage: response.data.message });
            } else {
              that.setState({ snackBarMessage: "Update Successful!" });
            }
            response.data.details.isLoggedIn = true;
            UserProfile.setUserProfile(response.data.details);

            let copiedStates = Object.assign({}, that.state);
            copiedStates.formData.preferredExams =
              response.data.details.preferredExams;
            that.setState(copiedStates);
          } else {
            if (response.data.message) {
              that.setState({ snackBarMessage: response.data.message });
              UserProfile.setUserProfile([]);
            }
          }
        } else if (response.status === 409) {
          that.setState({
            snackBarMessage:
              "This email is already registered with us. Please login",
          });
        }
        that.setState({ snackBar: true });
        that.handleExamDialogOpenDialogClose();
        return false;
      })
      .catch(function (error) {
        that.setState({ snackBarMessage: "Error" });
        that.setState({ snackBar: true });
      });
  }
  handleExamDialogOpenDialogClose() {
    this.setState({
      preferredExamsDialogOpen: false,
    });
  }
  validate() {
    let copiedStates = Object.assign({}, this.state);
    let isvalid = false;
    let phoneno = /^[987]\d{9}$/;
    if (!copiedStates.formDataToSubmit.name) {
      copiedStates.snackBarMessage = "Fill in your name";
      isvalid = true;
    } else if (
      !copiedStates.formDataToSubmit.mobile ||
      !copiedStates.formDataToSubmit.mobile.match(phoneno)
    ) {
      copiedStates.snackBarMessage = "Please enter 10 digit mobile number";
      isvalid = true;
    }
    copiedStates.snackBar = isvalid;
    this.setState(copiedStates);
    return !isvalid;
  }
  previewProfileImg(event, file) {
    let copiedStates = Object.assign({}, this.state);
    let reader = new FileReader();
    let that = this;
    reader.onload = function (e) {
      copiedStates.picChanged = true;
      copiedStates.formDataToSubmit.profilePic = e.target.result;
      that.setState(copiedStates);
    };
    reader.readAsDataURL(file);
    return;
  }
  uploadProfilePic(callback) {
    if (!this.state.picChanged) {
      return callback(false);
    }
    let copiedStates = Object.assign({}, this.state);

    if (!firebase.apps.length) {
      let config = {
        apiKey: "AIzaSyAqANsEPMGHRRHqC3ApoPgFFaMaiQ0bxBQ",
        authDomain: "react-web-app-v1.firebaseapp.com",
        storageBucket: "react-web-app-v1.appspot.com",
      };
      firebase.initializeApp(config);
    }

    let storageRef = firebase.storage().ref();
    var imageRef = storageRef.child(
      "images/" +
        CryptoJS.MD5(copiedStates.formData.email + copiedStates.formData.id) +
        ".jpg"
    );

    let dataURI = copiedStates.formDataToSubmit.profilePic;
    let byteString = atob(dataURI.split(",")[1]);
    let mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    let ab = new ArrayBuffer(byteString.length);
    let ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    let blob = new Blob([ab], { type: mimeString });

    imageRef
      .put(blob)
      .then(function (snapshot) {
        ReactGA.event({
          category: "PersonalInfo",
          action: "Profile pic uploaded",
        });
        callback(snapshot.metadata.fullPath);
      })
      .catch(function () {
        callback(false);
      });
  }
  componentDidUpdate(oldProps, oldState) {
    if (oldState.profilePic !== this.state.profilePic) {
      if (!firebase.apps.length) {
        let config = {
          apiKey: "AIzaSyAqANsEPMGHRRHqC3ApoPgFFaMaiQ0bxBQ",
          authDomain: "react-web-app-v1.firebaseapp.com",
          storageBucket: "react-web-app-v1.appspot.com",
        };
        firebase.initializeApp(config);
      }

      let storageRef = firebase.storage().ref();
      let that = this;
      storageRef
        .child(this.state.profilePic)
        .getDownloadURL()
        .then(function (url) {
          this.state.profilePic = url;
          that.forceUpdate();
        });
    }
  }
  render() {
    if (!this.state) {
      window.location = "signin";
    }
    let { displayNext, displaySubmit } = this.state;
    displayNext = displayNext ? "block" : "none";
    displaySubmit = displaySubmit ? "block" : "none";

    let profilePic = this.state.formData.profilePic;
    let that = this;

    let { classes } = this.props;

    return (
      <div>
        <Snackbar
          id="snackbar-profile"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          onClose={this.closeSnackBar}
          open={this.state.snackBar}
          autoHideDuration={1000}
          SnackbarContentProps={{
            "aria-describedby": "message-id",
          }}
          message={<span id="message-id">{this.state.snackBarMessage}</span>}
        />
        {this.state.preferredExamsNeeded && this.state.exams !== null ? (
          <Dialog
            className="dialog"
            open={this.state.preferredExamsDialogOpen}
            onClose={this.handleExamDialogOpenDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              What are you preparing for?
            </DialogTitle>
            <DialogContent>
              <List dense>
                {Object.keys(this.state.examsDescription).map(function (
                  exam,
                  index
                ) {
                  let className = "examContainer ";
                  if (that.state.exams[exam] === true) {
                    className += "selected";
                  }
                  return (
                    <ListItem
                      key={exam}
                      className={className}
                      button
                      onClick={() => that.handleDeleteExam(exam)}
                    >
                      <Avatar
                        style={{
                          width: 60,
                          height: 60,
                          backgroundColor: "white",
                        }}
                        src={that.state.examsDescription[exam].image}
                      />
                      <ListItemText
                        style={{
                          margin: "auto",
                        }}
                        primary={exam}
                        secondary={
                          that.state.examsDescription[exam].description
                        }
                      />
                    </ListItem>
                  );
                })}
              </List>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={this.handleExamDialogSkip}
                color="primary"
                style={{
                  float: "left",
                }}
              >
                Skip
              </Button>
              <Button
                onClick={this.handleExamDialogOpenDialogClose}
                color="primary"
              >
                Cancel
              </Button>
              <Button
                onClick={this.handleExamDialogOpenDialogSave}
                color="primary"
                variant="contained"
              >
                Save
              </Button>
            </DialogActions>
          </Dialog>
        ) : (
          <div></div>
        )}
        <Dialog
          maxWidth="sm"
          fullWidth
          className="dialog"
          open={this.state.dialogOpen}
          onClose={this.handleDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {displayNext === "block"
              ? "EDIT PROFILE"
              : "WHAT ARE YOU PREPARING FOR?"}
          </DialogTitle>
          <DialogContent>
            <div
              style={{
                textAlign: "center",
              }}
            >
              <div
                style={{
                  display: displayNext,
                }}
              >
                <input
                  type="file"
                  id="imgupload"
                  hidden
                  onChange={(event) => {
                    that.previewProfileImg(
                      event,
                      document.getElementById("imgupload").files[0]
                    );
                  }}
                />
                <div id="clickToUploadDiv">
                  <Avatar
                    className={"profilePic"}
                    id="clickToUploadAvatar"
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      document.getElementById("imgupload").click();
                    }}
                    src={this.state.formDataToSubmit.profilePic}
                  />
                  <div
                    id="clickToUploadText"
                    onClick={() => {
                      document.getElementById("imgupload").click();
                    }}
                  >
                    <CameraAltIcon /> <br />
                    Click to upload
                  </div>
                </div>
                <TextField
                  variant="outlined"
                  label="Full name"
                  margin="normal"
                  name="name"
                  value={this.state.formDataToSubmit.name}
                  onChange={this.handleInputChange}
                  onKeyUp={this.handleInputChange}
                  autoFocus
                  fullWidth
                />

                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                      variant="outlined"
                      id="mobile-number"
                      label="Mobile number"
                      margin="normal"
                      name="mobile"
                      value={this.state.formDataToSubmit.mobile}
                      onChange={this.handleInputChange}
                      onKeyUp={this.handleInputChange}
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                      style={{
                        marginTop: 16,
                      }}
                      id="outlined-select-currency"
                      select
                      fullWidth
                      name="gender"
                      label="Gender"
                      value={
                        this.state.formDataToSubmit.gender
                          ? this.state.formDataToSubmit.gender
                          : "male"
                      }
                      onChange={this.handleInputChange}
                      variant="outlined"
                    >
                      <MenuItem value="male">Male</MenuItem>
                      <MenuItem value="female">Female</MenuItem>
                      <MenuItem value="other">Other</MenuItem>
                    </TextField>
                  </Grid>
                </Grid>
              </div>
              <FormGroup
                style={{
                  display: displaySubmit,
                }}
              >
                <FormControl>
                  <List dense>
                    {Object.keys(this.state.examsDescription).map(function (
                      exam,
                      index
                    ) {
                      let className = "examContainer ";
                      if (that.state.exams[exam] === true) {
                        className += "selected";
                      }
                      return (
                        <ListItem
                          key={exam}
                          className={className}
                          button
                          onClick={() => that.handleDeleteExam(exam)}
                        >
                          <Avatar
                            style={{
                              width: 60,
                              height: 60,
                              backgroundColor: "white",
                            }}
                            src={that.state.examsDescription[exam].image}
                          />
                          <ListItemText
                            style={{
                              margin: "auto",
                            }}
                            primary={exam}
                            secondary={
                              that.state.examsDescription[exam].description
                            }
                          />
                        </ListItem>
                      );
                    })}
                  </List>
                </FormControl>
              </FormGroup>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDialogClose} color="primary">
              Cancel
            </Button>
            <Button
              onClick={this.handleDialogNext}
              style={{
                display: displayNext,
              }}
              color="primary"
              variant="contained"
            >
              Next
            </Button>
            <Button
              style={{
                display: displaySubmit,
              }}
              onClick={this.handleDialogSave}
              color="primary"
              variant="contained"
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
        <Card className="cardWithMargins profileCard">
          <CardHeader
            classes={{
              title: classes.cardTitle,
            }}
            style={{
              textAlign: "left",
            }}
            title="PERSONAL DETAILS"
            subheader={this.state.formData.email}
            action={
              <IconButton
                variant="fab"
                color="primary"
                aria-label="edit"
                onClick={this.editModeToggle}
              >
                <Edit role="button" />
              </IconButton>
            }
          />
          <CardContent>
            <Avatar className={"profilePic"} src={profilePic} />
            <Typography component="div">
              <List>
                <ListItem>
                  <ListItemIcon>
                    <Avatar
                      style={{
                        width: 32,
                        height: 32,
                        fontSize: 20,
                        fontWeight: 700,
                        background: "url(FlatIcons/lightCircle.png)",
                        backgroundSize: "contain",
                        backgroundPosition: "center",
                        color: "#222",
                      }}
                    >
                      {this.state.formData.name
                        ? this.state.formData.name.substring(0, 1).toUpperCase()
                        : ""}
                    </Avatar>
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      this.state.formData.name.length < 30
                        ? this.state.formData.name
                        : this.state.formData.name.substring(0, 30) + "..."
                    }
                  />
                </ListItem>
                <li>
                  <Divider />
                </li>
                <ListItem>
                  <ListItemIcon>
                    <img src="FlatIcons/telephone.png" width="24" />
                  </ListItemIcon>
                  <ListItemText primary={this.state.formData.mobile} />
                </ListItem>
                <li>
                  <Divider />
                </li>
                <ListItem>
                  <ListItemIcon>
                    <img src="FlatIcons/gender.png" width="24" />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      this.state.formData.gender === "male" ? "Male" : "Female"
                    }
                  />
                </ListItem>
              </List>
            </Typography>
          </CardContent>
        </Card>
      </div>
    );
  }
}

export default withStyles(styles)(PersonalInfo);
