import React, { Component } from 'react';
import Avatar from '@material-ui/core/Avatar';
import * as constants from '../config/constants';
import Tags from '../tag/tags';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';
import ShareDialog from '../social/shareDialog';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Edit from '@material-ui/icons/Edit';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import CallIcon from '@material-ui/icons/Call';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import { FormGroup } from 'react-bootstrap';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import axios from 'axios';
import UserProfile from '../Models/UserProfile';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import blue from '@material-ui/core/colors/blue';
import red from '@material-ui/core/colors/red';
import { withStyles } from '@material-ui/core/styles';
import ReactGA from 'react-ga';
import moment from 'moment';
let CryptoJS = require("crypto-js");

const styles = theme => ({
	cardTitle: {
		fontSize: 16,
		fontWeight: 700,
		textTransform: 'uppercase'
	}
});

class JoiningInfo extends Component {
	constructor() {
		super();
		let userData = UserProfile.getUserProfile();
		this.state = {
			userData: userData,
			showShareDialog : false
		};
		this.toggleShareDialogCB = this.toggleShareDialogCB.bind(this);
		ReactGA.initialize('UA-125256246-1', {
			gaOptions: {
				userId: UserProfile.getUserProfile().email
			}
		});
	}
	toggleShareDialogCB() {
		this.setState({
			showShareDialog : !this.state.showShareDialog
		})
	}
	render() {

		let { classes } = this.props;

		let roleName = "Admin";
		switch(this.state.userData.role.name) {
			case "L":
			roleName = "Learner";
			break;
			case "CA":
			roleName = "College Admin";
			break;
			case "P":
			roleName = "Publisher";
			break;
			case "MP":
			roleName = "Master Publisher";
			break;
		}

		let name = UserProfile.getUserProfile() ? UserProfile.getUserProfile().name : "";
		let shareMessage = name + " is inviting you. Join, improve & test your skills";

		return (
			<Card className="cardWithMargins profileCard">

			<ShareDialog
			showShareDialog={this.state.showShareDialog}
			shareUrl={constants.HOST_URL + "?code=" + this.state.inviteCode}
			shareMessage={shareMessage}
			toggleShareDialogCB = {this.toggleShareDialogCB}
			/>

			<CardHeader
			classes={{
				title: classes.cardTitle
			}}
			style={{
				textAlign: 'left'
			}}
			title="APPLICATION DETAILS"
			/>
			<CardContent>
			<Grid container>
			<Grid item md={4} sm={12}>
			<List>
			<ListItem>
			<ListItemText primary={moment(this.state.userData.createdAt).format('LL')} secondary={"Joining Date"} />
			</ListItem>
			</List>
			</Grid>
			<Grid item md={8}>
			<List>
			<ListItem button onClick={() => {this.toggleShareDialogCB()}} >
			<ListItemIcon>
			<img src="FlatIcons/share.png" width="24" />
			</ListItemIcon>
			<ListItemText primary={this.state.userData.inviteCode} secondary={"Invite Code"} />
			</ListItem>
			</List>
			</Grid>
			</Grid>
			</CardContent>
			</Card>
			);
	}
}

export default withStyles(styles)(JoiningInfo);
