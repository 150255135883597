import React, { Component } from "react";
import PropTypes from "prop-types";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import UserProfile from "../Models/UserProfile";
import Tooltip from "@material-ui/core/Tooltip";
import Grid from "@material-ui/core/Grid";
import Options from "./options";
import BookmarkBorderIcon from "@material-ui/icons/BookmarkBorder";
import OfflineBoltIcon from "@material-ui/icons/OfflineBolt";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import ClearIcon from "@material-ui/icons/Clear";
import IconButton from "@material-ui/core/IconButton";
import ReactHtmlParser from "react-html-parser";
import ReactGA from "react-ga";
import { withStyles } from "@material-ui/core/styles";
import hljs from "highlight.js";
import "highlight.js/styles/tomorrow-night-bright.css";

const styles = (theme) => ({
  cardTitle: {
    fontSize: 16,
    fontWeight: 700,
    textTransform: "uppercase",
  },
  paper: {
    borderRadius: 0,
  },
});

class Question extends Component {
  constructor(props) {
    super(props);
    this.state = {
      seqId: this.props.seqId,
      id: this.props.id,
      title: this.props.title,
      description: this.props.description,
      options: this.props.options,
      subTopics: this.props.subTopics,
      tags: this.props.tags,
      selectedOption: this.props.selectedOption,
      correctOptions: this.props.correctOptions
        ? this.props.correctOptions
        : null,
      isCorrect:
        typeof this.props.isCorrect !== "undefined"
          ? this.props.isCorrect
          : false,
      shuffleOptions:
        typeof this.props.shuffleOptions !== "undefined"
          ? this.props.shuffleOptions
          : true,
      answeredQuestion: this.props.answerQuestionCB,
      optionsType: this.props.optionsType,
      beginRecording: false,
      recordings: [],
      stopButtonDisabled: true,
      startButtonDisabled: false,
      timeSpent: this.props.timeSpent,
      isBookmarked: this.props.isBookmarked ? this.props.isBookmarked : false,
      bookmarkCallback: this.props.bookMarkCallback,
      clearOptionCallback: this.props.clearOptionCallback,
      quizLinear: this.props.quizLinear,
      numberOfAttempts: this.props.numberOfAttempts,
      numberOfCorrectAttempts: this.props.numberOfCorrectAttempts,
      isMobile: window.innerWidth <= 500,
      timeToSpend: this.props.timeToSpend,
      optionsReadOnly:
        typeof this.props.optionsReadOnly !== "undefined"
          ? this.props.optionsReadOnly
          : false,
    };
    this.clearOption = this.clearOption.bind(this);
    this.answerCallback = this.answerCallback.bind(this);
    this.handleBookMark = this.handleBookMark.bind(this);
    ReactGA.initialize("UA-125256246-1", {
      gaOptions: {
        userId: UserProfile.getUserProfile().email,
      },
    });
  }
  componentDidMount() {
    console.log(
      "document.querySelectorAll('pre code')",
      document.querySelectorAll("pre code")
    );
    document.querySelectorAll("pre code").forEach((block) => {
      hljs.highlightBlock(block);
    });
  }
  componentWillReceiveProps(nextProps) {
    let copiedStates = Object.assign({}, this.state);
    copiedStates.optionsReadOnly = nextProps.optionsReadOnly;
    copiedStates.selectedOption = nextProps.selectedOption;
    this.setState(copiedStates);
  }
  clearOption() {
    let that = this;
    that.state.clearOptionCallback(that.state.seqId);
  }
  handleBookMark() {
    let copiedStates = Object.assign({}, this.state);
    copiedStates.isBookmarked = !copiedStates.isBookmarked;
    let that = this;
    ReactGA.event({
      category: "Question",
      action: "Bookmark",
    });
    this.setState(copiedStates, () => {
      that.state.bookmarkCallback(this.state.seqId, copiedStates.isBookmarked);
    });
  }
  answerCallback(selectedOption) {
    let copiedStates = Object.assign({}, this.state);
    copiedStates.selectedOption = selectedOption;
    ReactGA.event({
      category: "Question",
      action: "Answered",
    });
    //TODO: Start from here
    this.props.handleOptionSelected();

    this.setState(copiedStates);
    this.state.answeredQuestion(copiedStates);
  }
  render() {
    let { classes } = this.props;

    let alarm =
      this.state.timeToSpend <= this.state.timeSpent / 1000 ? (
        <span
          style={{
            border: "1px dashed red",
            padding: 5,
            fontSize: 12,
            color: "red",
            margin: 3,
          }}
        >
          {" "}
          <OfflineBoltIcon
            style={{
              fontSize: 14,
            }}
          />{" "}
          Spending more time on this question
        </span>
      ) : (
        ""
      );

    if (this.state.isMobile) {
      alarm = "";
    }

    return (
      <div className="question">
        <Card
          style={{
            width: "100%",
          }}
          classes={{
            root: classes.paper,
          }}
        >
          <CardHeader
            classes={{
              title: classes.cardTitle,
              action: "noMarginAction",
            }}
            title={<span>{this.state.title}</span>}
            subheader={alarm}
            style={{ textAlign: "left" }}
            action={
              this.state.quizLinear === false && (
                <div>
                  {this.state.selectedOption.length > 0 && (
                    <Tooltip
                      id="tooltip-icon"
                      title={
                        this.state.selectedOption.length > 0 && "Clear Answer"
                      }
                    >
                      <IconButton onClick={this.clearOption}>
                        <ClearIcon color="secondary" />
                      </IconButton>
                    </Tooltip>
                  )}
                  <Tooltip
                    id="tooltip-icon"
                    title={
                      this.state.isBookmarked
                        ? "Remove Bookmark"
                        : "Add Bookmark"
                    }
                  >
                    <IconButton onClick={this.handleBookMark}>
                      {this.state.isBookmarked ? (
                        <BookmarkIcon color="secondary" />
                      ) : (
                        <BookmarkBorderIcon />
                      )}
                    </IconButton>
                  </Tooltip>
                </div>
              )
            }
          />
          <CardContent>
            <Grid container>
              <Grid
                md={9}
                style={{
                  border: "1px solid #ccc",
                  borderRadius: 0,
                  width: "100%",
                }}
              >
                <div className="quizQuestionDescription">
                  {ReactHtmlParser(this.state.description)}
                </div>
              </Grid>
              <Grid
                md={3}
                style={{
                  width: "100%",
                }}
              >
                <div className="quizQuestionOptions">
                  {this.state.optionsReadOnly ? (
                    <Options
                      options={this.state.options}
                      optionsType={this.state.optionsType}
                      selectedOption={this.state.selectedOption}
                      answerCallback={this.answerCallback}
                      correctOptions={this.state.correctOptions}
                      isCorrect={this.state.isCorrect}
                      questionId={this.state.id}
                      readOnly={true}
                    />
                  ) : (
                    <Options
                      options={this.state.options}
                      shuffleOptions={this.state.shuffleOptions}
                      optionsType={this.state.optionsType}
                      selectedOption={this.state.selectedOption}
                      answerCallback={this.answerCallback}
                      questionId={this.state.id}
                    />
                  )}
                </div>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </div>
    );
  }
}

Question.contextTypes = {
  theme: PropTypes.object,
};

export default withStyles(styles)(Question);
