import React, { PureComponent } from 'react';
import Avatar from '@material-ui/core/Avatar';
import * as constants from '../config/constants';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import axios from 'axios';
import UserProfile from '../Models/UserProfile';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import DoneIcon from '@material-ui/icons/Done';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import blue from '@material-ui/core/colors/blue';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
	cardTitle: {
		fontSize: 16,
		fontWeight: 700,
		textTransform: 'uppercase'
	}
});

class PersonaliseInfo extends PureComponent {
	constructor() {
		super();
		this.state = {
			userId: UserProfile.getUserProfile().id,
			_id: UserProfile.getUserProfile()._id,
			response: null,
			numberOfPurchasedQuizes: 0,
			lessonList: [],
			numberOfNotes: 0,
			numberOfLessonsBookmarked: 0,
			numberOfLessonsFavorite: 0,
			numberOfLessonsLiked: 0,
			numberOfLessonsPersonalized: 0,
			numberOfQuestionsAnswered: 0,
			numberOfQuestionsAnsweredCorrectly: 0,
			anchorEl: null
		};
		this.handleClick = this.handleClick.bind(this);
		this.handleClose = this.handleClose.bind(this);
	}
	handleClick = event => {
		this.setState({ anchorEl: event.currentTarget });
	};
	handleClose = event => {
		this.setState({ anchorEl: null });
	};
	componentDidMount() {
		let that = this;
		axios.get(constants.API_BASE_URL + '/attempts/users/' + this.state._id + '/aggregate/count')
		.then(function(response) {
			let copiedStates = Object.assign({}, that.state);
			copiedStates.numberOfQuestionsAnswered = response.data.nOfAttempts;
			copiedStates.numberOfQuestionsAnsweredCorrectly = response.data.nOfCorrectAttempts;
			that.setState(copiedStates);
		});
	}
	render() {

		let { classes } = this.props;

		return (
			<div>
			<Card className="cardWithMargins profileCard">
			<CardHeader
			classes={{
				title: classes.cardTitle
			}}
			style={{
				textAlign: 'left'
			}}
			title="Progress"
			subheader="Your recent activities"
			/>
			<CardContent>
			<List>
			<ListItem>
			<ListItemIcon>
			<img src="FlatIcons/check-mark.png" width="24" />
			</ListItemIcon>
			<ListItemText primary={<div><strong style={{
				fontSize: 18
			}}>{this.state.numberOfQuestionsAnsweredCorrectly}</strong> correct answers</div>} />
			</ListItem>

			<li>
			<Divider />
			</li>

			<ListItem>
			<ListItemIcon>
			<img src="FlatIcons/faq.png" width="24" />
			</ListItemIcon>
			<ListItemText primary={<div><strong style={{
				fontSize: 18
			}}>{this.state.numberOfQuestionsAnswered}</strong> questions answered</div>} />
			</ListItem>

			<li>
			<Divider />
			</li>

			<ListItem>
			<ListItemIcon>
			<img src="FlatIcons/percentage.png" width="24" />
			</ListItemIcon>
			<ListItemText primary={<div><strong style={{
				fontSize: 18
			}}>{((this.state.numberOfQuestionsAnswered > 0) ? Math.round((this.state.numberOfQuestionsAnsweredCorrectly / this.state.numberOfQuestionsAnswered)*100) : 0)}</strong> percentage scored</div>} />
			</ListItem>

			</List>

			</CardContent>
			</Card>
			</div>
			);
	}
}

export default withStyles(styles)(PersonaliseInfo);