import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import UserProfile from '../Models/UserProfile';
import * as constants from '../config/constants';
import Lesson from './lesson';
import LessonWithQuiz from './lessonWithQuiz';
import LessonMenu from './lessonMenu';
import IndexedDB from '../indexdb';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import CircularProgress from '@material-ui/core/CircularProgress';
import ReactGA from 'react-ga';

const drawerWidth = 240;

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: window.innerHeight - 70,
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    paddingLeft: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 3,
    minWidth: 0,
    width: 100% - drawerWidth
  },
  tocIcon: {
    backgroundColor: 'white',
    position: 'absolute',
    top: '20px',
    padding: '0',
    width: '15px',
    minWidth: '15px',
    maxWidth: '15px'
  }
});

class LessonList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      listOfLessons: [],
      drawerMenu: [],
      openMenus: {},
      open: false,
      openTOC: true,
      category: null,
      topic: null,
      subTopic: null,
      searchTerm: '',
      currentLesson: {},
      prevMenuItem: null,
      prevInnerKey: null,
      hideMenu: false,
      userClicked: false,
      noteOpened: false,
      isMobile: window.innerWidth <= 500
    };
    this.componentDidMount = this.componentDidMount.bind(this);
    this.openTOCToggle = this.openTOCToggle.bind(this);
    this.retrieveAllCallBack = this.retrieveAllCallBack.bind(this);
    this.subTopicClicked = this.subTopicClicked.bind(this);
    this.navigationCB = this.navigationCB.bind(this);
    this.notesCallBack = this.notesCallBack.bind(this);
    this.notesEdited = this.notesEdited.bind(this);
    this.handleLessonPersonalisationCB = this.handleLessonPersonalisationCB.bind(this);
    if(typeof(this.props.match.params) !== 'undefined' && Object.keys(this.props.match.params).length === 3) {
      [this.state.category, this.state.topic, this.state.subTopic] = [
      this.props.match.params.category,
      this.props.match.params.topic,
      this.props.match.params.subTopic]
    }
    ReactGA.initialize('UA-125256246-1', {
      gaOptions: {
        userId: UserProfile.getUserProfile().email
      }
    });
  }
  handleLessonPersonalisationCB(lesson) {
    let copiedStates = Object.assign({}, this.state);
    if(copiedStates.currentLesson.lessonId === lesson.lessonId) {
      copiedStates.currentLesson.isBookmarked = lesson.isBookmarked;
      copiedStates.currentLesson.isFavorite = lesson.isFavorite;
      copiedStates.currentLesson.isLiked = lesson.isLiked;
    }
    this.setState(copiedStates);
  }
  notesEdited(lesson){
    let copiedStates = Object.assign({}, this.state);
    copiedStates.currentLesson.notes = lesson.notes;
    ReactGA.event({
      category: 'Lesson',
      action: 'Notes Edited'
    });
    this.setState(copiedStates);
  }
  notesCallBack(){
    ReactGA.event({
      category: 'Lesson',
      action: 'Notes Clicked'
    });
    this.setState({
      noteOpened: !this.state.noteOpened
    })
  }
  retrieveAllCallBack(lessons) {
    let copiedStates = Object.assign({}, this.state);
    let chapters = {};
    let openMenus = {};
    for (let i = 0; i < lessons.length; i++) {
      if(lessons[i].category in chapters) {
        (lessons[i].topic in chapters[lessons[i].category]) ? chapters[lessons[i].category][lessons[i].topic].push(lessons[i].subTopic) : (chapters[lessons[i].category][lessons[i].topic] = [lessons[i].subTopic]);
      }
      else {
        chapters[lessons[i].category] = {
          [lessons[i].topic] : [lessons[i].subTopic]
        };
      }
    }
    for(let key in chapters) {
      openMenus[key] = {
        currentCategoryOpened: false
      };
      for(let innerKey in chapters[key]) {
        openMenus[key][innerKey] = false;
      }
    }
    copiedStates.listOfLessons = lessons;
    copiedStates.openMenus = openMenus;
    copiedStates.chapters = chapters;
    this.setState(copiedStates);
  }
  openTOCToggle(userClicked, event) {
    if(document.getElementById("LessonCardHeader")) {
      let copiedStates = Object.assign({}, this.state);
      copiedStates.openTOC = !copiedStates.openTOC;
      copiedStates.userClicked = userClicked;
      copiedStates.hideMenu = !copiedStates.hideMenu;
      document.getElementById("LessonCardHeader").classList.remove("stickyCardHeader");
      ReactGA.event({
        category: 'Lesson',
        action: 'Opened Menu'
      });
      this.setState(copiedStates);
    }
  }
  subTopicClicked(category, topic, subTopic, pushHistory = true) {
    let copiedStates = Object.assign({}, this.state);
    let that = this;
    copiedStates.loading = true;
    copiedStates.noDataFound = false;
    copiedStates.currentLesson = {};
    copiedStates.category = category;
    copiedStates.topic = topic;
    copiedStates.subTopic = subTopic;
    ReactGA.event({
      category: 'Lesson',
      action: 'Lesson Clicked',
      label: category + "/" + topic + "/" + subTopic
    });
    axios.get( constants.API_BASE_URL + '/lessons/search/' + category + '/' + topic + '/' + subTopic + '/' + UserProfile.getUserProfile().id)
    .then(function (response) {
      if(response.data.length !== 1) {
        copiedStates.noDataFound = true;
        copiedStates.loading = false;
      } else {
        copiedStates.loading = false;
        copiedStates.currentLesson = response.data[0];
        pushHistory && window.history.pushState({"html": "Hello","pageTitle": "pageTitle"},"", constants.HOST_URL + "/lessons/" + category + '/' + topic + '/' + subTopic);
      }
      that.setState(copiedStates);
    })
    .catch(function (error) {
      copiedStates.loading = false;
      copiedStates.noDataFound = true;
      that.setState(copiedStates);
    });
    if(this.state.isMobile || !this.state.userClicked) {
      copiedStates.openTOC = !copiedStates.openTOC;
      copiedStates.hideMenu = true;
    }
    this.setState(copiedStates);
  }
  componentDidMount(){
    var that = this;
    let copiedStates = Object.assign({}, this.state);
    copiedStates.noChaptersFound = false;

    window.onpopstate = function(event) {
      let pathnames = document.location.pathname.split('/');
      let subTopic, topic, category;
      [subTopic, topic, category] = [decodeURIComponent(pathnames.pop()), decodeURIComponent(pathnames.pop()), decodeURIComponent(pathnames.pop())];
      if(subTopic && topic && category) {
        that.subTopicClicked(category, topic, subTopic, false);
      }
      else {
        copiedStates.currentLesson = {};
        copiedStates.hideMenu = false;
        that.setState(copiedStates);
      }
    };
    axios.get(constants.API_BASE_URL + '/lessons/categories/all')
    .then(function (response) {
      var chapters = response.data.details;
      var openMenus = {};

      for(var key in chapters) {
        openMenus[key] = {};
        openMenus[key]['currentCategoryOpened'] = false;
        for(var innerKey in chapters[key]) {
          openMenus[key][innerKey] = false;
        }
      }
      copiedStates.openMenus = openMenus;
      copiedStates.chapters = chapters;
      that.setState(copiedStates);
      if(that.state.category !== null && that.state.topic !== null && that.state.subTopic !== null) {
        that.subTopicClicked(that.state.category, that.state.topic, that.state.subTopic);
      }
      IndexedDB.addition();
    })
    .catch(function (error) {
      that.setState(copiedStates);
      // IndexedDB.retrieveAll(that.retrieveAllCallBack);
    });
    this.setState(copiedStates);
  }
  navigationCB(action = "next") {
    let currentCategory = this.state.category;
    let currentTopic = this.state.topic;
    let currentSubTopic = this.state.subTopic;
    let currentCategoryIndex;
    let currentTopicIndex;
    let currentTopicIndexInCategory;
    let currentIndex;
    let chapters = this.state.chapters;
    let nextCategory = null;
    let nextTopic = null;
    let nextSubTopic = null;
    let listOfCategories = Object.keys(chapters);
    let listOfTopics = [];
    for (let key in chapters) {
      listOfTopics = listOfTopics.concat(Object.keys(chapters[key]));
    }

    currentCategoryIndex = 0;

    for (let key in chapters) {
      if (key === currentCategory) {
        nextCategory = key;
        currentCategoryIndex = listOfCategories.indexOf(key);
        for (let innerKey in chapters[key]) {
          if (innerKey === currentTopic) {

            nextTopic = innerKey;
            currentTopicIndex = listOfTopics.indexOf(innerKey);
            currentTopicIndexInCategory = Object.keys(chapters[key]).indexOf(innerKey);
            currentIndex = chapters[key][innerKey].indexOf(currentSubTopic);
            if (currentIndex > -1) {
              if(action === "next") {
                if (currentIndex < chapters[key][innerKey].length -1) {
                  nextSubTopic = chapters[key][innerKey][currentIndex + 1];
                } else {
                  if(currentTopicIndexInCategory < Object.keys(chapters[key]).length -1) {
                    nextTopic = listOfTopics[currentTopicIndex + 1];
                    nextSubTopic = chapters[key][listOfTopics[currentTopicIndex + 1]][0];
                  }
                  else {
                    if (currentCategoryIndex < listOfCategories.length - 1) {
                      nextCategory = listOfCategories[currentCategoryIndex+1];
                      nextTopic = Object.keys(chapters[nextCategory])[0];
                      nextSubTopic = chapters[listOfCategories[currentCategoryIndex+1]][Object.keys(chapters[listOfCategories[currentCategoryIndex+1]])[0]][0];
                    }
                    else {
                      alert('This is your last lesson');
                      return;
                    }
                  }
                }
              }
              else if(action === "prev") {
                if (currentIndex > 0) {
                  nextSubTopic = chapters[key][innerKey][currentIndex - 1];
                } else {
                  if (currentTopicIndexInCategory > 0) {
                    nextTopic = listOfTopics[currentTopicIndex - 1];
                    nextSubTopic = chapters[key][nextTopic][chapters[key][nextTopic].length-1];
                  } else {
                    if (currentCategoryIndex > 0) {
                      nextCategory = listOfCategories[currentCategoryIndex - 1];
                      nextTopic = Object.keys(chapters[nextCategory])[Object.keys(chapters[nextCategory]).length-1];
                      nextSubTopic =
                      chapters[listOfCategories[currentCategoryIndex - 1]][nextTopic][chapters[listOfCategories[currentCategoryIndex - 1]][nextTopic].length-1];
                    }
                    else {
                      alert('This is your first lesson');
                      return;
                    }
                  }
                }
              }
              break;
            }
          }
        }
      }
    }
    this.subTopicClicked(nextCategory, nextTopic, nextSubTopic);
  }
  render() {
    const { classes } = this.props;
    let lessonDisplay = (<div></div>);
    let tocIconPosition = 0;
    if(!this.state.hideMenu)
      tocIconPosition  = '325px';

    if(this.state.noDataFound) {
      lessonDisplay = (<Typography className="nodatafound" >--no data found--</Typography>);
    } else if(this.state.loading) {
      lessonDisplay = (<Typography className="circularProgress" component="div"><CircularProgress thickness={4} size={50} color="primary" /></Typography>);
    }
    else if(typeof this.state.currentLesson.title !== 'undefined') {
      if(this.state.currentLesson.quizId) {
        lessonDisplay = (
          <LessonWithQuiz
          tocCB={this.openTOCToggle}
          handleLessonPersonalisationCB={this.handleLessonPersonalisationCB}
          notesEdited={this.notesEdited}
          noSticky={this.state.openTOC}
          noteOpened={this.state.noteOpened}
          menuOpened={!this.state.hideMenu}
          notesCallBack={this.notesCallBack}
          key={this.state.currentLesson.lessonId}
          notes={this.state.currentLesson.notes}
          lessonId={this.state.currentLesson.lessonId}
          isFavorite={this.state.currentLesson.isFavorite}
          isLiked={this.state.currentLesson.isLiked}
          isBookmarked={this.state.currentLesson.isBookmarked}
          category={this.state.currentLesson.category}
          topic={this.state.currentLesson.topic}
          subTopic={this.state.currentLesson.subTopic}
          readingTime={this.state.currentLesson.readingTime}
          description={this.state.currentLesson.description}
          title={this.state.currentLesson.title}
          quiz={this.state.currentLesson.quizId}
          nextCB={() => this.navigationCB("next")}
          prevCB={() => this.navigationCB("prev")}
          />
          );
      }
      else {
        lessonDisplay = (
          <Lesson
          loading={this.state.loading}
          noDataFound={this.state.noDataFound}
          noSticky={this.state.openTOC}
          menuOpened={!this.state.hideMenu}
          handleLessonPersonalisationCB={this.handleLessonPersonalisationCB}
          notesEdited={this.state.notesEdited}
          readingTime={this.state.currentLesson.readingTime}
          noteOpened={this.state.noteOpened}
          notesCallBack={this.notesCallBack}
          isFavorite={this.state.currentLesson.isFavorite}
          isLiked={this.state.currentLesson.isLiked}
          key={this.state.currentLesson.lessonId}
          notes={this.state.currentLesson.notes}
          lessonId={this.state.currentLesson.lessonId}
          isBookmarked={this.state.currentLesson.isBookmarked}
          category={this.state.currentLesson.category}
          topic={this.state.currentLesson.topic}
          subTopic={this.state.currentLesson.subTopic}
          description={this.state.currentLesson.description}
          title={this.state.currentLesson.title}
          nextCB={() => this.navigationCB("next")}
          prevCB={() => this.navigationCB("prev")}
          />
          ); 
      }
    }
    let mainWidth = '100%';
    if(this.state.noteOpened) {
      if(this.state.isMobile) {
        mainWidth = 0;
      }
      else if(!this.state.hideMenu) {
        mainWidth = 'calc(75% - 325px)';
      }
      else {
        mainWidth = '75%';
      }
    }
    return (
      <div>
      <div
      className={classes.root}>
      <LessonMenu
      chapters={this.state.chapters}
      openMenus={this.state.openMenus}
      category={this.state.category}
      topic={this.state.topic}
      subTopic={this.state.subTopic}
      subTopicClickedCB={this.subTopicClicked}
      visible={!this.state.hideMenu} />
      <main id={"MainLessonView"} className={classes.content} style={{
        overflowY: 'auto',
        width: mainWidth,
        maxWidth: mainWidth
      }}>
      {lessonDisplay}
      </main>
      {!this.state.isMobile && (
        <Button
        disableFocusRipple
        disableRipple
        variant="contained"
        mini
        className={classes.tocIcon}
        style={{
          left: tocIconPosition
        }}
        size="small"
        onClick={(event) => this.openTOCToggle(true, event)} >
        {(this.state.hideMenu) ? <KeyboardArrowRightIcon /> : <KeyboardArrowLeftIcon />}
        </Button>
        )}
      </div>
      </div>
      );

  }
}

LessonList.propTypes = {
  classes: PropTypes.object.isRequired,
  // theme: PropTypes.object.isRequired,
};

export default withStyles(styles)(LessonList);
