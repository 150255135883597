import * as constants from "../config/constants";
import React, { Component } from "react";
import UserProfile from "../Models/UserProfile";
import Timer from "react-timer-wrapper";
import Timecode from "react-timecode";
import MyTimer from "./myTimer";

class QuizTimer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      time: this.props.time * 1000,
      duration: this.props.time * 1000,
      onTimerFinish: this.props.onTimerFinish,
    };
    this.onTimerTimeUpdate = this.onTimerTimeUpdate.bind(this);
    this.onTimerFinish = this.onTimerFinish.bind(this);
  }
  onTimerFinish = () => {
    this.state.onTimerFinish();
  };
  onTimerTimeUpdate = ({ duration, progress, time }) => {};
  render() {
    return (
      <div>
        <Timer
          active={true}
          onFinish={this.onTimerFinish}
          duration={this.state.duration + 3}
          loop={false}
          onTimeUpdate={this.onTimerTimeUpdate}
          onStart={this.onTimerStart}
        >
          <MyTimer />
        </Timer>
      </div>
    );
  }
}

export default QuizTimer;
