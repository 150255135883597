import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import * as constants from '../config/constants';

const styles = theme => ({
	appBar: {
		position: 'relative',
	},
	icon: {
		marginRight: theme.spacing.unit * 2,
	},
	heroUnit: {
		backgroundColor: theme.palette.background.paper,
	},
	heroContent: {
		maxWidth: 600,
		margin: '0 auto',
		padding: `${theme.spacing.unit * 8}px 0 ${theme.spacing.unit * 6}px`,
	},
	heroButtons: {
		marginTop: theme.spacing.unit * 4,
	},
	layout: {
		width: 'auto',
		marginLeft: theme.spacing.unit * 3,
		marginRight: theme.spacing.unit * 3,
		[theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
			width: 1100,
			marginLeft: 'auto',
			marginRight: 'auto',
		},
	},
	cardGrid: {
		padding: `${theme.spacing.unit * 8}px 0`,
	},
	card: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
	},
	cardMedia: {
		paddingTop: '56.25%', // 16:9
	},
	cardContent: {
		flexGrow: 1,
	},
	footer: {
		backgroundColor: theme.palette.background.paper,
		padding: theme.spacing.unit * 6,
	},
});

const linkStyle = {
	textDecoration: 'none',
	color: 'unset',
	border: 'none',
	outline: 'none'
};

function Album(props) {
	const { classes } = props;

	return (
		<React.Fragment>
			<CssBaseline />
			<main>
				<div className={classes.heroUnit}>
					<div className={classes.heroContent}>
						<Typography className="albumTitleName">
							{constants.PROD_NAME}
						</Typography>
						<Typography variant="title" align="center" color="textSecondary" paragraph>
							Something short and leading about the collection below—its contents, the creator, etc.
							Make it short and sweet, but not too short so folks don&apos;t simply skip over it
							entirely.
						</Typography>
						<div className={classes.heroButtons}>
							<Grid container spacing={16} justifyContent="center">
								<Grid item>
									<Link to="/signin/register" style={linkStyle}>
										<Button variant="contained" color="primary">
											Register
										</Button>
									</Link>
								</Grid>
								<Grid item>
									<Link to="/signin/login" style={linkStyle}>
										<Button variant="outlined" color="secondary">
											Login
										</Button>
									</Link>
								</Grid>
							</Grid>
						</div>
					</div>
				</div>
			</main>
		</React.Fragment>
	);
}

Album.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Album);