import * as constants from '../config/constants';
import axios from 'axios';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { Component } from 'react';
import {Row, Col} from 'reactstrap';
import RankingCategory from './rankingCategory';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Avatar from '@material-ui/core/Avatar';
import Badge from '@material-ui/core/Badge';
import UserProfile from '../Models/UserProfile';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import SwipeableViews from 'react-swipeable-views';
import SubjectIcon from '@material-ui/icons/Subject';
import VerticalAlignTopIcon from '@material-ui/icons/VerticalAlignTop';
import HourglassFullIcon from '@material-ui/icons/HourglassFull';
import TodayIcon from '@material-ui/icons/Today';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = theme => ({
	card: {
		maxWidth: 400,
	},
	media: {
		height: 0,
		paddingTop: '56.25%',
	},
	actions: {
		display: 'flex',
	},
	expand: {
		transform: 'rotate(0deg)',
		transition: theme.transitions.create('transform', {
			duration: theme.transitions.duration.shortest,
		}),
		marginLeft: 'auto',
	},
	expandOpen: {
		transform: 'rotate(180deg)',
	},
	secondaryHeading: {
		fontSize: theme.typography.pxToRem(15),
		color: theme.palette.text.secondary,
	},
	heading: {
		fontSize: theme.typography.pxToRem(15),
		flexBasis: '33.33%',
		flexShrink: 0,
		align: 'left'
	},
	noPadding: {
		padding: 0
	}
});

class RankingList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: {},
			isMobile: window.innerWidth <= 500,
			tab: 0,
			expanded: false,
			noDataFound: false
		}
		this.handleChangeIndex = this.handleChangeIndex.bind(this);
		this.handleChange = this.handleChange.bind(this);
	}
	handleChange = (event, value) => {
		this.setState({ tab: value });
	};
	handleChangeIndex = index => {
		this.setState({ tab: index });
	};
	componentDidMount() {
		let that = this;
		let copiedStates = Object.assign({}, this.state);
		axios.get(constants.API_BASE_URL + '/ranking/users/' + UserProfile.getUserProfile().id)
		.then(function (response) {
			if(typeof(response.data.data) === "undefined") {
				copiedStates.noDataFound = true;
			}
			else {
				copiedStates.data = response.data.data;
			}
			that.setState(copiedStates);
		})
		.catch(function (error) {
			copiedStates.noDataFound = true;
			that.setState(copiedStates);
		});
	}
	render() {
		let subTopicWiseUsersScore = this.state.data.subTopicWiseUsersScore;
		let quizWiseUsersScore = this.state.data.quizWiseUsersScore;
		let dayWiseUsersScore = this.state.data.dayWiseUsersScore;
		let overallUsersScore = this.state.data.overallUsersScore;
		let {classes} = this.props;
		let rankingCategory = [];
		rankingCategory.push(
			<RankingCategory
			keyToDisplay="Topic-wise"
			data={subTopicWiseUsersScore}
			/>);
		rankingCategory.push(
			<RankingCategory
			keyToDisplay="Quiz Wise"
			data={quizWiseUsersScore}
			/>);
		rankingCategory.push(
			<RankingCategory
			keyToDisplay="Day-wise"
			data={dayWiseUsersScore}
			/>);

		let individualUserArray = [];
		let avatar;
		let position = 0;
		let youCompleted = -1;
		for(let key in overallUsersScore) {
			if(UserProfile.getUserProfile()._id === overallUsersScore[key].userDetails.id) {
				youCompleted = position;
				avatar = (
					<Badge badgeContent={"You"} color="secondary">
					<Avatar>
					{position + 1}
					</Avatar>
					</Badge>
					);
			}
			else {
				avatar = (
					<Avatar>
					{position + 1}
					</Avatar>
					);
			}

			individualUserArray.push(
				<div style={{
					paddingTop: position * 50,
					width: '100%',
					textAlign: 'center',
					justifyContent: 'center',
					backgroundColor: 'white'
				}}>
				<ListItem style={{
					justifyContent: 'center'
				}}>
				{avatar}
				</ListItem>
				<ListItem style={{
					textAlign: 'center'
				}}>
				<ListItemText
				classes={{
					root: classes.noPadding
				}}
				primary={overallUsersScore[key].userDetails.name}
				secondary={Math.round(overallUsersScore[key].percentage * 100, 2) + "%"} />
				</ListItem>
				</div>
				);
			position++;
		}
		
		if(this.state.isMobile === true) {
			let toRender = '';
			if(individualUserArray.length === 0) {
				toRender = (this.state.noDataFound) ? (
					<div className="containerWithMargin" style={{
						height: '100vh'
					}} >
					<Typography className="nodatafound" >
					--no data found--
					</Typography>
					</div>
					) : (
					<div className="containerWithMargin" style={{
						height: '100vh'
					}} >
					<Typography className="circularProgress" component="div">
					<CircularProgress thickness={4} size={50} color="default" />
					</Typography>
					</div>
					);
				}
				else {
					toRender = (
						<div className="containerWithMargin">
						<SwipeableViews
						index={this.state.tab}
						onChangeIndex={this.handleChangeIndex}
						>
						{(individualUserArray.length > 0) && (
							<Card className="RankingCategoryCard cardWithMargins">
							<CardHeader
							title={"Top performers"}
							/>
							<CardContent><List>{individualUserArray}</List></CardContent>
							</Card>
							)}				
						{rankingCategory[0]}
						{rankingCategory[1]}
						{rankingCategory[2]}

						</SwipeableViews>

						<br />
						<br />
						<BottomNavigation
						style={{
							position: 'fixed',
							width: '100%',
							left: 0,
							bottom: 0,
							borderTop: '1px solid rgba(0, 0, 0, 0.12)'
						}}
						value={this.state.tab}
						onChange={this.handleChange}
						showLabels
						>
						<BottomNavigationAction
						label="Top" icon={<VerticalAlignTopIcon />} />
						<BottomNavigationAction
						color="primary"
						label="Topic"
						icon={<SubjectIcon />} />
						<BottomNavigationAction
						label="Quiz"
						icon={<HourglassFullIcon />} />
						<BottomNavigationAction
						label="Day"
						icon={<TodayIcon />} />
						</BottomNavigation>
						</div>
						);
				}
				return (
					toRender
					);
			}
			else {
				return (
					<div className="containerWithMargin">
					{(individualUserArray.length > 0) ? (
						<Card className="RankingCategoryCard cardWithMargins">
						<CardHeader
						title={"Top performers"}
						/>
						<CardContent>
						<List>
						<Row>
						<Col className="individualUserArray" md={(youCompleted <= 2) ? 4 : 3}>
						{individualUserArray[1]}
						</Col>
						<Col className="individualUserArray" md={(youCompleted <= 2) ? 4 : 3}>
						{individualUserArray[0]}
						</Col>
						<Col className="individualUserArray" md={(youCompleted <= 2) ? 4 : 3}>
						{individualUserArray[2]}
						</Col>
						{((youCompleted > 2)) && 
							(<Col className="individualUserArrayVert" md={3}>
								{individualUserArray[3]}
								</Col>)
						}
						</Row>
						</List>
						</CardContent>
						</Card>
						) : ((this.state.noDataFound) ? (<Typography className="nodatafound" >--no data found--</Typography>) : 
						(<Typography className="circularProgress" component="div"><CircularProgress thickness={4} size={50} color="default" /></Typography>)
						)}
						<Row>
						<Col md={4}>
						{rankingCategory[0]}
						</Col>
						<Col md={4}>
						{rankingCategory[1]}
						</Col>
						<Col md={4}>
						{rankingCategory[2]}
						</Col>
						</Row>
						</div>);
			}
		}
	}

	RankingList.propTypes = {
		classes: PropTypes.object.isRequired,
	};

	export default withStyles(styles)(RankingList);
