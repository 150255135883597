import React, { Component } from "react";
import PropTypes from "prop-types";
import * as constants from "../../config/constants";
import FormControl from "@material-ui/core/FormControl";
import Typography from "@material-ui/core/Typography";
import Select from "react-select";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import UserProfile from "../../Models/UserProfile";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Snackbar from "@material-ui/core/Snackbar";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import axios from "axios";
import ReactGA from "react-ga";
import { Select as MUISelect } from "@material-ui/core";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    height: 250,
  },
  input: {
    display: "flex",
  },
  valueContainer: {
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    alignItems: "center",
  },
  chip: {
    margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`,
  },
  noOptionsMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
  },
  singleValue: {
    fontSize: 16,
    marginBottom: 8,
  },
  placeholder: {
    position: "absolute",
    left: 2,
    fontSize: 16,
  },
  paper: {
    marginTop: theme.spacing.unit,
  },
  divider: {
    height: theme.spacing.unit * 2,
  },
  cardTitle: {
    fontSize: 16,
    fontWeight: 700,
    textTransform: "uppercase",
  },
  muiSelect: {
    width: "100%",
  },
});

function NoOptionsMessage(props) {
  return (
    <Typography
      component="div"
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Control(props) {
  return (
    <TextField
      id="institutionNameTextField"
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
      }}
      {...props.selectProps.textFieldProps}
    />
  );
}

function Option(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

function Placeholder(props) {
  return (
    <Typography
      component="div"
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function SingleValue(props) {
  return (
    <Typography
      component="div"
      className={props.selectProps.classes.singleValue}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function ValueContainer(props) {
  return (
    <div className={props.selectProps.classes.valueContainer}>
      {props.children}
    </div>
  );
}

function Menu(props) {
  return (
    <Paper
      square
      className={props.selectProps.classes.paper}
      {...props.innerProps}
      style={{ overflowY: "scroll" }}
    >
      <div>{props.children}</div>
    </Paper>
  );
}

let timeOut = [];

const components = {
  Option,
  Control,
  NoOptionsMessage,
  Placeholder,
  SingleValue,
  ValueContainer,
  Menu,
};

const listOfDegrees = [
  { label: "BE" },
  { label: "BTech" },
  { label: "MTech" },
  { label: "ME" },
  { label: "Mcom" },
  { label: "MBA" },
  { label: "MCA" },
  { label: "BCA" },
  { label: "Bsc" },
  { label: "BBA" },
  { label: "Bcom" },
  { label: "Msc" },
  { label: "BArch" },
  { label: "BA" },
  { label: "BAMS" },
  { label: "BDes / Bd" },
  { label: "BEd" },
  { label: "BFa / Bvallb" },
  { label: "BLib / BLi" },
  { label: "BScmA" },
  { label: "MPhil" },
  { label: "PhD" },
].map((degree) => ({
  value: degree.label,
  label: degree.label,
}));

const listOfBranches = [
  { label: "Automobile" },
  { label: "Aerospace" },
  { label: "Agricultural and Food" },
  { label: "Artificial Intelligence" },
  { label: "Agriculture" },
  { label: "Aeronautical" },
  { label: "Biochemical" },
  { label: "Biotechnology" },
  { label: "Biomedical" },
  { label: "Biotechnology" },
  { label: "Biomedical" },
  { label: "Ceramic" },
  { label: "Civil" },
  { label: "Computer Science" },
  { label: "Chemical" },
  { label: "Ceramic and Cement Technology" },
  { label: "Construction" },
  { label: "Electronics and Communication" },
  { label: "Electronics and Telecommunication" },
  { label: "Electrical and Electronics" },
  { label: "Electronics and Instrumentation" },
  { label: "Food Processing technology" },
  { label: "Food Technology / Processing" },
  { label: "Food Tech" },
  { label: "Finance" },
  { label: "Genetic" },
  { label: "Human Resource" },
  { label: "Instrumentation and Control" },
  { label: "Information Technology" },
  { label: "Industrial" },
  { label: "International Business" },
  { label: "Industrial and Production" },
  { label: "Information Science" },
  { label: "Mechatronics" },
  { label: "Mining" },
  { label: "Mechanical" },
  { label: "Marine" },
  { label: "Merchant Navy" },
  { label: "Metallurgical" },
  { label: "Media Management" },
  { label: "Mechanical" },
  { label: "Marketing" },
  { label: "Multimedia & Creative Technology" },
  { label: "Operations Management" },
  { label: "Production" },
  { label: "Petroleum" },
  { label: "Plastic and Polymer" },
  { label: "Physics" },
  { label: "Robotics" },
  { label: "Software" },
  { label: "Textile" },
  { label: "Visual Communication" },
].map((degree) => ({
  value: degree.label,
  label: degree.label,
}));

const listOfDivisions = [
  { label: "A" },
  { label: "B" },
  { label: "C" },
  { label: "D" },
  { label: "E" },
  { label: "F" },
  { label: "G" },
  { label: "H" },
  { label: "I" },
  { label: "J" },
  { label: "K" },
  { label: "L" },
].map((degree) => ({
  value: degree.label,
  label: degree.label,
}));

const listOfSemesters = [
  { label: "1" },
  { label: "2" },
  { label: "3" },
  { label: "4" },
  { label: "5" },
  { label: "6" },
  { label: "7" },
  { label: "8" },
].map((sem) => ({
  value: sem.label,
  label: sem.label,
}));

class EducationDetailsDialog extends Component {
  constructor(props) {
    super(props);

    let institution = {
      label: props.userData.institutionName,
      value: props.userData.institutionName,
    };
    const userData = Object.assign({}, props.userData);

    this.state = {
      formDataToSubmit: userData,
      snackBar: false,
      snackBarMessage: "",
      error: {},
      listOfColleges: [],
      institutionName: institution,
      previousInstitutionName: "",
    };
    this.editModeToggle = this.editModeToggle.bind(this);
    // this.editModeEducationToggle = this.editModeEducationToggle.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleDialogSave = this.handleDialogSave.bind(this);
    // this.handleEducationalDialogClose = this.handleEducationalDialogClose.bind(
    //   this
    // );
    this.handleChange = this.handleChange.bind(this);
    this.closeSnackBar = this.closeSnackBar.bind(this);
    this.validate = this.validate.bind(this);
    this.onChangeDate = this.onChangeDate.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleUserDataChange = this.handleUserDataChange.bind(this);

    ReactGA.initialize("UA-125256246-1", {
      gaOptions: {
        userId: UserProfile.getUserProfile().email,
      },
    });
  }

  handleUserDataChange = (name, value) => {
    let copiedStates = Object.assign({}, this.state);
    copiedStates.formDataToSubmit[name] = value.value;
    this.setState(copiedStates);
  };
  onChangeDate = (date: Date) => {
    let copiedStates = Object.assign({}, this.state);
    copiedStates.formDataToSubmit.passoutYear = date;
    this.setState(copiedStates);
  };
  handleKeyDown = (event) => {
    let copiedStates = Object.assign({}, this.state);
    let target = event.target;
    let that = this;

    if (event.keyCode === 8) {
      copiedStates.institutionName = "";
      copiedStates.formDataToSubmit.institutionName = "";
      this.setState(copiedStates);
      return;
    }

    let singleTimeOut = setTimeout(() => {
      if (copiedStates.previousInstitutionName !== target.value) {
        timeOut.forEach((t) => {
          clearTimeout(t);
        });
        if (target.value.length < 3) {
          this.setState({ ...that, listOfColleges: [] });
          return;
        }
        copiedStates.previousInstitutionName = target.value;
        axios
          .get(constants.API_BASE_URL + "/users/searchCollege/" + target.value)
          .then(function (response) {
            let listOfColleges = response.data.details.map((college) => ({
              value: college,
              label: college,
            }));
            copiedStates.listOfColleges = listOfColleges;
            that.setState(copiedStates);
            ReactGA.event({
              category: "EducationInfo",
              action: "Searching College",
            });
            return false;
          })
          .catch(function (error) {});
        that.setState(copiedStates);
      }

      that.setState(copiedStates);
    }, 1000);

    timeOut.push(singleTimeOut);
    this.setState(copiedStates);
  };
  handleChange = (name, value) => {
    let copiedStates = Object.assign({}, this.state);
    copiedStates.institutionName = value;
    copiedStates.formDataToSubmit.institutionName = value.value;
    this.setState(copiedStates);
  };
  closeSnackBar() {
    this.setState({ snackBar: false });
  }
  handleDialogSave(event) {
    ReactGA.event({
      category: "EducationInfo",
      action: "Updating",
    });
    event.preventDefault();
    let totalErrors = 0;
    for (let ref in this.state.error) {
      totalErrors += this.state.error[ref];
    }
    if (totalErrors > 0) return false;
    let examsToSubmit = [];
    for (let exam in this.state.exams) {
      if (this.state.exams[exam] === true) {
        examsToSubmit.push(exam);
      }
    }

    if (!this.validate()) {
      return;
    }

    let response = {
      email: this.state.formDataToSubmit.email,
      mobile: this.state.formDataToSubmit.mobile,
      password: this.state.formDataToSubmit.password,
      name: this.state.formDataToSubmit.name,
      institutionName: this.state.formDataToSubmit.institutionName,
      degree: this.state.formDataToSubmit.degree,
      branch: this.state.formDataToSubmit.branch,
      division: this.state.formDataToSubmit.division,
      semester: this.state.formDataToSubmit.semester,
      gender: this.state.formDataToSubmit.gender,
      age: this.state.formDataToSubmit.age,
      country: this.state.formDataToSubmit.country,
      state: this.state.formDataToSubmit.state,
      language: this.state.formDataToSubmit.language,
      preferredExams: examsToSubmit.join(","),
      id: this.state.formDataToSubmit.id,
    };

    if ("institutionName" in this.state.formDataToSubmit)
      response.institutionName = this.state.formDataToSubmit.institutionName;
    if ("usn" in this.state.formDataToSubmit)
      response.usn = this.state.formDataToSubmit.usn;
    if ("passoutYear" in this.state.formDataToSubmit)
      response.passoutYear = this.state.formDataToSubmit.passoutYear;

    var that = this;
    axios
      .put(
        constants.API_BASE_URL + "/users/" + this.state.formDataToSubmit.id,
        response
      )
      .then(function (response) {
        response.data.details.isLoggedIn = true;
        UserProfile.setUserProfile(response.data.details);
        let copiedStates = Object.assign({}, that.state);
        copiedStates.snackBar = true;
        copiedStates.snackBarMessage = response.data.message;

        that.setState(copiedStates);
        that.props.closeDialogCB(true, response.data.details);

        ReactGA.event({
          category: "EducationInfo",
          action: "Updated",
        });
        return false;
      })
      .catch(function (error) {
        ReactGA.event({
          category: "EducationInfo",
          action: "Error",
        });
        that.setState({ snackBarMessage: "Error" });
        that.setState({ snackBar: true });
      });
  }

  editModeToggle() {
    this.setState({
      dialogOpen: true,
    });
  }
  handleInputChange(e) {
    let copiedStates = Object.assign({}, this.state);
    copiedStates.formDataToSubmit[e.target.name] = e.target.value;
    this.setState(copiedStates);
  }

  handleDialogClose() {
    this.props.closeDialogCB(false);
  }
  validate() {
    let copiedStates = Object.assign({}, this.state);
    let isvalid = false;
    if (!copiedStates.formDataToSubmit.institutionName) {
      copiedStates.snackBarMessage = "Kindly Fill in your Institution name";
      isvalid = true;
    } else if (
      !copiedStates.formDataToSubmit.degree ||
      copiedStates.formDataToSubmit.degree === "Others"
    ) {
      copiedStates.snackBarMessage = "Kindly Fill in your Degree";
      isvalid = true;
    } else if (!copiedStates.formDataToSubmit.passoutYear) {
      copiedStates.snackBarMessage =
        "Kindly Fill in your expected year of graduation";
      isvalid = true;
    } else if (!copiedStates.formDataToSubmit.division) {
      copiedStates.snackBarMessage = "Kindly Fill in your division";
      isvalid = true;
    } else if (!copiedStates.formDataToSubmit.semester) {
      copiedStates.snackBarMessage = "Kindly Fill in your semester";
      isvalid = true;
    } else if (!copiedStates.formDataToSubmit.usn) {
      copiedStates.snackBarMessage =
        "Kindly Fill in your USN/Registration Number";
      isvalid = true;
    }
    //Year should be exactly 4 digits
    if (
      copiedStates.formDataToSubmit.passoutYear &&
      !/\d{4}/.test(copiedStates.formDataToSubmit.passoutYear)
    ) {
      copiedStates.snackBarMessage = "Invalid year of graduation";
      isvalid = true;
    }

    copiedStates.snackBar = isvalid;
    this.setState(copiedStates);
    return !isvalid;
  }
  render() {
    let handleKeyDown = this.handleKeyDown;
    const { classes, theme } = this.props;
    const selectStyles = {
      input: (base) => ({
        ...base,
        color: theme.palette.text.primary,
      }),
    };
    return (
      <div>
        <Snackbar
          id="snackbar-profile"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          onClose={this.closeSnackBar}
          open={this.state.snackBar}
          autoHideDuration={1000}
          SnackbarContentProps={{
            "aria-describedby": "message-id",
          }}
          message={<span id="message-id">{this.state.snackBarMessage}</span>}
        />

        <Dialog
          open={this.props.dialogOpen}
          fullWidth={true}
          maxWidth={"sm"}
          onClose={this.handleEducationalDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            EDIT EDUCATIONAL DETAILS
          </DialogTitle>
          <DialogContent>
            Institution Name
            <Select
              classes={classes}
              styles={selectStyles}
              components={components}
              options={this.state.listOfColleges}
              value={this.state.institutionName}
              placeholder="Institution Name"
              textFieldProps={{
                InputLabelProps: {
                  id: "institutionNameTextField",
                },
                onKeyUp: handleKeyDown,
              }}
              onChange={(event) => {
                this.handleChange("institutionName", event);
              }}
            />
            <br />
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormControl style={{ minWidth: 120 }} fullWidth>
                  <InputLabel>Degree</InputLabel>
                  <MUISelect
                    value={this.state.formDataToSubmit.degree}
                    onChange={(event) => {
                      this.handleUserDataChange("degree", {
                        value: event.target.value,
                      });
                    }}
                  >
                    {listOfDegrees.map((degree) => (
                      <MenuItem value={degree.value}>{degree.label}</MenuItem>
                    ))}
                  </MUISelect>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl style={{ minWidth: 120 }} fullWidth>
                  <InputLabel>Branch</InputLabel>
                  <MUISelect
                    value={this.state.formDataToSubmit.branch}
                    onChange={(event) => {
                      this.handleUserDataChange("branch", {
                        value: event.target.value,
                      });
                    }}
                  >
                    {listOfBranches.map((branch) => (
                      <MenuItem value={branch.value}>{branch.label}</MenuItem>
                    ))}
                  </MUISelect>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                Division
                {/* <Select
                  style={{
                    padding: 15,
                  }}
                  classes={classes}
                  styles={selectStyles}
                  components={components}
                  options={listOfDivisions}
                  value={{
                    label: this.state.formDataToSubmit.division,
                    value: this.state.formDataToSubmit.division,
                  }}
                  placeholder="Division"
                  textFieldProps={{
                    InputLabelProps: {
                      id: "institutionNameTextField",
                    },
                    onKeyUp: handleKeyDown,
                  }}
                  onChange={(event) => {
                    this.handleUserDataChange("division", event);
                  }}
                /> */}
                <MUISelect
                  labelId="simple-select-division"
                  id="select-division"
                  name="division"
                  style={{
                    width: "100%",
                  }}
                  value={this.state.formDataToSubmit.division}
                  onChange={(event) => {
                    this.handleUserDataChange("division", {
                      value: event.target.value,
                    });
                  }}
                >
                  {listOfDivisions.map((row) => (
                    <MenuItem key={row.value} value={row.value}>
                      {row.label}
                    </MenuItem>
                  ))}
                </MUISelect>
              </Grid>
              <Grid item xs={6}>
                Semester
                <MUISelect
                  labelId="simple-select-semester"
                  id="select-semester"
                  name="semester"
                  style={{
                    width: "100%",
                  }}
                  value={this.state.formDataToSubmit.semester}
                  onChange={(event) => {
                    this.handleUserDataChange("semester", {
                      value: event.target.value,
                    });
                  }}
                >
                  {listOfSemesters.map((row) => (
                    <MenuItem key={row.value} value={row.value}>
                      {row.label}
                    </MenuItem>
                  ))}
                </MUISelect>
              </Grid>
            </Grid>
            <br />
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  required
                  variant="outlined"
                  label="USN / Roll Number / College Registration Number"
                  margin="normal"
                  name="usn"
                  value={this.state.formDataToSubmit.usn}
                  onChange={this.handleInputChange}
                  onKeyUp={this.handleInputChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  required
                  variant="outlined"
                  label="Year of Graduation"
                  margin="normal"
                  type="number"
                  name="passoutYear"
                  value={this.state.formDataToSubmit.passoutYear}
                  onChange={this.handleInputChange}
                  onKeyUp={this.handleInputChange}
                  fullWidth
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDialogClose} color="primary">
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={this.handleDialogSave}
              color="primary"
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

EducationDetailsDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(EducationDetailsDialog);
