import * as constants from "../config/constants";
import React, { Component } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import UserProfile from "../Models/UserProfile";
import Tooltip from "@material-ui/core/Tooltip";
import Grid from "@material-ui/core/Grid";
import Options from "./options";
import Tags from "../tag/tags";
import BookmarkBorderIcon from "@material-ui/icons/BookmarkBorder";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import OfflineBoltIcon from "@material-ui/icons/OfflineBolt";
import MaximizeIcon from "@material-ui/icons/Maximize";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import ClearIcon from "@material-ui/icons/Clear";
import IconButton from "@material-ui/core/IconButton";
import ReactHtmlParser from "react-html-parser";
import ReactGA from "react-ga";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  cardTitle: {
    fontSize: 16,
    fontWeight: 700,
    textTransform: "uppercase",
  },
});

var startTime;
var endTime;

class CodingQuestion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      attemptCode: this.props.attemptCode,
      randomId: this.props.randomId,
      questionId: this.props.questionId,
      quizId: this.props.quizId,
      id: this.props.questionId,
      timeSpent: this.props.timeSpent ? this.props.timeSpent : 0,
      sphereEngineQuestionId: this.props.sphereEngineQuestionId,
    };
    this.submitAnswer = this.submitAnswer.bind(this);
  }

  submitAnswer(data) {
    endTime = new Date();
    let questionToSave = {
      questionId: this.state.id,
      attemptCode: this.state.attemptCode,
      userId: UserProfile.getUserProfile().id,
      quizId: this.state.quizId,
      // isCorrect: data.status.number,
      selectedAnswer: data.submission.apiId,
      sphereEngineQuestionId: this.state.sphereEngineQuestionId,
      timeSpent: this.state.timeSpent + (endTime - startTime),
    };

    this.props.handleOptionSelected();

    var sphereEngineQuestionId = this.state.sphereEngineQuestionId;

    if (data.status.number === 15 || data.status.number === 14) {
      axios
        .post(constants.API_BASE_URL + "/attempts/", questionToSave)
        .then(function (response) {
          console.log("response", response);
        })
        .catch(function (error) {});
      // if (data.status.number === 15) {
      //   let res;
      //   (async function () {
      //     var myHeaders = new Headers();
      //     myHeaders.append("Content-Type", "application/json");

      //     var raw = JSON.stringify({
      //       userId: UserProfile.getUserProfile().id,
      //       [sphereEngineQuestionId]: true,
      //     });

      //     console.log(raw);

      //     var requestOptions = {
      //       method: "POST",
      //       headers: myHeaders,
      //       body: raw,
      //       redirect: "follow",
      //     };

      //     fetch(
      //       "https://x28829y5ig.execute-api.ap-south-1.amazonaws.com/Prod/certigen/certificate/all",
      //       requestOptions
      //     )
      //       .then((response) => response.text())
      //       .then((result) => console.log(result))
      //       .catch((error) => console.log("error", error));
      //   })();
      // }
    }
  }
  componentWillReceiveProps(nextProps) {
    console.log("nextProps", nextProps);
  }
  componentDidMount(nextProps) {
    let that = this;
    (function (d, s, id) {
      console.log("d, s, id", d, s, id);
      var SE_BASE = "843d54fe.widgets.sphere-engine.com";
      var SE_HTTPS = false;
      if (constants.ENVIRONMENT === "LIVE") {
        SE_HTTPS = true;
      }
      var SE = window.SE || (window.SE = []);
      window.SE = SE;
      window.SE_BASE = SE_BASE;
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        window.SE.ready(function () {
          var afterSendSubmission = function (data) {
            console.log("afterSendSubmission", data);
          };
          var checkStatus = function (data) {
            console.log("checkStatus", data);
            if (data.statusNumber <= 8) {
              console.log("waiting...");
            } else {
              that.submitAnswer(data);
            }
          };
          var SEWidget = window.SE.widget(
            "example-widget" + that.state.randomId
          );
          SEWidget.events.subscribe("afterSendSubmission", afterSendSubmission);
          SEWidget.events.subscribe("checkStatus", checkStatus);
          startTime = new Date();
        });
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.onload = function () {
        window.SE.ready(function () {
          var afterSendSubmission = function (data) {
            console.log("afterSendSubmission", data);
          };
          var checkStatus = function (data) {
            console.log("checkStatus", data);
            if (data.statusNumber <= 8) {
              console.log("waiting...");
            } else {
              that.submitAnswer(data);
            }
          };
          var SEWidget = window.SE.widget(
            "example-widget" + that.state.randomId
          );
          SEWidget.events.subscribe("afterSendSubmission", afterSendSubmission);
          SEWidget.events.subscribe("checkStatus", checkStatus);
          startTime = new Date();
        });
      };
      js.src =
        (SE_HTTPS ? "https" : "http") +
        "://" +
        SE_BASE +
        "/static/sdk/sdk.min.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "sphere-engine-jssdk");
  }
  render() {
    let { classes } = this.props;
    return (
      <div
        style={{
          paddingTop: 15,
          height: "80vh",
        }}
        key={this.state.randomId}
      >
        <div
          key={this.state.randomId}
          id={"example-widget" + this.state.randomId}
          className="se-widget"
          data-widget={this.state.sphereEngineQuestionId}
          data-user-id={
            UserProfile.getUserProfile().id + "_" + this.state.attemptCode
          }
        >
          Loading your question...
        </div>
      </div>
    );
  }
}

CodingQuestion.contextTypes = {
  theme: PropTypes.object,
};

export default withStyles(styles)(CodingQuestion);
