import React, { Component } from 'react';
import IconButton from '@material-ui/core/IconButton';
import VerySatisfied from  '@material-ui/icons/SentimentVerySatisfied';
import Satisfied from  '@material-ui/icons/SentimentSatisfied';
import Dissatisfied from  '@material-ui/icons/SentimentDissatisfied';
import CloseIcon from  '@material-ui/icons/Close';
import VeryDisatisfied from  '@material-ui/icons/SentimentVeryDissatisfied';
import SvgIcon from '@material-ui/core/SvgIcon';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import * as constants from '../config/constants';
import UserProfile from '../Models/UserProfile';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
	root: {
		width: '100%',
		maxWidth: 360,
		backgroundColor: theme.palette.background.paper,
	},
	dialogMobile : {
		position: 'absolute',
		bottom: 0,
		right: '15px',
		zIndex: 3000,
	},
});


class Rate extends Component {
	constructor(props) {
		super(props);
		this.state = {
			open: typeof this.props.rateDialog !== "undefined" ? this.props.rateDialog : false,
			page: (this.props.page) ? this.props.page : 'header',
			callBackClose: (this.props.callBackClose) ? this.props.callBackClose : null,
			rateCount: 0,
			value: 0,
			comments: '',
			thankYouMessage: false,
			userId: UserProfile.getUserProfile().id,
			userOpen: typeof this.props.userOpen !== "undefined" ? this.props.userOpen : false,
			isMobile: window.innerWidth < 500,
			closeTimeGap: 300000
		}
		this.handleRate = this.handleRate.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
		this.checkRateDialogStats = this.checkRateDialogStats.bind(this);
	}
	handleClose(){
		this.setState({
			open: false
		});
		let rateDialogStats = {
			closed: true,
			given: false,
			time: Date.now(),
			userId: this.state.userId
		}
		localStorage.setItem('rate', JSON.stringify(rateDialogStats));
		(this.state.callBackClose) && this.state.callBackClose('user cancelled');
	}
	checkRateDialogStats = (nextProps) => {
		let rateDialogStats = localStorage.getItem('rate');
		if(rateDialogStats === null || nextProps.page === "header")
			return true;
		rateDialogStats = JSON.parse(rateDialogStats);
		return rateDialogStats.closed === true && rateDialogStats.time + this.state.closeTimeGap >= Date.now() && rateDialogStats.given === true;
	}
	componentWillReceiveProps(nextProps){
		let randomDelay = Math.random() * 5000;
		let that = this;
		let copiedStates = Object.assign({}, this.state);
		if(typeof nextProps.rateDialog !== "undefined" && nextProps.rateDialog === true && this.checkRateDialogStats(nextProps)) {
			if(document.getElementsByClassName("dialog").length === 0) {
				axios.get(constants.API_BASE_URL + '/ratings/'+ this.state.userId)
				.then(function (response) {
					if(response.data.details.length > 0 && nextProps.rateDialog === true){
						if(nextProps.page === "header") {
							copiedStates.open = nextProps.rateDialog;
						}
						copiedStates.thankYouMessage = "You have already submitted your rating. You can write to us @ reachus@ethnus.com";
						that.setState(copiedStates);
						return;
					}
					let rateDialogStats = {
						closed: true,
						given: false,
						time: Date.now(),
						userId: this.state.userId
					}
					localStorage.setItem('rate', JSON.stringify(rateDialogStats));
					copiedStates.open = typeof nextProps.rateDialog !== "undefined" ? nextProps.rateDialog : false;
					setTimeout(function() {
						that.setState(copiedStates);
					}, randomDelay);
				})
				.catch(function (error) {
				});
			} else {
				setTimeout(function() {
					that.componentWillReceiveProps(nextProps);
				}, randomDelay);
			}
		}
	}
	handleRate(star){
		let that = this;
		let copiedStates = Object.assign({},this.state);
		let request = { 
			rating: star,
			userId: UserProfile.getUserProfile().id,
			reason: that.state.comments,
			page: that.state.page
		}
		axios.post(constants.API_BASE_URL + '/rating/',request)
		.then(function (response) {
			copiedStates.rateCount = star;
			if(response){
				copiedStates.thankYouMessage = "Thanks for rating";
				let rateDialogStats = {
					closed: true,
					given: true,
					time: Date.now(),
					userId: this.state.userId
				}
				localStorage.setItem('rate', JSON.stringify(rateDialogStats));
				setTimeout(function () {
					copiedStates.open = false;
					copiedStates.rateCount = 0;
					copiedStates.value = 0;
					copiedStates.comments = '';
					setTimeout(() => {
						copiedStates.thankYouMessage = false;
						that.setState(copiedStates);
					}, 2000);
					that.setState(copiedStates);
				}, 3000);
			}
			that.setState(copiedStates);
		})
		.catch(function (error) {
			console.log(error);
		});
		that.setState(copiedStates);
	}
	handleInputChange(event) {
		let copiedStates = Object.assign({},this.state);
		copiedStates[event.target.name] = event.target.value;
		this.setState(copiedStates);
	}
	render(){
		let display = this.state.open ? "block" : "none";
		if(this.state.isMobile === false) {
			return(
				<div onClose={this.handleClose} className={"ratingDialog " + display}>
				<Card style={{
					textAlign: 'left'
				}}>
				<CardHeader
				avatar={
					<Avatar className={"selectChoice"} src={"logo.png"}/>
				}
				action ={<IconButton onClick={this.handleClose}><CloseIcon  /></IconButton>}
				title={<Typography variant="h2">Rate Aptimithra</Typography>}
				/>
				<CardContent style={{
					padding: 0
				}}>
				<div style={{display: Boolean(this.state.thankYouMessage) === true ? 'none' : 'block'}}>
				<List onClick={() => this.handleRate(5)}>
				<ListItem button>
				<ListItemIcon> 
				<VerySatisfied/>
				</ListItemIcon>
				<ListItemText primary={"Very satisfied"}/>
				</ListItem>
				</List>
				<List onClick={() => this.handleRate(4)}>
				<ListItem button>
				<ListItemIcon> 
				<Satisfied/>
				</ListItemIcon> 
				<ListItemText primary={"Somewhat satisfied"}/>
				</ListItem>
				</List>
				<List onClick={() => this.handleRate(3)}>
				<ListItem button>
				<ListItemIcon> 
				<SvgIcon>
				<g>
				<path id="svg_1" d="m0,0l24,0l0,24l-24,0l0,-24z" fill="none"/>
				<circle id="svg_2" r="1.5" cy="9.5" cx="15.5"/>
				<circle id="svg_3" r="1.5" cy="9.5" cx="8.5"/>
				<path id="svg_4" d="m11.81533,1.91266c-5.52,0 -9.99,4.48 -9.99,10s4.47,10 9.99,10c5.53,0 10.01,-4.48 10.01,-10s-4.48,-10 -10.01,-10zm0.01,18c-4.42,0 -8,-3.58 -8,-8s3.58,-8 8,-8s8,3.58 8,8s-3.58,8 -8,8zm-2.64193,-4.60262l4.38865,0.06987l3.25328,-1.46725l-10.17468,0z"/>
				</g>
				</SvgIcon>
				</ListItemIcon> 
				<ListItemText primary={"Neither satisfied nor dissatisfied"}/>
				</ListItem>
				</List>
				<List onClick={() => this.handleRate(2)}>
				<ListItem button>
				<ListItemIcon> 
				<Dissatisfied/>
				</ListItemIcon>
				<ListItemText primary={"Dissatisfied"}/>
				</ListItem>
				</List>
				<List onClick={() => this.handleRate(1)}>
				<ListItem button>
				<ListItemIcon>
				<VeryDisatisfied/>
				</ListItemIcon>
				<ListItemText primary={"Very dissatisfied"}/>
				</ListItem>
				</List>
				</div>
				{this.state.thankYouMessage ? (
					<div 
					style={{
						padding : 15,
						textAlign : 'center'
					}}>
					<br />
					<Typography>
					{this.state.thankYouMessage}
					</Typography>
					</div>
					) : ""}
				</CardContent>
				</Card>
				</div>
				)
		}
		else {
			return(
				<div onClose={this.handleClose} className={"ratingDialog mobile " + display}>
				<Card>
				<CardHeader
				avatar={
					<Avatar className={"selectChoice"} src={"logo.png"}/>
				}
				action ={<IconButton><CloseIcon onClick={this.handleClose} /></IconButton>}
				title="Rate Aptimithra"
				/>	
				<CardContent style={{
					padding : 0,
					textAlign: 'center'
				}}>
				<div style={{display: Boolean(this.state.thankYouMessage) === true ? 'none' : 'block'}}>
				<IconButton  style={{display : 'inline-block'}} onClick={() => this.handleRate(5)} >
				<VerySatisfied/>
				</IconButton>
				<IconButton  style={{display : 'inline-block'}} onClick={() => this.handleRate(4)} >
				<Satisfied/>
				</IconButton>
				<IconButton  style={{display : 'inline-block'}} onClick={() => this.handleRate(3)}>
				<SvgIcon>
				<g>
				<path id="svg_1" d="m0,0l24,0l0,24l-24,0l0,-24z" fill="none"/>
				<circle id="svg_2" r="1.5" cy="9.5" cx="15.5"/>
				<circle id="svg_3" r="1.5" cy="9.5" cx="8.5"/>
				<path id="svg_4" d="m11.81533,1.91266c-5.52,0 -9.99,4.48 -9.99,10s4.47,10 9.99,10c5.53,0 10.01,-4.48 10.01,-10s-4.48,-10 -10.01,-10zm0.01,18c-4.42,0 -8,-3.58 -8,-8s3.58,-8 8,-8s8,3.58 8,8s-3.58,8 -8,8zm-2.64193,-4.60262l4.38865,0.06987l3.25328,-1.46725l-10.17468,0z"/>
				</g>
				</SvgIcon>
				</IconButton>
				<IconButton style={{display : 'inline-block'}} onClick={() => this.handleRate(2)}>
				<Dissatisfied/>
				</IconButton>
				<IconButton  style={{display : 'inline-block'}} onClick={() => this.handleRate(1)}>
				<Satisfied/>
				</IconButton>
				</div>
				</CardContent>
				{this.state.thankYouMessage ? (
					<div 
					style={{
						padding : 15,
						textAlign : 'center'
					}}>
					<br />
					<Typography>
					{this.state.thankYouMessage}
					</Typography>
					</div>
					) : ""}
				</Card>
				</div>
				)
		}
	}
}
Rate.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Rate);