import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Badge from '@material-ui/core/Badge';
import UserProfile from '../Models/UserProfile';

const styles = theme => ({
	card: {
		maxWidth: 400,
	},
	media: {
		height: 0,
		paddingTop: '56.25%',
	},
	actions: {
		display: 'flex',
	},
	expand: {
		transform: 'rotate(0deg)',
		transition: theme.transitions.create('transform', {
			duration: theme.transitions.duration.shortest,
		}),
		marginLeft: 'auto',
	},
	expandOpen: {
		transform: 'rotate(180deg)',
	},
	secondaryHeading: {
		fontSize: theme.typography.pxToRem(15),
		color: theme.palette.text.secondary,
	},
	heading: {
		fontSize: theme.typography.pxToRem(15),
		flexBasis: '33.33%',
		flexShrink: 0,
		align: 'left'
	},
});

class RankingCategory extends Component {
	constructor(props) {
		super(props);
		this.state = {
			keyToDisplay: this.props.keyToDisplay,
			data: this.props.data,
			expanded: null,
			cardExpanded: false
		}
		this.handleChange = this.handleChange.bind(this)
	}
	handleExpandClick = () => {
		this.setState({ cardExpanded: !this.state.cardExpanded });
	};
	handleChange = panel => (event, expanded) => {
		this.setState({
			expanded: expanded ? panel : false,
		});
	};
	componentWillReceiveProps(nextProps) {
		let copiedStates = Object.assign({}, this.state);
		copiedStates.data = nextProps.data;
		copiedStates.keyToDisplay = nextProps.keyToDisplay;
		this.setState(copiedStates);
	}
	render() {
		const { classes } = this.props;

		if(this.state.data) {
			let individualArray = [];
			let individualUserArray = [];
			let title;
			let quizTitle;
			for(let key in this.state.data) {
				individualUserArray = [];
				quizTitle = '';

				for(let user in this.state.data[key]) {
					if((this.state.keyToDisplay === "Quiz Wise")) {
						quizTitle = this.state.data[key][user].quizDetails.name.title;
					}
					let avatar;
					if(this.state.data[key][user] && UserProfile.getUserProfile()._id === this.state.data[key][user].userDetails.id) {
						avatar = (
							<Badge badgeContent={"You"} color="secondary">
							<Avatar>
							{this.state.data[key][user].userDetails.name.slice(0,1).toUpperCase()}
							</Avatar>
							</Badge>
							);
					}
					else {
						avatar = (
							<Avatar>
							{this.state.data[key][user].userDetails.name.slice(0,1).toUpperCase()}
							</Avatar>
							);
					}
					individualUserArray.push(
						<ListItem key={user} >
						{avatar}
						<ListItemText
						primary={this.state.data[key][user].userDetails.name}
						secondary={(this.state.data[key][user].percentage === -100) ? 'No data found' : Math.round(this.state.data[key][user].percentage * 100, 2) + "%"} />
						<ListItemSecondaryAction>
						<Typography component="p">#{(this.state.data[key][user].rank) ? this.state.data[key][user].rank : (parseInt(user, 10)+1)}</Typography>
						</ListItemSecondaryAction>
						</ListItem>
						);
				}
				title = (this.state.keyToDisplay === "Quiz Wise") ? quizTitle : key;

				individualArray.push(
					<ExpansionPanel key={key} expanded={this.state.expanded === key} onChange={this.handleChange(key)}>
					<ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
					<Typography className={classes.heading}>{title}</Typography>
					<Typography className={classes.secondaryHeading}>{this.state.data[key][0].userDetails.name}</Typography>
					</ExpansionPanelSummary>
					<ExpansionPanelDetails>
					<Typography component="div" style={{
						width: '100%',
						borderTop: '1px solid #ddd'
					}} >
					<List dense>
					{individualUserArray}
					</List>
					</Typography>
					</ExpansionPanelDetails>
					</ExpansionPanel>
					);
			}

			let arrayLength = individualArray.length;

			if(this.state.cardExpanded === false)
				individualArray = individualArray.slice(0,3);

			return (
				<Card className="cardWithMargins RankingCategoryCard">
				<CardHeader
				title={this.state.keyToDisplay === "Quiz Wise" ? "Quiz-wise" : this.state.keyToDisplay}
				action={
					(arrayLength > 3) ? (<Button
						size="small"
						color="secondary"
						onClick={this.handleExpandClick}>
						{(this.state.cardExpanded === true) ? "Recent 3" : "View All"}
						</Button>) : ''
				}
				/>
				<CardContent style={{
					padding: 0
				}} >
				{individualArray}
				</CardContent>
				</Card>
				);	
		}
		else {
			return (<div></div>);
		}
	}
}

RankingCategory.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(RankingCategory);