import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col } from "reactstrap";
import * as constants from "../config/constants";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import CardMedia from "@material-ui/core/CardMedia";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import DescriptionIcon from "@material-ui/icons/Description";
import TimerIcon from "@material-ui/icons/Timer";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import ThumbsUpDown from "@material-ui/icons/ThumbsUpDown";
import ShareIcon from "@material-ui/icons/Share";
import ShareDialog from "../social/shareDialog";
import axios from "axios";
import UserProfile from "../Models/UserProfile";
import AnalyticsTable from "../analytics/analyticsTable";
import AttemptData from "./attemptData";
import CategoryData from "./categoryData";
import Suggestions from "./suggestions";
import DetailedReport from "./detailedReport";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import SwipeableViews from "react-swipeable-views";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import ReactGA from "react-ga";
import moment from "moment";
import Rate from "../rating/rate";
import { withStyles } from "@material-ui/core/styles";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import CircularProgress from "@material-ui/core/CircularProgress";
import BounceLoader from "react-spinners/BounceLoader";
import debounce from "lodash.debounce";

const styles = (theme) => ({
  cardTitle: {
    fontSize: 16,
    fontWeight: 700,
    textTransform: "uppercase",
  },
  root: {
    flexGrow: 1,
    height: window.innerHeight - 70,
    zIndex: 1,
    overflow: "hidden",
    position: "relative",
    display: "flex",
    width: "100%",
  },
});

class AttemptList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: window.innerWidth <= 500,
      userId: UserProfile.getUserProfile().id,
      random: Math.random(),
      loaded: false,
      categoryData: {
        Quants: {
          attempts: 0,
          correct: 0,
          timeSpent: 0,
        },
        Programming: {
          attempts: 0,
          correct: 0,
          timeSpent: 0,
        },
        Verbal: {
          attempts: 0,
          correct: 0,
          timeSpent: 0,
        },
        Reasoning: {
          attempts: 0,
          correct: 0,
          timeSpent: 0,
        },
        Coding: {
          attempts: 0,
          correct: 0,
          timeSpent: 0,
        },
        Uncategorised: {
          attempts: 0,
          correct: 0,
          timeSpent: 0,
        },
        SoftSkills: {
          attempts: 0,
          correct: 0,
          timeSpent: 0,
        },
      },
      attemptData: {},
      wholeData: {},
      limit: 15,
    };
    ReactGA.initialize("UA-125256246-1", {
      gaOptions: {
        userId: UserProfile.getUserProfile().email,
      },
    });
    ReactGA.pageview("/reports");
  }
  componentDidMount() {
    let that = this;
    let copiedStates = Object.assign({}, this.state);

    axios
      .get(constants.API_BASE_URL + "/attempts/users/" + this.state.userId)
      .then(function (response) {
        response.data.map((r) => {
          if (r.questionId) {
            if (
              [
                "Quants",
                "Programming",
                "Verbal",
                "Reasoning",
                "Coding",
                "Uncategorised",
                "SoftSkills",
              ].includes(r.questionId.category)
            ) {
              if (r.attemptCode in copiedStates.attemptData) {
                copiedStates.attemptData[r.attemptCode].attempts++;
                r.isCorrect &&
                  copiedStates.attemptData[r.attemptCode].correct++;
                copiedStates.attemptData[r.attemptCode].timeSpent +=
                  r.timeSpent;
                copiedStates.attemptData[r.attemptCode].markEarned +=
                  r.markEarned;
                copiedStates.attemptData[r.attemptCode].date =
                  copiedStates.attemptData[r.attemptCode].date >
                  moment(r.createdAt)
                    ? copiedStates.attemptData[r.attemptCode].date
                    : moment(r.createdAt);
                copiedStates.attemptData[r.attemptCode].categoryData[
                  r.questionId.category
                ].attempts++;
                r.isCorrect &&
                  copiedStates.attemptData[r.attemptCode].categoryData[
                    r.questionId.category
                  ].correct++;
                copiedStates.attemptData[r.attemptCode].categoryData[
                  r.questionId.category
                ].timeSpent += r.timeSpent;
              } else {
                copiedStates.attemptData[r.attemptCode] = {
                  attempts: 1,
                  correct: r.isCorrect ? 1 : 0,
                  markEarned: r.markEarned,
                  totalMarks: r.totalMark,
                  timeSpent: r.timeSpent,
                  date: moment(r.createdAt),
                  questionDetails:
                    typeof r.questionId !== "undefined" ? r.questionId : {},
                  quizTitle:
                    typeof r.quizDetails !== "undefined"
                      ? r.quizDetails.title
                      : "Quiz",
                  quizData:
                    typeof r.quizDetails !== "undefined"
                      ? r.quizDetails
                      : "Quiz",
                  categoryData: {
                    Quants: {
                      attempts: 0,
                      correct: 0,
                      timeSpent: 0,
                    },
                    Programming: {
                      attempts: 0,
                      correct: 0,
                      timeSpent: 0,
                    },
                    Verbal: {
                      attempts: 0,
                      correct: 0,
                      timeSpent: 0,
                    },
                    Reasoning: {
                      attempts: 0,
                      correct: 0,
                      timeSpent: 0,
                    },
                    Coding: {
                      attempts: 0,
                      correct: 0,
                      timeSpent: 0,
                    },
                    Uncategorised: {
                      attempts: 0,
                      correct: 0,
                      timeSpent: 0,
                    },
                    SoftSkills: {
                      attempts: 0,
                      correct: 0,
                      timeSpent: 0,
                    },
                  },
                };

                copiedStates.attemptData[r.attemptCode].categoryData[
                  r.questionId.category
                ].attempts++;
                r.isCorrect &&
                  copiedStates.attemptData[r.attemptCode].categoryData[
                    r.questionId.category
                  ].correct++;
                copiedStates.attemptData[r.attemptCode].categoryData[
                  r.questionId.category
                ].timeSpent += r.timeSpent;
              }

              copiedStates.categoryData[r.questionId.category].attempts++;
              r.isCorrect &&
                copiedStates.categoryData[r.questionId.category].correct++;
              copiedStates.categoryData[r.questionId.category].timeSpent +=
                r.timeSpent;
            }
          }
        });
        copiedStates.loaded = true;
        that.setState(copiedStates);
      })
      .catch(function (error) {
        console.log("AttemptList catch", error);
      });
  }
  render() {
    var maxWidth = "100%";
    const { classes } = this.props;
    var tablesToDisplay = [];
    var value = this.state.tabValue;
    const isMobile = window.innerWidth <= 500;
    let that = this;

    if (this.state.loaded === false) {
      return (
        <div className={classes.root}>
          <Typography
            className="circularProgress"
            component="div"
            style={{
              width: "100%",
              height: "100%",
            }}
          >
            <BounceLoader
              sizeUnit={"px"}
              size={50}
              style={{
                margin: "auto",
              }}
              color={"#212121"}
              loading={true}
            />
          </Typography>
        </div>
      );
    }

    return (
      <div>
        <CategoryData categoryData={this.state.categoryData} />
        <AttemptData attemptData={this.state.attemptData} />
      </div>
    );
  }
}
AttemptList.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(AttemptList);
